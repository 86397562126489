import React from "react";
import { IPageMdl } from "pages/_models/PageMdl";
import { ComponentRenderer } from "components/ComponentRenderer";
import { PageRendererContext } from "pages/PageContext";
import { PageRendererStore } from "pages/_stores/PageRendererStore";
import { PageMeta } from "pages/PageMeta";
import { PageTitle } from "_common/ui/pages/PageTitle";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { TLang } from "_configs/sharedConfig";

type Props = {
    page: IPageMdl;
};

export function PageRenderer(props: Props) {
    const localizedPage = props.page.localized[i18nextInstance.language as TLang];
    if (!localizedPage) return null;
    return (
        <PageRendererContext pageRendererStore={new PageRendererStore()}>
            <PageMeta page={props.page} />
            <PageTitle style={{ display: "none" }}>{localizedPage.title}</PageTitle>
            <ComponentRenderer component={localizedPage.component} isRoot />
        </PageRendererContext>
    );
}
