import React from "react";
import clsx from "clsx";
import styles from "auctions/card/_css/auctionCard.module.css";
import { AUCTION_STATUS, IBaseAuctionMdl } from "auctions/_models/AuctionMdl";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { AuctionReserve } from "auctions/_common/AuctionReserve";
import { AuctionBid } from "auctions/view/AuctionBid";
import { AuctionCountdown } from "auctions/_common/AuctionCountdown";
import { formatPrice } from "_common/_utils/currencyUtils";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { getHighestBidOrOffer } from "auctions/_utils/auctionUtils";

type Props = {
    auction: IBaseAuctionMdl;
    isClosed?: boolean;
};

export const AuctionCardInfo = observer((props: Props) => {
    const { t } = useTranslation();
    if (props.auction.mileage === undefined) return null;
    const bidsAndOffers = [...(props.auction.bids ?? []), ...(props.auction.offers ?? [])];
    const highestBid = getHighestBidOrOffer(bidsAndOffers);
    const isBuyNowAction = props.auction.status === AUCTION_STATUS.BUY_NOW && !!props.auction.minPrice;
    const isSold =
        !props.auction?.notAvailable &&
        (props.auction.soldManually || props.auction.sold || props.auction.status === AUCTION_STATUS.PAID);

    return (
        <div className={clsx({ [styles.infoIsClosed]: props.isClosed })}>
            <h3
                className={clsx(styles.title, {
                    [styles.titleIsClosed]: props.isClosed,
                })}
            >
                {props.auction.name}
            </h3>
            {!props.isClosed && (
                <>
                    <div>{props.auction?.mileage?.toLocaleString()} km</div>
                    <AuctionReserve withReserve={props.auction.withReserve} />
                    {props.auction?.location?.province && props.auction?.location?.city && (
                        <div>
                            {props.auction.location?.country?.toUpperCase() ?? "CANADA"},{" "}
                            {props.auction.location.province}, {props.auction.location.city}
                        </div>
                    )}
                </>
            )}
            <div
                className={clsx({
                    [styles.isClosed]: props.isClosed && props.auction.status !== AUCTION_STATUS.BUY_NOW,
                    [styles.buyNow]: props.auction.status === AUCTION_STATUS.BUY_NOW,
                })}
            >
                {!isBuyNowAction && !isSold && !!highestBid && (
                    <div>
                        <div className={clsx(styles.lastBid)}>
                            {props.isClosed ? t("auction.view.past") : t("auction.view.currentBid")}
                        </div>
                        <AuctionBid bid={highestBid} theme="contained" />
                    </div>
                )}
                {props.auction.endDate && props.auction.status !== AUCTION_STATUS.BUY_NOW && (
                    <div className="mt_5">
                        <AuctionCountdown auction={props.auction} />
                    </div>
                )}
                {isBuyNowAction && (
                    <div className={styles.buyNow}>
                        <p>
                            {t("auction.buyNow")} : <b>{formatPrice(props.auction.minPrice as number)}</b>
                        </p>
                        <p className={"flex_center_center"}>
                            <UiButton color={"primary"} variant={"contained"}>
                                {t("auction.seeOrMakeAnOffer")}
                            </UiButton>
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
});
