import React from "react";
import styles from "./_css/userPhoto.module.css";

type Props = {
    photo?: string;
};

export function UserPhoto(props: Props) {
    return (
        <div
            className={styles.container}
            style={props.photo ? { backgroundImage: `url('${props.photo}')` } : undefined}
        />
    );
}
