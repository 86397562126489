import React from "react";
import { action, observable } from "mobx";
import { guid } from "shared/_common/_utils/coreUtils";
import { TErrMdl } from "shared/_common/errors/_models/ErrMdl";
import { SnackbarProps } from "@material-ui/core/Snackbar/Snackbar";
import { i18nextInstance } from "shared/_common/i18n/IntlProvider";
import Alert from "@material-ui/lab/Alert";

class SnackbarsStore {
    @observable snackbars: { id: string; snackbarProps: SnackbarProps }[] = [];

    @action show = (snackbarProps: SnackbarProps) => {
        const id = guid();
        this.snackbars.push({ id, snackbarProps });
        return id;
    };

    showError(error: TErrMdl) {
        return this.show({
            autoHideDuration: 4000,
            children: (
                <Alert severity="error" elevation={6} variant="filled">
                    {i18nextInstance.t([error?.key, "errors.global.unknownLight"])}
                </Alert>
            ),
        });
    }

    showSuccess(message: string) {
        return this.show({
            autoHideDuration: 4000,
            children: (
                <Alert elevation={6} variant="filled">
                    {message}
                </Alert>
            ),
        });
    }

    @action hide(snackbarId: string) {
        this.snackbars.splice(
            this.snackbars.findIndex(({ id }) => id === snackbarId),
            1,
        );
    }
}

const snackbarsStore = new SnackbarsStore();
export { snackbarsStore };
