import React from "react";
import { paymentMethodsStore } from "payments/paymentMethods/_stores/paymentMethodsStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { PaymentMethodsList, PaymentMethodsListProps } from "payments/paymentMethods/PaymentMethodsList";

export const PaymentMethods = (props: PaymentMethodsListProps) => {
    React.useEffect(() => {
        paymentMethodsStore.load();
    }, []);

    return (
        <LoadableFromLoading
            loading={paymentMethodsStore.loadingState}
            renderer={(status, error) => (
                <div>
                    <ErrorBlock error={error} onRetry={() => paymentMethodsStore.load()} />
                    {status === "SUCCEEDED" ? <PaymentMethodsList {...props} /> : null}
                </div>
            )}
        />
    );
};
