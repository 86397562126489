import React from "react";
import { IPageMdl } from "pages/_models/PageMdl";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { Meta } from "_common/_utils/Meta";

type Props = {
    page: IPageMdl;
};

export function PageMeta(props: Props) {
    const currentLang = i18nextInstance.language as TLang;
    const localizedPage = props.page.localized[currentLang];
    if (!localizedPage) return null;

    const title = localizedPage.meta?.title ?? localizedPage.title;
    const description = localizedPage.meta?.description ?? localizedPage.description;
    const image = localizedPage.meta?.image ?? props.page.image;
    const url = sharedConfig.languages[currentLang].baseUrl + localizedPage.url;
    const alternateUrls = Object.entries(props.page.localized).reduce((acc, [lang, localized]) => {
        if (lang !== currentLang && localized?.published) {
            acc.push({ lang: lang as TLang, url: sharedConfig.languages[lang as TLang].baseUrl + localized.url });
        }
        return acc;
    }, [] as { lang: TLang; url: string }[]);
    return <Meta title={title} description={description} image={image} url={url} alternateUrls={alternateUrls} />;
}
