import React from "react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { getAuctionsBuyNowListingStore } from "auctions/_stores/auctionsBuyNowListingStore";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import styles from "./_css/auctionSideListing.module.css";
import { useTranslation } from "react-i18next";
import { AuctionsSideList } from "auctions/listing/sidebar/AuctionsSideList";
import clsx from "clsx";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { URLS } from "_configs/URLS";
import { useHistory } from "react-router-dom";

export function AuctionSideListing() {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className={styles.container}>
            <h2 className={clsx(styles.title, "mv_10")}>{t("buyNow.title")}</h2>
            <LoadableFromLoading
                loading={getAuctionsBuyNowListingStore().getLoadingState(1)}
                renderer={(status, error) => {
                    return (
                        <div className={"pr_4"}>
                            <ErrorBlock error={error} onRetry={() => getAuctionsBuyNowListingStore().load(1)} />
                            <div>
                                {status === "SUCCEEDED" ? (
                                    <AuctionsSideList listStore={getAuctionsBuyNowListingStore()} isClosed={true} />
                                ) : null}
                                <div className="flex_center_center mt_10 mb_10">
                                    <UiButton
                                        variant="contained"
                                        color="primary"
                                        onClick={() => history.push(URLS.auction.buyNowOrMakeAnOffer())}
                                    >
                                        {t("buyNow.seeAll")}
                                    </UiButton>
                                </div>
                            </div>
                        </div>
                    );
                }}
            />
        </div>
    );
}
