export const appConfig = {
    token: {
        storageKey: "TOKEN",
    },

    stripe: {
        publicKey: process.env.STRIPE_PUBLIC_KEY as string,
    },

    hotjar: {
        id: 2241573,
    },
};
