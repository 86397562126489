import React from "react";
import { TComponent } from "pages/_models/PageMdl";
import { Icon } from "@material-ui/core";
import clsx from "clsx";

export type IconComponentProps = {
    icon: string;
    $component?: TComponent;
} & React.ComponentProps<typeof Icon>;

export function IconComponent({ $component, icon, className, ...props }: IconComponentProps) {
    return icon.startsWith("aig-") ? (
        <i {...props} className={clsx(className, icon)} />
    ) : (
        <Icon {...props}>{icon}</Icon>
    );
}
