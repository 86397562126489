import { useEffect, useState } from "react";

export function useNoSsr() {
    const [firstRender, setFirstRender] = useState(true);

    if (__BROWSER__) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            setFirstRender(false);
        }, []);
    }

    return firstRender;
}
