import React, { PropsWithChildren } from "react";
import styles from "./_css/popup.module.css";
import { createPortal } from "react-dom";
import CloseIcon from "@material-ui/icons/Close";

type Props = {
    isOpen: boolean;
    close: () => void;
    autoClose?: number;
};

const Popup = ({ isOpen, close, autoClose, children }: PropsWithChildren<Props>) => {
    if (!isOpen) return null;
    if (autoClose) setTimeout(close, autoClose);
    return createPortal(
        <div className={styles.container}>
            <div className={styles.closeIcon} onClick={close}>
                <CloseIcon fontSize={"small"} />
            </div>
            {children}
        </div>,
        document.body,
    );
};

export default Popup;
