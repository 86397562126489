import { UiButton } from "_common/ui/mui/buttons/UiButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { userStore } from "users/_stores/userStore";
import { URLS } from "_configs/URLS";
import { useAuctionStore } from "auctions/AuctionContext";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";

const BuyNowBtn = observer(() => {
    const { t } = useTranslation();
    const auctionStore = useAuctionStore();
    const history = useHistory();
    const handleBuyNow = () => {
        !userStore.isLogged ? history.push(URLS.auth.signIn()) : auctionStore.openBuyNowDialog();
    };
    return (
        <UiButton color={"primary"} variant={"contained"} onClick={handleBuyNow}>
            {t("auction.buyNow")}
        </UiButton>
    );
});

export default BuyNowBtn;
