import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Input } from "_common/ui/forms/Input";
import { Controller, useFormContext } from "react-hook-form";
import { useAuctionStore } from "auctions/AuctionContext";
import { ProvinceInput } from "auctions/form/adInformation/ProvinceInput";
import { CountryInput } from "auctions/form/adInformation/CountryInput";
import { IAuctionMdl } from "auctions/_models/AuctionMdl";
import { StateInput } from "auctions/form/adInformation/StateInput";

type Props = {
    admin: boolean;
};

export function LocationInput(props: Props) {
    const form = useFormContext<IAuctionMdl>();
    const auctionStore = useAuctionStore();
    const { t } = useTranslation();
    const disabled = !props.admin && !auctionStore.inEdition;

    return (
        <Grid container spacing={3} className="mt_10">
            <Grid item md={4} sm={6} xs={12}>
                <InputBlock required label={t("words.country")}>
                    <Controller
                        control={form.control}
                        name={"location.country"}
                        disabled={disabled}
                        as={CountryInput}
                        value={form.watch("location.country") ?? "canada"}
                        rules={{
                            required: t<string>("errors.forms.required"),
                        }}
                        error={form.errors.location?.country}
                    />
                </InputBlock>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
                {form.watch("location.country") === "usa" ? (
                    <InputBlock required label={t("words.state")}>
                        <Controller
                            control={form.control}
                            name={"location.province"}
                            disabled={disabled}
                            as={StateInput}
                            rules={{
                                required: t<string>("errors.forms.required"),
                            }}
                            error={form.errors.location?.province}
                        />
                    </InputBlock>
                ) : (
                    <InputBlock required label={t("words.province")}>
                        <Controller
                            control={form.control}
                            name={"location.province"}
                            disabled={disabled}
                            as={ProvinceInput}
                            rules={{
                                required: t<string>("errors.forms.required"),
                            }}
                            error={form.errors.location?.province}
                        />
                    </InputBlock>
                )}
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
                <InputBlock required label={t("words.city")}>
                    <Controller
                        control={form.control}
                        name={"location.city"}
                        disabled={disabled}
                        as={Input}
                        rules={{
                            required: t<string>("errors.forms.required"),
                        }}
                        error={form.errors.location?.city}
                    />
                </InputBlock>
            </Grid>
        </Grid>
    );
}
