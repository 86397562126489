import React from "react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { auctionsWatchListStore } from "auctions/watchList/_stores/auctionsWatchListStore";
import { AuctionsListObserver } from "auctions/listing/AuctionsListObserver";

export const WatchListLoader = () => {
    React.useEffect(() => {
        auctionsWatchListStore.listStore.load(1);
    }, []);

    return (
        <LoadableFromLoading
            loading={auctionsWatchListStore.listStore.getLoadingState(1)}
            renderer={(status, error) => (
                <div>
                    <ErrorBlock error={error} onRetry={() => auctionsWatchListStore.listStore.load(1)} />
                    {status === "SUCCEEDED" ? (
                        <AuctionsListObserver listStore={auctionsWatchListStore.listStore} />
                    ) : null}
                </div>
            )}
        />
    );
};
