import React from "react";
import { useTranslation } from "react-i18next";
type Props = {
    type: "declined" | "accepted";
};

export function AuctionOfferStatusMessage(props: Props) {
    const { t } = useTranslation();
    return (
        <div
            className={"mb_25 mt_10 text_big text_bold"}
            dangerouslySetInnerHTML={{ __html: t("comments.offers." + props.type) }}
        />
    );
}
