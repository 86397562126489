import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { userStore } from "users/_stores/userStore";
import { InputBlock } from "_common/ui/forms/InputBlock";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { Input } from "_common/ui/forms/Input";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { MenuItem } from "@material-ui/core";
import sharedConfig from "_configs/sharedConfig";
import frImage from "../../../../assets/images/fr.png";
import enImage from "../../../../assets/images/ca.png";
import { IUserMdl } from "users/_models/UserMdl";
import { toast } from "react-toastify";
import { SingleFilePicker } from "_common/ui/forms/images/SingleFilePicker";
import { createFilesData } from "_common/_utils/fileUtils";
import { observer } from "mobx-react";
import { getErrorMessage } from "_common/errors/errorUtils";
import { UserInfoNotifications } from "users/profile/info/userInfo/UserInfoNotifications";

type Props = {
    isEditing?: boolean;
    onStopEditing: () => void;
};

export const UserInfoForm = observer((props: Props) => {
    const { t } = useTranslation();
    const form = useForm<IUserMdl>({
        mode: "onBlur",
        defaultValues: userStore.user,
    });

    React.useEffect(() => {
        form.reset(userStore.user);
    }, [userStore.user]);
    return (
        <FormProvider {...form}>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <InputBlock label={t("profile.photo")}>
                        <div style={{ maxWidth: 250 }}>
                            <Controller
                                as={SingleFilePicker}
                                control={form.control}
                                name={"photo"}
                                disabled={!props.isEditing || form.formState.isSubmitting}
                            />
                        </div>
                    </InputBlock>
                </Grid>
                <Grid item sm={12} md={6}>
                    <InputBlock required label={t("words.email")}>
                        <Controller
                            as={Input}
                            control={form.control}
                            theme={props.isEditing ? undefined : "label"}
                            name={"email"}
                            disabled
                        />
                    </InputBlock>
                </Grid>
                <Grid item sm={12} md={6}>
                    <InputBlock required label={t("words.nickname")}>
                        <Controller
                            as={Input}
                            control={form.control}
                            name={"nickname"}
                            theme={props.isEditing ? undefined : "label"}
                            disabled={!props.isEditing || form.formState.isSubmitting}
                        />
                    </InputBlock>
                </Grid>
                <Grid item sm={12} md={6}>
                    <InputBlock label={t("words.firstName")}>
                        <Controller
                            as={Input}
                            control={form.control}
                            name={"firstName"}
                            theme={props.isEditing ? undefined : "label"}
                            disabled={!props.isEditing || form.formState.isSubmitting}
                        />
                    </InputBlock>
                </Grid>
                <Grid item sm={12} md={6}>
                    <InputBlock label={t("words.lastName")}>
                        <Controller
                            as={Input}
                            control={form.control}
                            name={"lastName"}
                            theme={props.isEditing ? undefined : "label"}
                            disabled={!props.isEditing || form.formState.isSubmitting}
                        />
                    </InputBlock>
                </Grid>
                <Grid item sm={12} md={12}>
                    <InputBlock required label={t("words.phone")}>
                        <Controller
                            as={Input}
                            control={form.control}
                            rules={{
                                required: t<string>("errors.forms.required"),
                            }}
                            name={"phone"}
                            theme={props.isEditing ? undefined : "label"}
                            disabled={!props.isEditing || form.formState.isSubmitting}
                        />
                    </InputBlock>
                </Grid>
                <Grid item sm={12} md={6}>
                    <InputBlock label={t("profile.lang")}>
                        <Controller
                            control={form.control}
                            name={"lang"}
                            defaultValue={userStore.user?.lang ?? sharedConfig.defaultLang}
                            as={
                                <UiSelect disabled={!props.isEditing || form.formState.isSubmitting}>
                                    {Object.entries(sharedConfig.languages).map(([itemKey, item], index) => {
                                        return (
                                            <MenuItem key={index} value={itemKey}>
                                                <div className="flex_row_center">
                                                    <img
                                                        className={"mr_10"}
                                                        height={24}
                                                        src={itemKey === "fr" ? frImage : enImage}
                                                        alt={itemKey}
                                                    />
                                                    {item.title}
                                                </div>
                                            </MenuItem>
                                        );
                                    })}
                                </UiSelect>
                            }
                        />
                    </InputBlock>
                </Grid>
                <Grid item sm={12} md={6}>
                    <UserInfoNotifications
                        time={"dailyNotification"}
                        isEditing={props.isEditing}
                        onStopEditing={props.onStopEditing}
                    />
                </Grid>
            </Grid>
            {props.isEditing && (
                <div className="mt_20">
                    <UiButton
                        onClick={() => {
                            props.onStopEditing();
                            form.reset(userStore.user);
                        }}
                        variant={"outlined"}
                        color={"primary"}
                        className="mr_5"
                        disabled={form.formState.isSubmitting}
                    >
                        {t("words.cancel")}
                    </UiButton>
                    <UiButton
                        onClick={form.handleSubmit(async (data) => {
                            const filesData = await createFilesData(data.photo, "photo", 350);
                            return userStore
                                .save(
                                    {
                                        ...userStore.user,
                                        ...data,
                                    } as IUserMdl,
                                    filesData,
                                )
                                .then(
                                    () => {
                                        toast.success(t("profile.success"));
                                        props.onStopEditing();
                                    },
                                    (err) => {
                                        toast.error(getErrorMessage(err));
                                    },
                                );
                        })}
                        color={"primary"}
                        variant={"contained"}
                        disabled={form.formState.isSubmitting}
                    >
                        {form.formState.isSubmitting ? t("loaders.message") : t("words.submit")}
                    </UiButton>
                </div>
            )}
        </FormProvider>
    );
});
