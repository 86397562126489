import React, { useEffect } from "react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { userStore } from "users/_stores/userStore";
import { useHistory } from "react-router";
import { URLS } from "_configs/URLS";
import { useAuctionStore } from "auctions/AuctionContext";
import { useTranslation } from "react-i18next";
import styles from "./_css/placeBid.module.css";
import { observer } from "mobx-react";
import { AUCTION_STATUS } from "auctions/_models/AuctionMdl";
import { PAGE_REF } from "pages/PageRegular";

export const PlaceBidBtn = observer(() => {
    const [top, setTop] = React.useState(0);
    const auctionStore = useAuctionStore();
    const history = useHistory();
    const { t } = useTranslation();

    if (__BROWSER__) {
        useEffect(() => {
            if (!PAGE_REF.current) return;
            const listener = () => {
                if (PAGE_REF.current.scrollTop > 60) {
                    setTop(PAGE_REF.current.scrollTop - 75);
                } else {
                    setTop(-1);
                }
            };
            listener();
            PAGE_REF.current.addEventListener("scroll", listener);
            return () => (PAGE_REF.current ? PAGE_REF.current.removeEventListener("scroll", listener) : undefined);
        }, []);
    }

    if (auctionStore.auction.status !== AUCTION_STATUS.IN_PROGRESS) return null;

    function handlePlaceBid() {
        !userStore.isLogged ? history.push(URLS.auth.signIn()) : auctionStore.openBidDialog();
    }

    return (
        <div
            style={{ transform: `translateY(${top}px)`, transition: "all .3s ease-in-out" }}
            className={styles.containerBtn}
        >
            <UiButton className={styles.btn} onClick={handlePlaceBid} color={"primary"} variant={"contained"}>
                {t("bids.place")}
            </UiButton>
        </div>
    );
});
