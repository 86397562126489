import React from "react";
import styles from "./_css/mobileMenu.module.css";
import clsx from "clsx";
import { settingsStore } from "settings/_stores/settingsStore";
import { RootMobileMenu } from "main/menu/mobile/RootMobileMenu";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import { TLang } from "_configs/sharedConfig";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { MobileMenuBusiness } from "main/menu/mobile/MobileMenuBusiness";
import { MobileMenuUser } from "main/menu/mobile/MobileMenuUser";
import { Logo } from "_common/icons/Logo";

type Props = {
    opened: boolean;
    onClose: () => void;
};

export function MobileMenu(props: Props) {
    const lang = i18nextInstance.language;
    const menu = settingsStore.getOne()?.menu;
    const localizedMenu = menu?.localized?.[lang as TLang]?.items;
    if (!localizedMenu) return null;

    return (
        <div
            className={clsx(styles.container, {
                [styles.opened]: props.opened,
            })}
        >
            <IconButton className={styles.closeIcon} onClick={props.onClose}>
                <CloseIcon style={{ color: "#FFF" }} fontSize="large" />
            </IconButton>
            <div className={styles.logo}>
                <Logo width={120} fill="#FFF" />
            </div>
            <MobileMenuBusiness onClose={props.onClose} />
            {localizedMenu.map((rootMenu, index) => (
                <RootMobileMenu key={index} menu={rootMenu} onClose={props.onClose} />
            ))}
            <MobileMenuUser onClose={props.onClose} />
        </div>
    );
}
