import React from "react";
import clsx from "clsx";
import { AUCTION_STATUS, IUserAuctionListingMdl } from "auctions/_models/AuctionMdl";
import { useHistory } from "react-router-dom";
import { URLS } from "_configs/URLS";
import styles from "./_css/userAuction.module.css";
import { Grid } from "@material-ui/core";
import { AuctionStatus } from "auctions/_common/AuctionStatus";

type Props = {
    auction: IUserAuctionListingMdl;
    onCancel: (auctionId: string) => void;
};

export function UserAuction(props: Props) {
    // const { t } = useTranslation();
    const history = useHistory();
    const link =
        props.auction.status === AUCTION_STATUS.DRAFT || props.auction.status === AUCTION_STATUS.APPROVED
            ? URLS.auction.edit(props.auction._id)
            : URLS.auction.see(props.auction.alias);
    return (
        <div className={clsx("flex_row", styles.container)}>
            <Grid justify={"space-between"} alignItems={"center"} container>
                <Grid onClick={() => history.push(link)} item sm={5} xs={12}>
                    <AuctionStatus
                        className="mr_20"
                        auction={props.auction}
                        cancelRequested={props.auction.cancelRequested}
                    />
                </Grid>
                <Grid onClick={() => history.push(link)} item sm={7} xs={12}>
                    {props.auction.name}
                </Grid>
                {/*{props.auction.status !== AUCTION_STATUS.CANCELED &&*/}
                {/*props.auction.status !== AUCTION_STATUS.CLOSED &&*/}
                {/*!props.auction.cancelRequested ? (*/}
                {/*    <Grid onClick={() => props.onCancel(props.auction._id)} item sm={2} xs={12}>*/}
                {/*        <div className="textAlign_right">*/}
                {/*            <LinkBtn size="small" theme="delete">*/}
                {/*                {t(`words.cancel`).toLowerCase()}*/}
                {/*            </LinkBtn>*/}
                {/*        </div>*/}
                {/*    </Grid>*/}
                {/*) : (*/}
                {/*    <Grid item sm={2} xs={12} />*/}
                {/*)}*/}
            </Grid>
        </div>
    );
}
