import React, { useState } from "react";
import { observer } from "mobx-react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { getUserAuctionsStore } from "auctions/user/_stores/userAuctionsStore";
import { useTranslation } from "react-i18next";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import { UserAuction } from "auctions/user/UserAuction";
import { LoadMoreBtn } from "_common/ui/utils/LoadMoreBtn";
import { ConfirmationDialog } from "_common/ui/dialogs/ConfirmationDialog";
import sharedConfig from "_configs/sharedConfig";
import { UserAuctionCancelDialog } from "auctions/user/UserAuctionCancelDialog";
import { IAuctionEditionMdl } from "auctions/_models/AuctionMdl";

export const UserAuctionsList = observer(() => {
    const userAuctionsStore = getUserAuctionsStore();
    const [auctionIdToCancel, setAuctionIdToCancel] = useState<string>();
    const [agreed, setAgreed] = useState(false);
    const { t } = useTranslation();
    return (
        <div>
            {userAuctionsStore.items.map(
                (userAuction) =>
                    userAuction && (
                        <UserAuction
                            onCancel={(auctionId: string) => setAuctionIdToCancel(auctionId)}
                            key={userAuction._id}
                            auction={userAuction}
                        />
                    ),
            )}
            {userAuctionsStore.items.length === 0 && (
                <div className="flex_center_center mt_20 mb_25">{t("auction.user.empty")}</div>
            )}
            <LoadMoreBtn listStore={userAuctionsStore} />
            <div className="mt_20">
                <Link to={URLS.auction.new()}>
                    <UiButton variant="contained" color="primary">
                        {t("header.sellCar")}
                    </UiButton>
                </Link>
            </div>
            <ConfirmationDialog
                title={t("auction.cancel.title", {
                    auctionName: auctionIdToCancel ? userAuctionsStore.getSync(auctionIdToCancel)?.name : "",
                })}
                open={!!auctionIdToCancel && !agreed}
                agreeLabel={t("words.yes")}
                disagreeLabel={t("words.no")}
                message={t("auction.cancel.info", {
                    amount: auctionIdToCancel
                        ? userAuctionsStore.getSync(auctionIdToCancel)?.withReserve
                            ? sharedConfig.fees.cancelWithReserve
                            : sharedConfig.fees.cancelNoReserve
                        : "",
                })}
                onAgree={() => setAgreed(true)}
                onDisagree={() => setAuctionIdToCancel(undefined)}
            />
            <UserAuctionCancelDialog
                open={agreed}
                onClose={() => {
                    setAgreed(false);
                    setAuctionIdToCancel(undefined);
                }}
                auction={
                    auctionIdToCancel ? (userAuctionsStore.getSync(auctionIdToCancel) as IAuctionEditionMdl) : undefined
                }
            />
        </div>
    );
});
