import React from "react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { ActionBtn } from "_common/ui/utils/ActionBtn";
import { useAuctionCommentsStore } from "comments/AuctionCommentsContext";
import { TextArea } from "_common/ui/forms/TextArea";
import styles from "./_css/auctionCommentForm.module.css";
import { userStore } from "users/_stores/userStore";
import { useHistory } from "react-router";
import { URLS } from "_configs/URLS";

type Props = {
    parentComment?: string;
    onSuccess?: () => void;
};

export function AuctionCommentForm(props: Props) {
    const [sending, setSending] = React.useState(false);
    const [text, setText] = React.useState("");
    const history = useHistory();
    const { t } = useTranslation();
    const auctionCommentsStore = useAuctionCommentsStore();
    const isValid = !!text.trim();
    return (
        <div className={styles.container}>
            <TextArea
                value={text}
                onValueChange={(text) => setText(text)}
                placeholder={t("comments.form.placeholder")}
                rows={3}
                disabled={sending}
            />
            <div className={styles.btn}>
                <ActionBtn
                    onExecute={() => {
                        if (!isValid) return;
                        if (!userStore.isLogged) {
                            history.push(URLS.auth.signIn());
                            return;
                        }
                        setSending(true);
                        const promise = auctionCommentsStore.postComment(text, props.parentComment);
                        promise
                            .then(() => {
                                setText("");
                                props.onSuccess?.();
                            })
                            .finally(() => setSending(false));
                        return promise;
                    }}
                >
                    <UiButton disabled={!isValid} color="primary" variant="outlined">
                        {t("words.send")}
                    </UiButton>
                </ActionBtn>
            </div>
        </div>
    );
}
