import React from "react";
import { usePageRendererStore } from "pages/PageContext";
import { TWrapperComponentProps } from "pages/_stores/PageRendererStore";
import { COMPONENTS_CONFIGS } from "components/componentsConfigs";

export type TComponentsConfigs = {
    [key: string]:
        | {
              component: (props: any) => JSX.Element;
          }
        | undefined;
};

export function ComponentRenderer(props: Omit<TWrapperComponentProps, "children">) {
    const pageRendererStore = usePageRendererStore();
    const componentConfig = COMPONENTS_CONFIGS[props.component.id];
    if (!componentConfig) return null;

    const Component = componentConfig.component;
    const WrapperComponent = pageRendererStore.WrapperComponent;
    if (WrapperComponent) {
        return (
            <WrapperComponent {...props}>
                {(componentProps: any, EditorComponent?: (props: any) => JSX.Element) => {
                    const ComponentToRender = EditorComponent ?? Component;
                    return <ComponentToRender {...componentProps} $component={props.component} />;
                }}
            </WrapperComponent>
        );
    }

    return <Component {...props.component.props} $component={props.component} />;
}
