import React, { useEffect, useState } from "react";
import Popup from "main/overlay/Popup";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import styles from "./_css/noListingFeesPopup.module.css";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { InfoRounded } from "@material-ui/icons";
import clsx from "clsx";

const STORAGE_KEY = "listingFeedPopup";

const shouldSeePopup = (): boolean => {
    const lastSeen = localStorage.getItem(STORAGE_KEY);
    if (!lastSeen) return true;
    const date = JSON.parse(lastSeen).lastSeen;
    return !dayjs(date).isSame(dayjs(), "day");
};
const NoListingFeesPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(() => setIsOpen(true), 5000);
    });

    if (!shouldSeePopup()) return null;

    const handleClose = () => {
        setIsOpen(false);
        localStorage.setItem(STORAGE_KEY, JSON.stringify({ lastSeen: dayjs() }));
    };

    return (
        <Popup isOpen={isOpen} close={handleClose}>
            <div className={styles.container}>
                <div className={clsx(styles.body, "mb_20")}>
                    <InfoRounded className={styles.icon} />
                    <p>{t("noListingFeesPopup.body")}</p>
                </div>
                <Link to={URLS.auction.new()} onClick={handleClose}>
                    <p className={styles.action}>{t("noListingFeesPopup.action")}</p>
                </Link>
            </div>
        </Popup>
    );
};

export default NoListingFeesPopup;
