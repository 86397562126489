import React, { useState } from "react";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { i18nextInstance } from "shared/_common/i18n/IntlProvider";
import { MenuItem } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import { UiButton } from "shared/_common/ui/mui/buttons/UiButton";
import { pagesStore } from "pages/_stores/pagesStore";
import { ArrowDropDown } from "@material-ui/icons";

async function changeLang(lang: TLang) {
    const oldLang = i18nextInstance.language as TLang;
    if (oldLang === lang) return;
    const langConfig = sharedConfig.languages[lang];
    await i18nextInstance.changeLanguage(lang);
    const { pathname, hash, search } = window.location;
    if (pathname === "/") {
        window.location.href = langConfig.baseUrl;
    } else {
        const page = pagesStore.getByUrlSync(pathname, oldLang);
        if (page?.localized?.[lang]?.url) {
            window.location.href = langConfig.baseUrl + page.localized[lang]?.url + search + hash;
        } else if (!page) {
            window.location.href = langConfig.baseUrl + pathname.substr(langConfig.basePath.length) + search + hash;
        } else {
            window.location.href = langConfig.baseUrl;
        }
    }
}

export function LangSelector() {
    const [flagMenuEl, setFlagMenuEl] = useState<(EventTarget & HTMLButtonElement) | undefined>(undefined);

    return (
        <div>
            <UiButton
                aria-controls="language-menu"
                aria-haspopup="true"
                onClick={(event) => setFlagMenuEl(event.currentTarget)}
                endIcon={<ArrowDropDown />}
                light
            >
                {i18nextInstance.language === "fr" ? "FR" : "EN"}
            </UiButton>
            <Menu open={!!flagMenuEl} anchorEl={flagMenuEl} onClose={() => setFlagMenuEl(undefined)}>
                {Object.entries(sharedConfig.languages).map(([lang, { title }]) => (
                    <MenuItem
                        onClick={() => {
                            setFlagMenuEl(undefined);
                            changeLang(lang as TLang);
                        }}
                        key={lang}
                        value={lang}
                    >
                        <em>{title}</em>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
