import React from "react";
import styles from "./_css/inputBlock.module.css";
import clsx from "clsx";

type Props = {
    label: string;
    extralabel?: string;
    text?: string;
    required?: boolean;
    className?: string;
    inputClassName?: string;
};

export function InputBlock(props: React.PropsWithChildren<Props>) {
    return (
        <div className={props.className}>
            <div className={"flex_row_center"}>
                <div className={clsx(styles.label, "mr_10")}>
                    {props.label}
                    {props.required && <span className={styles.required}>*</span>}
                </div>
                <div className={styles.extraLabel}>{props.extralabel}</div>
            </div>
            {props.text && <div className={styles.text}>{props.text}</div>}
            <div className={props.inputClassName ?? "mb_10"}>{props.children}</div>
        </div>
    );
}
