import React, { useContext } from "react";
import { AuctionStore } from "./_stores/AuctionStore";

const AuctionCtxt = React.createContext<AuctionStore>({} as AuctionStore);

type Props = {
    auctionStore: AuctionStore;
};

export function useAuctionStore() {
    return useContext(AuctionCtxt);
}

export function AuctionContext(props: React.PropsWithChildren<Props>) {
    return <AuctionCtxt.Provider value={props.auctionStore}>{props.children}</AuctionCtxt.Provider>;
}
