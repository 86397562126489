import React from "react";
import { Helmet } from "react-helmet-async";
import sharedConfig, { TLang } from "_configs/sharedConfig";

type Props = {
    title?: string;
    description?: string;
    image?: string;
    url: string;
    alternateUrls?: { lang: TLang; url: string }[];
};

export function Meta(props: Props) {
    return (
        <Helmet>
            {props.title && (
                <>
                    <meta name="title" content={props.title} />
                    <meta name="og:title" content={props.title} />
                    <meta name="twitter:title" content={props.title} />
                </>
            )}
            {props.description && (
                <>
                    <meta name="description" content={props.description} />
                    <meta name="og:description" content={props.description} />
                    <meta name="twitter:description" content={props.description} />
                </>
            )}
            {props.image && (
                <>
                    <meta name="image" content={props.image} />
                    <meta name="og:image" content={props.image} />
                    <meta name="twitter:image" content={props.image} />
                </>
            )}
            <meta name="twitter:card" content="summary" />
            <meta name="og:url" content={props.url} />
            <link rel="canonical" href={props.url} />
            {props.alternateUrls?.map(({ lang, url }) => (
                <link
                    key={lang}
                    rel="alternate"
                    type="text/html"
                    hrefLang={lang}
                    href={url}
                    title={sharedConfig.languages[lang].title}
                />
            ))}
        </Helmet>
    );
}
