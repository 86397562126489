import React from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { paymentMethodsStore } from "payments/paymentMethods/_stores/paymentMethodsStore";
import { TErrMdl } from "_common/errors/_models/ErrMdl";
import { StripeLoader } from "payments/StripeLoader";
import { toast } from "react-toastify";
import styles from "./_css/paymentMethodForm.module.css";
import clsx from "clsx";
import { UNKNOWN_ERROR } from "_common/errors/errorUtils";

type Props = {
    onClose: () => void;
};

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

function PaymentMethodFormContent(props: Props) {
    const [complete, setComplete] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | TErrMdl | undefined>(undefined);
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();

    return (
        <div className={clsx("shadow p_20", styles.container)}>
            <ErrorBlock error={error} />
            <CardElement options={CARD_ELEMENT_OPTIONS} onChange={(event) => setComplete(event.complete)} />
            <div className="flex_row_center mt_20 flexWrap_wrap">
                <UiButton
                    className="mr_5"
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                        setLoading(true);
                        setError(undefined);
                        const card = elements?.getElement(CardElement);
                        if (!card) return;

                        try {
                            const result = await stripe?.createPaymentMethod({
                                type: "card",
                                card,
                            });
                            if (result?.error) setError(result?.error.message);
                            const stripePaymentMethodId = result?.paymentMethod?.id;
                            if (stripePaymentMethodId) {
                                await paymentMethodsStore.add(stripePaymentMethodId);
                                props.onClose();
                                toast.success(t("paymentMethods.cardAdded"), {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                });
                                return;
                            } else {
                                setError(UNKNOWN_ERROR);
                            }
                        } catch (err) {
                            setError(err);
                        }
                        setLoading(false);
                    }}
                    disabled={loading || !complete}
                >
                    {t(loading ? "loaders.message" : "paymentMethods.addCard")}
                </UiButton>
                <UiButton onClick={props.onClose}>{t("words.cancel")}</UiButton>
            </div>
        </div>
    );
}

export function PaymentMethodForm(props: Props) {
    return (
        <StripeLoader>
            <PaymentMethodFormContent {...props} />
        </StripeLoader>
    );
}
