import React from "react";
import { IAuctionListingMdl } from "auctions/_models/AuctionMdl";
import styles from "./_css/auctionCard.module.css";
import { useHistory } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { CAR_OPTIONS_CONFIG } from "_configs/carConfig";
import { AuctionOptionView } from "auctions/view/AuctionOptionView";
import { AuctionThumbnail } from "./AuctionThumbnail";
import { AuctionCardInfo } from "./AuctionCardInfo";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

type Props = {
    auction: IAuctionListingMdl;
    isClosed?: boolean;
};

export const AuctionCard = observer((props: Props) => {
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <Grid
            container
            className={clsx(styles.container, {
                [styles.containerIsClosed]: props.isClosed,
            })}
            onClick={() => history.push(URLS.auction.see(props.auction.alias))}
            spacing={3}
        >
            <Grid item md={props.isClosed ? 12 : 4} sm={12} xs={12}>
                <AuctionThumbnail auction={props.auction} />
            </Grid>
            <Grid item md={props.isClosed ? 12 : 3} sm={12} xs={12}>
                <AuctionCardInfo auction={props.auction} isClosed={props.isClosed} />
            </Grid>
            <Grid item md={5} sm={12} xs={12} className={clsx({ [styles.contentHidden]: props.isClosed })}>
                <Grid container spacing={2}>
                    {CAR_OPTIONS_CONFIG.map((carOption) => (
                        <Grid
                            key={carOption.key}
                            item
                            md={4}
                            sm={6}
                            xs={6}
                            className={clsx({ [styles.hiddenDesktop]: carOption.type === "color" })}
                        >
                            <AuctionOptionView auction={props.auction} carOption={carOption} />
                        </Grid>
                    ))}
                    {props.auction.carfax?.url && (
                        <Grid item xs={6} className={styles.bottomBottom}>
                            <h4>{t("auction.carfax")}</h4>
                            <a href={props.auction.carfax.url} target="_blank" rel="noopener noreferrer">
                                {t("auction.carfaxLink")}
                            </a>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
});
