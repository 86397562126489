import React from "react";
import { FooterMenu } from "main/footer/FooterMenu";
import { settingsStore } from "settings/_stores/settingsStore";
import { useTranslation } from "react-i18next";

type Props = {
    className?: string;
};

export function FooterInformation(props: Props) {
    let links = [];
    const { t } = useTranslation();
    const contacts = settingsStore.getOne()?.contacts;
    if (contacts?.emailContact) {
        links.push({
            url: "mailto:" + contacts.emailContact,
            title: contacts.emailContact,
        });
    }
    if (contacts?.phone) links.push({ url: "tel:" + contacts.phone, title: contacts.phone });
    links = [
        { url: t("privacyPolicy.url"), title: t("privacyPolicy.title") },
        { url: t("termsOfUse.url"), title: t("termsOfUse.title") },
        ...links,
    ];

    return <FooterMenu className={props.className} title="Information" links={links} />;
}
