import React from "react";
import { formatPrice } from "_common/_utils/currencyUtils";
import { IBidMdl, IBidPaymentStatusMdl } from "auctions/_models/BidMdl";
import clsx from "clsx";
import styles from "./_css/auctionBid.module.css";

type Props = {
    bid: IBidMdl | IBidPaymentStatusMdl;
    theme?: "contained" | "default";
};

export function AuctionBid(props: Props) {
    return (
        <div className={clsx(styles.container, { [styles.contained]: props.theme === "contained" })}>
            {formatPrice(props.bid.price)}
        </div>
    );
}
