import React from "react";
import { useAuctionStore } from "auctions/AuctionContext";
import { WatchBtn } from "auctions/watchList/WatchBtn";
import { AuctionInfo } from "auctions/view/AuctionInfo";
import { BidDialog } from "auctions/bids/BidDialog";
import eventsStore from "main/events/eventsStore";
import { FacebookBtn } from "_common/ui/share/FacebookBtn";
import styles from "./_css/auctionView.module.css";
import clsx from "clsx";
import { AuctionMeta } from "auctions/view/AuctionMeta";
import { NoSsr } from "@material-ui/core";
import { AuctionCopyLinkBtn } from "auctions/share/AuctionCopyLinkBtn";
import { EmailBtn } from "_common/ui/share/EmailBtn";
import sharedConfig from "_configs/sharedConfig";
import { URLS } from "_configs/URLS";
import { PlaceBidBtn } from "auctions/bids/PlaceBidBtn";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AUCTION_STATUS } from "auctions/_models/AuctionMdl";
import BuyNowDialog from "auctions/buyNow/BuyNowDialog";
import OfferDialog from "auctions/offers/OfferDialog";

export function AuctionView() {
    const { t } = useTranslation();
    const auctionStore = useAuctionStore();
    const auctionUrl = sharedConfig.appUrl + URLS.auction.see(auctionStore.auction.alias ?? "");
    React.useEffect(() => {
        if (auctionStore.auction._id) {
            eventsStore.send({ type: "auctions/watch", payload: { auctionId: auctionStore.auction._id } });
            return () =>
                eventsStore.send({ type: "auctions/unwatch", payload: { auctionId: auctionStore.auction._id } });
        }
    }, [auctionStore.auction._id]);

    const inProgress =
        auctionStore.auction.status !== AUCTION_STATUS.PAID &&
        (auctionStore.auction.status === AUCTION_STATUS.IN_PROGRESS ||
            auctionStore.auction.status === AUCTION_STATUS.DRAFT ||
            auctionStore.auction.status === AUCTION_STATUS.CANCELED);
    const notSold =
        (auctionStore.auction.status === AUCTION_STATUS.CLOSED ||
            auctionStore.auction.status === AUCTION_STATUS.CLOSED_NO_RESERVE) &&
        !auctionStore.auction.sold &&
        !auctionStore.auction.soldManually;

    const notSoldNotInProgress = !inProgress && notSold;

    return (
        <div className="mb_40">
            <AuctionMeta />
            <div className=" mb_20">
                <div className="flex_row_center">
                    <h2 className="mr_10">{auctionStore.auction.name}</h2>
                    {auctionStore.auction._id ? <WatchBtn auctionId={auctionStore.auction._id} /> : null}
                    <div className="flex-1" />
                    <div className={clsx(styles.shareButtons)}>
                        <AuctionCopyLinkBtn auction={auctionStore.auction} />
                        <FacebookBtn url={auctionUrl} />
                        <EmailBtn subject={auctionStore.auction.name} body={auctionUrl} />
                    </div>
                </div>
                {notSoldNotInProgress && (
                    <Link onClick={() => (window as any).$crisp.push(["do", "chat:open"])} to={"#"}>
                        <div className={clsx(styles.askUs, "mv_10")}>{t("auction.notSoldAskUs")}</div>
                    </Link>
                )}
                <div className={styles.shareButtonsMobile}>
                    <AuctionCopyLinkBtn auction={auctionStore.auction} />
                    <FacebookBtn url={auctionUrl} />
                    <EmailBtn subject={auctionStore.auction.name} body={auctionUrl} />
                </div>
            </div>
            <div className="flex_row justifyContent_flexEnd mt_10">
                <PlaceBidBtn />
            </div>
            <AuctionInfo />
            <NoSsr>
                <BidDialog />
                <BuyNowDialog />
                <OfferDialog />
            </NoSsr>
        </div>
    );
}
