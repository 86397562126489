import React from "react";
import styles from "./_css/footer.module.css";
import { NavLink } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { Container, Grid, Hidden } from "@material-ui/core";
import clsx from "clsx";
import { FooterInformation } from "main/footer/FooterInformation";
import { FooterNavigation } from "main/footer/FooterNavigation";
import { FooterLinks } from "main/footer/FooterLinks";
import { settingsStore } from "settings/_stores/settingsStore";
import { useTranslation } from "react-i18next";
import { Logo } from "_common/icons/Logo";
import { LangSelector } from "main/menu/LangSelector";

export function Footer() {
    const links = settingsStore.getOne()?.links;
    const { t } = useTranslation();
    const date = new Date();
    return (
        <div className={styles.container}>
            <Container>
                <div className="flex_row flexWrap_wrap">
                    <div className={clsx(styles.logos, "flex_row flexShrink_0")}>
                        <div
                            className={clsx(styles.brandLogoWrapper, "flex-1 flex_column justifyContent_spaceBetween")}
                        >
                            <NavLink to={URLS.home()}>
                                <Logo width={120} fill="#FFF" />
                            </NavLink>
                            <LangSelector />
                        </div>
                    </div>
                    <Grid container spacing={3} className="flex-1">
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <FooterNavigation className={styles.menu} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <FooterInformation className={styles.menu} />
                        </Grid>

                        {(links?.fb || links?.tw || links?.ig || links?.yt || links?.li) && (
                            <Grid item xs={12} md={4} lg={4}>
                                <Hidden lgDown>
                                    <div className={styles.spacer} />
                                </Hidden>
                                <FooterLinks links={links} />
                            </Grid>
                        )}
                    </Grid>
                </div>
                <div className={clsx(styles.allRightsReserved, "pt_40 flex_center_center")}>
                    {t("footer.copyright", { year: date.getFullYear().toString() })} - made by{" "}
                    <a
                        style={{ color: "white" }}
                        target={"_blank"}
                        rel={"noreferrer noopener"}
                        href={"https://lesentrecodeurs.com"}
                    >
                        Les Entrecodeurs
                    </a>
                </div>
            </Container>
        </div>
    );
}
