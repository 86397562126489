import React from "react";
import { observer } from "mobx-react";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { auctionsWatchListStore } from "auctions/watchList/_stores/auctionsWatchListStore";
import { IconButton } from "@material-ui/core";
import { ActionBtn } from "_common/ui/utils/ActionBtn";
import styles from "./_css/watchBtn.module.css";
import { userStore } from "users/_stores/userStore";
import { useHistory } from "react-router";
import { URLS } from "_configs/URLS";

type Props = {
    auctionId: string;
};

export const WatchBtn = observer((props: Props) => {
    const isWatching = auctionsWatchListStore.isWatching(props.auctionId);
    const history = useHistory();
    return (
        <ActionBtn
            onExecute={() => {
                if (!userStore.isLogged) {
                    history.push(URLS.auth.signIn());
                    return undefined;
                }
                return isWatching
                    ? auctionsWatchListStore.unwatch(props.auctionId)
                    : auctionsWatchListStore.watch(props.auctionId);
            }}
            noLoadingLabel
        >
            <IconButton color="secondary" disabled={false} className={styles.container}>
                {isWatching ? <NotificationsActiveIcon /> : <NotificationsNoneIcon />}
            </IconButton>
        </ActionBtn>
    );
});
