import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";

type Props = {
    style?: CSSProperties;
    value: string;
    type?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    $component: TComponent;
};

export function TitleComponent({ value, type, $component, ...props }: Props) {
    switch (type ?? "h2") {
        case "h1":
            return (
                <h1 {...props} className="h1">
                    {value}
                </h1>
            );
        case "h2":
            return (
                <h2 {...props} className="h2">
                    {value}
                </h2>
            );
        case "h3":
            return (
                <h3 {...props} className="h3">
                    {value}
                </h3>
            );
        case "h4":
            return (
                <h4 {...props} className="h4">
                    {value}
                </h4>
            );
        case "h5":
            return (
                <h5 {...props} className="h5">
                    {value}
                </h5>
            );
        case "h6":
            return (
                <h6 {...props} className="h6">
                    {value}
                </h6>
            );
    }
}
