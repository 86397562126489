import { observer } from "mobx-react-lite";
import { AUCTION_STATUS, AUCTION_TYPE } from "auctions/_models/AuctionMdl";
import { Grid } from "@material-ui/core";
import styles from "auctions/view/_css/auctionMainInfo.module.css";
import BuyNowBtn from "auctions/buyNow/BuyNowBtn";
import PlaceOfferBtn from "auctions/offers/PlaceOfferBtn";
import { AuctionReserve } from "auctions/_common/AuctionReserve";
import { AuctionTimeLeft } from "auctions/view/AuctionTimeLeft";
import { AuctionLastBid } from "auctions/view/AuctionLastBid";
import React from "react";
import { useAuctionStore } from "auctions/AuctionContext";
import { useTranslation } from "react-i18next";
import { formatPrice } from "_common/_utils/currencyUtils";

const AuctionMainBottomInfos = observer(() => {
    const { t } = useTranslation();
    const auctionStore = useAuctionStore();
    const hasBuyNowButtons = auctionStore.auction.status === AUCTION_STATUS.BUY_NOW && auctionStore.auction.minPrice;
    return (
        <>
            {hasBuyNowButtons ? (
                <Grid container spacing={2}>
                    <Grid className={styles.bottomInfo} />
                    <Grid item xs={6}>
                        <div className={"flex_center_center flex_column"} style={{ width: "fit-content" }}>
                            <BuyNowBtn />
                            {auctionStore.auction.minPrice && (
                                <p className={"text_bold mt_10"}>{formatPrice(auctionStore.auction.minPrice)}</p>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <PlaceOfferBtn />
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid className={styles.bottomInfo} />
                    <Grid item xs={6}>
                        <AuctionReserve withReserve={auctionStore.auction.withReserve} />
                    </Grid>
                    <Grid item xs={6}>
                        <span className={styles.username}>{auctionStore.auction.user.nickname}</span> <br />
                        {t(
                            auctionStore.auction.auctionType === AUCTION_TYPE.DEALER
                                ? "auction.auctionTypeDealer"
                                : "auction.auctionTypePrivate",
                        )}
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <AuctionTimeLeft />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <AuctionLastBid />
                    </Grid>
                </Grid>
            )}
        </>
    );
});

export default AuctionMainBottomInfos;
