import React from "react";

type Props = {
    fill?: string;
    width?: number;
    className?: string;
};

export function Logo(props: Props) {
    const fill1 = props.fill ?? "#231f20";
    const fill2 = props.fill ?? "#171df3";
    const stroke1 = props.fill ?? "#ed2024";
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 139.43 55.62"
            style={props.width !== undefined ? { width: props.width } : undefined}
            fill="transparent"
            className={props.className}
        >
            <path
                fill={fill1}
                d="M68.61,133.05h8.74L77,135.6H70.84l-.45,3h6L76,141.15H70l-.78,5.34h-2.6Z"
                transform="translate(-66.38 -132.86)"
            />
            <path
                fill={fill1}
                d="M87.46,137l-1.38,9.5H83.59l.16-1.1a4.12,4.12,0,0,1-3,1.19c-2.12,0-3.89-2-3.46-4.85s2.51-4.84,4.86-4.84a3.13,3.13,0,0,1,2.64,1.21L85,137Zm-3.18,4.74a2,2,0,0,0-1.91-2.52,2.84,2.84,0,0,0-2.62,2.52,2,2,0,0,0,1.87,2.53A2.85,2.85,0,0,0,84.28,141.73Z"
                transform="translate(-66.38 -132.86)"
            />
            <path
                fill={fill1}
                d="M88,144.26l2.3-.84A1.6,1.6,0,0,0,92,144.66c1,0,1.61-.46,1.7-1.17.16-1.05-2.11-.55-3.56-1.22a2.14,2.14,0,0,1-1.18-2.44,4,4,0,0,1,4.12-3.13,3,3,0,0,1,3.29,2.59l-2.25.65a1.25,1.25,0,0,0-1.32-1.21,1.41,1.41,0,0,0-1.49,1.1c-.19,1.26,2.16.65,3.63,1.3a2.22,2.22,0,0,1,1.24,2.48c-.32,2-2.2,3.24-4.52,3.24A3.43,3.43,0,0,1,88,144.26Z"
                transform="translate(-66.38 -132.86)"
            />
            <path
                fill={fill1}
                d="M98.48,143.11l.57-3.78H97.44l.35-2.34h1.59l.41-2.77h2.51l-.41,2.77h2.38l-.34,2.34h-2.37l-.5,3.32c-.14,1,.3,1.59,1.24,1.59a2.79,2.79,0,0,0,1-.21l-.34,2.19a5.42,5.42,0,0,1-1.73.31C99.21,146.53,98.18,145.11,98.48,143.11Z"
                transform="translate(-66.38 -132.86)"
            />
            <path
                fill={fill1}
                d="M66.46,159.3c.58-4,3.66-7.11,7.49-7.11a5.42,5.42,0,0,1,5.1,3.69l-2.58,1.35a3.1,3.1,0,0,0-3-2.1,4.71,4.71,0,0,0-4.34,4.17c-.34,2.29,1,4.17,3.12,4.17a4.37,4.37,0,0,0,3.6-2.16l2.22,1.35a7.1,7.1,0,0,1-6.22,3.75C68.47,166.41,65.89,163.23,66.46,159.3Z"
                transform="translate(-66.38 -132.86)"
            />
            <path
                fill={fill1}
                d="M89.57,156.44l-1.4,9.77H85.62l.17-1.12a4.18,4.18,0,0,1-3,1.22c-2.17,0-4-2-3.54-5s2.56-5,5-5a3.18,3.18,0,0,1,2.69,1.24l.16-1.14Zm-3.24,4.87a2.06,2.06,0,0,0-2-2.58,2.9,2.9,0,0,0-2.67,2.58,2.07,2.07,0,0,0,1.91,2.61A2.93,2.93,0,0,0,86.33,161.31Z"
                transform="translate(-66.38 -132.86)"
            />
            <path
                fill={fill1}
                d="M91.77,156.44h2.56l-.24,1.62a4.68,4.68,0,0,1,3.77-1.74l-.41,2.86a2.76,2.76,0,0,0-3.84,2.21l-.71,4.82H90.34Z"
                transform="translate(-66.38 -132.86)"
            />
            <path
                fill={fill1}
                d="M68.61,174.67h5c2.32,0,4,1.36,3.59,4a3.54,3.54,0,0,1-1.63,2.54,2.78,2.78,0,0,1,1.19,2.88,4.37,4.37,0,0,1-4.76,4H66.66Zm3.69,11a1.76,1.76,0,0,0,1.81-1.57,1.27,1.27,0,0,0-1.35-1.61H70.11l-.46,3.18Zm.52-5.35a1.68,1.68,0,0,0,1.78-1.58,1.22,1.22,0,0,0-1.32-1.55H70.89l-.46,3.13Z"
                transform="translate(-66.38 -132.86)"
            />
            <path
                fill={fill1}
                d="M78.94,178.61h2.51l-1.4,9.5H77.54Zm.3-3a1.73,1.73,0,0,1,1.61-1.46A1.2,1.2,0,0,1,82,175.63a1.74,1.74,0,0,1-1.59,1.52A1.24,1.24,0,0,1,79.24,175.63Z"
                transform="translate(-66.38 -132.86)"
            />
            <path
                fill={fill1}
                d="M82.29,183.35c.43-2.9,2.61-4.84,4.89-4.84a2.88,2.88,0,0,1,2.6,1.42l.92-6.22h2.49l-2.1,14.4h-2.5l.18-1.33a3.86,3.86,0,0,1-3,1.42C83.5,188.2,81.87,186.25,82.29,183.35Zm7,0a2,2,0,0,0-1.91-2.52,2.86,2.86,0,0,0-2.62,2.52,2,2,0,0,0,1.88,2.53A2.85,2.85,0,0,0,89.28,183.35Z"
                transform="translate(-66.38 -132.86)"
            />
            <path
                fill={fill1}
                d="M93,185.88l2.3-.84A1.6,1.6,0,0,0,97,186.28c1,0,1.61-.46,1.7-1.17.16-1.05-2.11-.55-3.56-1.22a2.14,2.14,0,0,1-1.18-2.44A4,4,0,0,1,98,178.32a3,3,0,0,1,3.29,2.59l-2.25.65a1.25,1.25,0,0,0-1.32-1.21,1.41,1.41,0,0,0-1.49,1.1c-.2,1.26,2.16.65,3.63,1.3a2.21,2.21,0,0,1,1.23,2.48c-.31,2.05-2.19,3.24-4.51,3.24C94.84,188.47,93.24,187.51,93,185.88Z"
                transform="translate(-66.38 -132.86)"
            />
            <path d="M183.8,170" transform="translate(-66.38 -132.86)" />
            <path
                fill={fill2}
                d="M191.82,148.26a88,88,0,0,0-9.21-1.37l-3-.27s-24.75-.45-29-1c-3.22-.38-4.05-9.26-4.35-11.1l9.67.67a42.51,42.51,0,0,1,18.65,9.39c4.61.79,4.37,0,4.37,0-2.66-2.35-11.58-6.88-11.58-6.88-11.52-6.28-39-4.69-39-4.69-4.19.9-16.59,0-16.59,0s1.1,4.49,1.47,4.53-7.78,7.21-7.78,7.21c-.82,8,.09,6.7.09,6.7l1.67-5.53s7-1.44,41.22.29c24.25,1.22,37,1.47,43.86,3.52,4.93,1.47,7.09,1.65,8.39,2.48a24.2,24.2,0,0,0,4.88,2.44C202.32,150.61,196.71,149.26,191.82,148.26Zm-75-10.5c6.06-2.87,7.82-2.34,7.82-2.34L121.84,144C116.7,145.09,102.19,144.72,116.85,137.76Zm22.93,7.35A92.33,92.33,0,0,1,123.41,144s1.66-9.4,3.83-9.1l16.62-.1,1.9,9.93A52.26,52.26,0,0,1,139.78,145.11Z"
                transform="translate(-66.38 -132.86)"
            />
            <path
                fill={fill2}
                d="M110.57,159.28a15.47,15.47,0,0,1,4-5.37,9.52,9.52,0,0,1,3-1.79,8.17,8.17,0,0,1,3.51-.49,12.32,12.32,0,0,1,6.35,2.83,30,30,0,0,1,4.66,5l-.76-.39c3.5,0,7,0,10.51.11s7,.15,10.5.26,7,.26,10.5.45c1.75.08,3.5.21,5.25.33s3.49.23,5.24.39c-1.75.11-3.5.17-5.25.24s-3.51.15-5.26.18c-3.5.09-7,.13-10.51.15s-7,0-10.5,0-7-.12-10.51-.17h-.48l-.27-.38a29,29,0,0,0-4.21-4.81,11.09,11.09,0,0,0-5.41-2.81,8.08,8.08,0,0,0-5.84,1.57A16.91,16.91,0,0,0,110.57,159.28Z"
                transform="translate(-66.38 -132.86)"
            />
            <path
                fill={fill2}
                d="M178.28,159.42a11.51,11.51,0,0,1,3.66-5.17,9.11,9.11,0,0,1,6.31-2.08,11.32,11.32,0,0,1,6.08,2.49,17.07,17.07,0,0,1,4.14,4.76,54.87,54.87,0,0,0-5-3.46,12.93,12.93,0,0,0-5.34-1.8,9.22,9.22,0,0,0-5.29,1.36A20,20,0,0,0,178.28,159.42Z"
                transform="translate(-66.38 -132.86)"
            />
            <path
                stroke={stroke1}
                d="M116.54,171.86s44.45-13.43,55,4.45c0,0,27.09-2.23,33.71,4.51l-.74,5.13h-3.93s-7.7-13-16,0h-3.45s-10.81-6.22-46.83-1.4l-7.71.2s-8.06-8.84-16.67,1.2l-5-5.13V174.7Z"
                transform="translate(-66.38 -132.86)"
            />
            <path
                stroke={stroke1}
                d="M118.69,171.27s45.68-15.45,55,3.64l2.94,1.13"
                transform="translate(-66.38 -132.86)"
            />
            <path
                stroke={stroke1}
                d="M109.57,176c.34,2.28,20.45-2.87,23.64,2.68"
                transform="translate(-66.38 -132.86)"
            />
            <path
                stroke={stroke1}
                d="M135.72,174.1c-4.84-3.61,1.08-3.79,1.08-3.79s-11.11-1-5.2,3.79c0,0,2.29,2.07,3.93.73Z"
                transform="translate(-66.38 -132.86)"
            />
            <path stroke={stroke1} d="M136.8,178s60.9-2.75,63.34,2.38" transform="translate(-66.38 -132.86)" />
            <line stroke={stroke1} x1="113.62" y1="47.29" x2="112.37" y2="50.39" />
            <line stroke={stroke1} x1="112.07" y1="47.21" x2="111.04" y2="50.02" />
            <line stroke={stroke1} x1="110.63" y1="46.87" x2="109.63" y2="49.94" />
        </svg>
    );
}
