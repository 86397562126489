import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import { ChildrenComponentRenderer } from "components/ChildrenComponentRenderer";
import { Grid } from "@material-ui/core";

type Props = {
    style: CSSProperties;
    children: TComponent[];
    $component: TComponent;
};

export function GridContainerComponent({ children, $component, ...props }: Props) {
    return (
        <div {...props}>
            <Grid container spacing={3} style={{ marginTop: 0, marginBottom: 0 }}>
                <ChildrenComponentRenderer propName="children" parent={$component} children={children} />
            </Grid>
        </div>
    );
}
