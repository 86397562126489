import React from "react";
import { ICommentBaseMdl } from "comments/_models/CommentMdl";
import { useTranslation } from "react-i18next";

type Props = {
    comment: ICommentBaseMdl;
};

const AuctionCommentWithoutTitle = (props: Props) => {
    const { t } = useTranslation();
    return (
        <div className={"mv_25 text_big text_bold"}>
            {props.comment.textKey ? t(props.comment.textKey) : props.comment.text}
        </div>
    );
};

export default AuctionCommentWithoutTitle;
