import React from "react";
import { Route, Switch } from "react-router";
import { PageRouter } from "pages/PageRouter";
import { AuthenticatedRoute } from "_common/_utils/AuthenticatedRoute";
import { URLS } from "_configs/URLS";
import { ProfilePage } from "users/profile/ProfilePage";
import { PageRegular } from "pages/PageRegular";
import { AuctionNewPage } from "auctions/new/AuctionNewPage";
import { NoSsr } from "@material-ui/core";
import { AuctionEditPage } from "auctions/edit/AuctionEditPage";
import { AuctionViewPage } from "auctions/view/AuctionViewPage";
import { AuctionsListingPage } from "auctions/listing/AuctionsListingPage";
import { AuctionsClosedListingPage } from "auctions/listing/AuctionsClosedListingPage";
import { AuctionsSearchResultPage } from "main/menu/search/AuctionsSearchResultPage";
import AuctionsBuyNowListingPage from "auctions/listing/AuctionsBuyNowListingPage";

export function AppContent() {
    return (
        <PageRegular>
            <Switch>
                <AuthenticatedRoute path={URLS.user.profile(":tab?", ":lang")} component={ProfilePage} />
                <AuthenticatedRoute path={URLS.auction.new(":lang")} component={AuctionNewPage} />
                <AuthenticatedRoute
                    path={URLS.auction.edit(":auctionId", ":lang")}
                    component={() => (
                        <NoSsr>
                            <AuctionEditPage />
                        </NoSsr>
                    )}
                />
                <Route path={URLS.auction.see(":alias", ":lang?")} component={AuctionViewPage} />
                <Route path={URLS.home(":lang?")} exact={true} component={AuctionsListingPage} />
                <Route path={URLS.auction.ended()} component={AuctionsClosedListingPage} />
                <Route path={URLS.auction.buyNowOrMakeAnOffer()} component={AuctionsBuyNowListingPage} />
                <Route path={URLS.auction.search(":searchParams", ":lang?")} component={AuctionsSearchResultPage} />
                <Route exact path={URLS.auction.current()} component={AuctionsListingPage} />
                <Route path="/" component={() => <PageRouter />} />
            </Switch>
        </PageRegular>
    );
}
