import React from "react";
import { Paper } from "@material-ui/core";
import styles from "./_css/uiPaper.module.css";
import clsx from "clsx";

export const UiPaper = (props: React.ComponentProps<typeof Paper>) => {
    return (
        <Paper
            elevation={0}
            {...props}
            className={clsx(styles.container, props.className)}
            classes={{
                root: styles.root,
            }}
        />
    );
};
