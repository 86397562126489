import { ADMIN_PATH } from "admin/_configs/ADMIN_URLS";
import { appConfig } from "_configs/appConfig";
import sharedConfig from "_configs/sharedConfig";

class AnalyticsUtils {
    readonly crispPromise: Promise<any> | undefined;
    readonly enabled = !sharedConfig.isDev;

    constructor() {
        if (!__BROWSER__) {
            return;
        }
        this.crispPromise = this.loadCrisp().catch((err) => console.error(err));
        this._loadHotJar();
    }

    private loadCrisp() {
        return new Promise((resolve) => {
            (window as any).$crisp = [];
            (window as any).CRISP_WEBSITE_ID = "b2791daf-57f8-4d7e-8402-c322e01b6c73";
            (function () {
                const scriptElement = document.createElement("script");
                scriptElement.src = "https://client.crisp.chat/l.js";
                scriptElement.async = true;
                scriptElement.onload = () => resolve((window as any).$crisp);
                document.getElementsByTagName("head")[0].appendChild(scriptElement);
            })();
        });
    }

    _loadHotJar() {
        if (appConfig.hotjar.id && this.enabled) {
            (function (h: any, o: any, t: any, j: any, a?: any, r?: any) {
                h.hj =
                    h.hj ||
                    function () {
                        (h.hj.q = h.hj.q || []).push(arguments);
                    };
                h._hjSettings = { hjid: appConfig.hotjar.id, hjsv: 6 };
                a = o.getElementsByTagName("head")[0];
                r = o.createElement("script");
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
        }
    }

    setUserInfo(email: string) {
        this.crispPromise?.then((crisp) => {
            crisp.push(["set", "user:email", email]);
        });
    }

    trackPage(pagePath: string) {
        if (pagePath.startsWith(ADMIN_PATH)) {
            return;
        }
        try {
            if (sharedConfig.google.analytics && sharedConfig.isProd) {
                (window as any).gtag("config", sharedConfig.google.analytics, { page_path: pagePath });
                import("react-facebook-pixel")
                    .then((module) => module.default)
                    .then((ReactPixel) => {
                        ReactPixel.init(sharedConfig.facebook.pixel, undefined, {
                            autoConfig: true,
                            debug: !this.enabled,
                        });
                        ReactPixel.pageView();
                    });
            }
        } catch (err) {
            console.error(err);
        }
    }
}

const analyticsUtils = new AnalyticsUtils();
export { analyticsUtils };
