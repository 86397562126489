import React from "react";
import { TUserSummaryMdl } from "users/_models/UserMdl";
import { UserPhoto } from "users/view/UserPhoto";
import { useTranslation } from "react-i18next";
import styles from "./_css/userSummary.module.css";

type Props = {
    userSummary: TUserSummaryMdl;
    isSeller?: boolean;
};

export function UserSummary(props: Props) {
    const { t } = useTranslation();
    return (
        <div className="flex_row_center">
            <div className="mr_10">
                <UserPhoto photo={props.userSummary.photo} />
            </div>
            <div className="fontWeight_bold text_ellipsis maxWidth_label" title={props.userSummary.nickname}>
                {props.userSummary.nickname}
            </div>
            {props.isSeller && (
                <>
                    {"  "} - <div className={styles.seller}> {t("words.seller")} </div>
                </>
            )}
        </div>
    );
}
