import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { IAuctionEditionMdl, IUserAuctionListingMdl } from "auctions/_models/AuctionMdl";
import sharedConfig from "_configs/sharedConfig";
import { auctionsStore } from "auctions/_stores/auctionsStore";
import { toast } from "react-toastify";
import { getUserAuctionsStore } from "auctions/user/_stores/userAuctionsStore";
import { PaymentDialog } from "payments/paymentDialog/PaymentDialog";
import { formatPrice } from "_common/_utils/currencyUtils";

type Props = {
    open: boolean;
    onClose: () => void;
    auction?: IAuctionEditionMdl;
};

export const UserAuctionCancelDialog = observer((props: Props) => {
    const { t } = useTranslation();
    if (!props.auction) return null;

    const amount = props.auction.minPrice ? sharedConfig.fees.cancelWithReserve : sharedConfig.fees.cancelNoReserve;

    return (
        <div>
            <PaymentDialog
                onClose={() => props.onClose()}
                title={t("auction.cancel.checkoutTitle", { auctionName: props.auction.name })}
                amount={amount}
                cta={t("words.submit")}
                onPrepareCheckout={() => {
                    if (props.auction?._id) return auctionsStore.requestCancel(props.auction._id);
                    else return Promise.reject();
                }}
                order={props.auction}
                text={formatPrice(amount)}
                open={props.open}
                onSuccess={() => {
                    props.onClose();
                    getUserAuctionsStore().onUpdate({
                        ...props.auction,
                        cancelRequested: true,
                    } as IUserAuctionListingMdl);
                    toast.success(t("auction.cancel.success"));
                }}
            />
        </div>
    );
});
