import React from "react";
import styles from "./_css/footerLinks.module.css";
import { ISettingsMdl } from "settings/_models/SettingsMdl";
import clsx from "clsx";

const LINKS: { key: keyof ISettingsMdl["links"]; label: string }[] = [
    { key: "fb", label: "Facebook" },
    { key: "tw", label: "Twitter" },
    { key: "ig", label: "Instagram" },
    { key: "yt", label: "YouTube" },
    { key: "li", label: "LinkedIn" },
];

type Props = {
    links?: {
        fb?: string;
        tw?: string;
        ig?: string;
        yt?: string;
        li?: string;
    };
};

export function FooterLinks(props: Props) {
    const links = props.links;
    if (!links) return null;

    return (
        <div className={clsx(styles.container, "flex_center_center")}>
            <div className={styles.innerContainer}>
                {LINKS.map((link) => {
                    const settingsLink = links[link.key];
                    if (!settingsLink) return null;
                    return (
                        <a className={styles.link} key={link.key} href={settingsLink} rel="noopener noreferrer">
                            <i className={"aig-" + link.label.toLowerCase()} />
                        </a>
                    );
                })}
            </div>
        </div>
    );
}
