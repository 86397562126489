import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import { ChildrenComponentRenderer } from "components/ChildrenComponentRenderer";
import { Grid } from "@material-ui/core";

type Props = {
    style: CSSProperties;
    children: TComponent[];
    $component: TComponent;
} & React.ComponentProps<typeof Grid>;

export function GridItemComponent({ children, $component, ...props }: Props) {
    return (
        <Grid item {...props}>
            <ChildrenComponentRenderer propName="children" parent={$component} children={children} />
        </Grid>
    );
}
