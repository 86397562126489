import { ListStore } from "_common/list/ListStore";
import { IListProvider } from "_common/list/IListProvider";
import { fetchUtils } from "_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { ICommentMdl } from "comments/_models/CommentMdl";
import { action } from "mobx";
import eventsStore from "main/events/eventsStore";
import { reformatComment } from "comments/_utils/commentsUtils";

class AuctionCommentsProvider implements IListProvider<ICommentMdl> {
    readonly auctionId: string;

    constructor(auctionId: string) {
        this.auctionId = auctionId;
    }

    list(offset?: number, limit?: number): Promise<{ count: number; items: ICommentMdl[] }> {
        const url = `${sharedConfig.apiUrl}/auctions/${this.auctionId}/comments?offset=${offset}&limit=${limit}`;
        return fetchUtils.get<{ count: number; items: ICommentMdl[] }>(url).then(({ data: { count, items } }) => ({
            count,
            items: items.map((item) => reformatComment(item)),
        }));
    }

    putItemInCache(): void {}
}

export class AuctionCommentsStore {
    readonly auctionId: string;
    listStore: ListStore<ICommentMdl>;
    destroyCallbacks: (() => void)[] = [];

    constructor(auctionId: string) {
        this.auctionId = auctionId;
        this.listStore = new ListStore<ICommentMdl>("comments/" + auctionId, new AuctionCommentsProvider(auctionId));
        if (__BROWSER__) this.initWatcher();
    }

    postComment(text: string, repliedTo?: string) {
        const url = `${sharedConfig.apiUrl}/auctions/${this.auctionId}/comments`;
        return fetchUtils.post<ICommentMdl>(url, { text, repliedTo });
    }

    destroy() {
        this.destroyCallbacks.forEach((callback) => callback());
        delete this.listStore;
        delete this.destroyCallbacks;
    }

    private initWatcher() {
        const disposerCreated = eventsStore.on(
            "comments/created",
            action((event) => {
                const comment = event.payload.item;
                if (comment.repliedTo) {
                    const repliedComment = this.listStore.getSync(comment.repliedTo);
                    if (repliedComment) {
                        repliedComment.replies = repliedComment.replies ?? [];
                        repliedComment.replies.unshift(comment);
                    }
                } else {
                    this.listStore.onCreate(event.payload.item);
                }
            }),
        );
        this.destroyCallbacks.push(disposerCreated);

        const disposerUpdated = eventsStore.on(
            "comments/updated",
            action((event) => {
                const comment = event.payload.item;
                if (comment.repliedTo) {
                    const repliedComment = this.listStore.getSync(comment.repliedTo);
                    if (repliedComment && repliedComment.replies) {
                        const replyIndex = repliedComment.replies.findIndex((reply) => reply._id === comment._id);
                        if (replyIndex >= 0) repliedComment.replies.splice(replyIndex, 1, comment);
                    }
                } else {
                    this.listStore.onUpdate(comment);
                }
            }),
        );
        this.destroyCallbacks.push(disposerUpdated);

        const disposerDeleted = eventsStore.on(
            "comments/deleted",
            action((event) => {
                const comment = event.payload.item;
                if (comment.repliedTo) {
                    const repliedComment = this.listStore.getSync(comment.repliedTo);
                    if (repliedComment && repliedComment.replies) {
                        const replyIndex = repliedComment.replies.findIndex((reply) => reply._id === comment._id);
                        if (replyIndex >= 0) repliedComment.replies.splice(replyIndex, 1);
                    }
                } else {
                    this.listStore.onDelete(comment._id);
                }
            }),
        );
        this.destroyCallbacks.push(disposerDeleted);
    }
}
