import React from "react";
import styles from "./_css/header.module.css";
import { Container } from "@material-ui/core";
import clsx from "clsx";
import { Menu } from "main/menu/Menu";

function Header() {
    return (
        <>
            <header className={clsx("flex_row_center", "mui-fixed", styles.wrapper)}>
                <Container>
                    <div className={clsx(styles.container, "flex_row alignItems_center")}>
                        <Menu />
                    </div>
                </Container>
            </header>
            <div className={styles.placeholder} />
        </>
    );
}

export default Header;
