export const ADMIN_PATH = "/fcb-admin";

export const ADMIN_URLS = {
    home: () => ADMIN_PATH,

    users: {
        base: () => `${ADMIN_PATH}/users`,
        new: () => `${ADMIN_PATH}/users/edit`,
        edit: (id: string) => `${ADMIN_PATH}/users/edit/${id}`,
    },

    pages: {
        base: () => `${ADMIN_PATH}/pages`,
        new: () => `${ADMIN_PATH}/pages/edit`,
        edit: (id: string) => `${ADMIN_PATH}/pages/edit/${id}`,
    },

    auctions: {
        base: () => `${ADMIN_PATH}/auctions`,
        new: () => `${ADMIN_PATH}/auctions/edit`,
        edit: (id: string) => `${ADMIN_PATH}/auctions/edit/${id}`,
    },

    payments: {
        base: () => `${ADMIN_PATH}/payments`,
        edit: (id: string) => `${ADMIN_PATH}/payments/edit/${id}`,
    },

    comments: {
        base: () => `${ADMIN_PATH}/comments`,
        edit: (id: string) => `${ADMIN_PATH}/comments/edit/${id}`,
    },

    settings: {
        base: () => `${ADMIN_PATH}/settings`,
    },

    menu: {
        base: () => `${ADMIN_PATH}/menu`,
    },
};
