import React from "react";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "_common/ui/forms/Input";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAuctionStore } from "auctions/AuctionContext";

type Props = {
    admin?: boolean;
};

export function AuctionCarInformationForm(props: Props) {
    const { control, errors } = useFormContext();
    const { t } = useTranslation();

    const auctionStore = useAuctionStore();
    const disabled = !props.admin && !auctionStore.inEdition;

    return (
        <div>
            <h3 className="mb_20">{t("auction.carInformation")}</h3>
            <Grid spacing={3} container>
                <Grid item xs={12} md={4}>
                    <InputBlock required label={t("auction.brand")}>
                        <Controller
                            control={control}
                            name={"brand"}
                            disabled={disabled}
                            as={Input}
                            rules={{
                                required: t<string>("errors.forms.required"),
                            }}
                            error={errors.brand}
                        />
                    </InputBlock>
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputBlock required label={t("auction.type")}>
                        <Controller
                            control={control}
                            name={"type"}
                            disabled={disabled}
                            rules={{
                                required: t<string>("errors.forms.required"),
                            }}
                            error={errors.type}
                            as={Input}
                        />
                    </InputBlock>
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputBlock required label={t("auction.year")}>
                        <Controller
                            control={control}
                            name={"year"}
                            disabled={disabled}
                            type={"number"}
                            rules={{
                                required: t<string>("errors.forms.required"),
                                minLength: 4,
                                maxLength: 4,
                            }}
                            error={errors.year}
                            as={Input}
                        />
                    </InputBlock>
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputBlock required label={t("auction.mileage")} extralabel="(km)">
                        <Controller
                            control={control}
                            name={"mileage"}
                            type={"number"}
                            disabled={disabled}
                            as={Input}
                            rules={{
                                required: t<string>("errors.forms.required"),
                            }}
                            error={errors.mileage}
                        />
                    </InputBlock>
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputBlock label={t("auction.matriculation")} extralabel={t("auction.matriculationInfo")} required>
                        <Controller
                            control={control}
                            name={"matriculation"}
                            disabled={disabled}
                            rules={{
                                required: t<string>("errors.forms.required"),
                                minLength: { value: 17, message: t("errors.forms.minChar", { min: 17 }) },
                                maxLength: { value: 17, message: t("errors.forms.maxChar", { max: 17 }) },
                            }}
                            error={errors.matriculation}
                            as={Input}
                        />
                    </InputBlock>
                </Grid>
            </Grid>
        </div>
    );
}
