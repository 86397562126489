import styles from "./_css/errorBlock.module.css";
import React from "react";
import clsx from "clsx";
import { TErrMdl } from "shared/_common/errors/_models/ErrMdl";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { getErrorMessage } from "_common/errors/errorUtils";

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    error?: TErrMdl | string;
    onRetry?: () => void;
};

export const ErrorBlock = React.forwardRef<HTMLDivElement, Props>(
    ({ error, className, onRetry, ...props }: Props, ref) => {
        const { t } = useTranslation();

        if (!error) return null;
        return (
            <div className={clsx(styles.container, className)} {...props} ref={ref}>
                {getErrorMessage(error)}
                {onRetry ? (
                    <div className="mt_20">
                        <UiButton onClick={onRetry}>{t("words.retry")}</UiButton>
                    </div>
                ) : null}
            </div>
        );
    },
);
