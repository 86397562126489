import React from "react";
import { FilePicker, FilePickerProps } from "_common/ui/forms/images/FilePicker";

type Props = {
    value?: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
} & Omit<FilePickerProps, "value" | "onChange">;

export function SingleFilePicker(props: Props) {
    return (
        <FilePicker
            {...props}
            maxNbFiles={1}
            value={props.value ? [props.value] : undefined}
            onChange={(value) => props.onChange?.(value?.[0])}
        />
    );
}
