import React from "react";
import { observer } from "mobx-react";
import { ListStore } from "_common/list/ListStore";
import { IAuctionListingMdl } from "auctions/_models/AuctionMdl";
import { useTranslation } from "react-i18next";
import { LoadMoreBtn } from "_common/ui/utils/LoadMoreBtn";
import { AuctionsList } from "auctions/listing/AuctionsList";

type Props = {
    listStore: ListStore<IAuctionListingMdl>;
    textKey: string;
    isClosed?: boolean;
    sideList?: boolean;
};

export const AuctionsListObserver = observer((props: Props) => {
    const { t } = useTranslation();
    return (
        <div>
            <AuctionsList auctions={props.listStore.items} isClosed={props.isClosed} sideList={props.sideList} />
            {props.listStore.items.length === 0 && <div className="flex_row_center pv_20">{t(props.textKey)}</div>}
            <div className="flex_center_center mt_20 mb_10">
                <LoadMoreBtn listStore={props.listStore} />
            </div>
        </div>
    );
});
