import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./_css/auctionReserve.module.css";

type Props = {
    withReserve?: boolean;
};

export function AuctionReserve(props: Props) {
    const { t } = useTranslation();
    return props.withReserve ? (
        <div>{t("auction.card.reserve")}</div>
    ) : (
        <div className={styles.noReserve}>{t("auction.card.noReserve")}</div>
    );
}
