import React from "react";
import { useTranslation } from "react-i18next";
import { useAuctionStore } from "auctions/AuctionContext";
import clsx from "clsx";
import styles from "auctions/view/_css/auctionInfo.module.css";
import { Grid } from "@material-ui/core";

const AuctionChart = () => {
    const { t } = useTranslation();
    const auctionStore = useAuctionStore();

    if (auctionStore.auction.chart) {
        return (
            <div className={clsx("p_20", styles.container)}>
                <h3>{t("auction.view.chart")}</h3>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div dangerouslySetInnerHTML={{ __html: auctionStore.auction.chart }} />
                    </Grid>
                </Grid>
            </div>
        );
    } else return null;
};

export default AuctionChart;
