import { LoadingStateMdl, TLoadingStatus } from "shared/_common/loaders/_models/LoadingStateMdl";
import { reaction } from "mobx";

export function createLoadingStateFromPromise<TValue>(promise: Promise<TValue>) {
    const loadingState = new LoadingStateMdl<TValue>("LOADING", promise);
    promise.then(loadingState.setSuccess, loadingState.setError);
    return loadingState;
}

export function combineLoadingStatesStatuses(statuses: TLoadingStatus[]) {
    let allSucceeded = true;
    for (const status of statuses) {
        if (status === "LOADING") return "LOADING";
        else if (status === "IDLE") allSucceeded = false;
    }
    return allSucceeded && statuses.length > 0 ? "SUCCEEDED" : "IDLE";
}

export function combineLoadingStates(loadingStates: (LoadingStateMdl<any> | undefined)[]) {
    const filledLoadingStates = loadingStates.filter((loadingState) => !!loadingState) as LoadingStateMdl<any>[];
    if (filledLoadingStates.length === 1) {
        return filledLoadingStates[0];
    }
    const loadingState = new LoadingStateMdl();
    reaction(
        () => combineLoadingStatesStatuses(filledLoadingStates.map((loadingState) => loadingState.status)),
        loadingState.setStatus,
    );
    return loadingState;
}
