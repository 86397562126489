import React, { useState } from "react";
import { observer } from "mobx-react";
import { useAuctionStore } from "auctions/AuctionContext";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { GalleryCar } from "auctions/gallery/dialog/GalleryCar";
import { Tab, Tabs } from "@material-ui/core";
import { GALLERY_CATEGORIES } from "auctions/_models/AuctionMdl";
import { useTranslation } from "react-i18next";
import styles from "./_css/galleryPage.module.css";

export const GalleryPage = observer(() => {
    const [selectedTab, setSelectedTab] = useState<GALLERY_CATEGORIES>(GALLERY_CATEGORIES.ALL);
    const { t } = useTranslation();
    const auctionStore = useAuctionStore();
    const opened = auctionStore.galleryPageOpened;
    return (
        <Dialog
            closeCross={true}
            closeSize={"large"}
            fullScreen
            bottomCrossMobile
            withoutPlaceholder
            open={opened}
            onClose={() => auctionStore.closeGalleryPage()}
        >
            <Tabs
                className={styles.tabs}
                value={selectedTab}
                scrollButtons="auto"
                variant={"scrollable"}
                onChange={(_e, newValue) => {
                    setSelectedTab(newValue);
                }}
            >
                {auctionStore.galleriesWithContent.map((galleryCategory) => {
                    return (
                        <Tab label={t(`auction.${galleryCategory}`)} key={galleryCategory} value={galleryCategory} />
                    );
                })}
            </Tabs>
            <GalleryCar galleryCategory={selectedTab} />
        </Dialog>
    );
});
