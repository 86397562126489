import React, { lazy, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import "./_css/app.css";
import "./_css/fonts/aig.css";
import "./_css/fonts/font.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Switch } from "react-router-dom";
import { AuthenticatedRoute } from "shared/_common/_utils/AuthenticatedRoute";
import { AuthPage } from "users/auth/AuthPage";
import { StickyContainer } from "react-sticky";
import { Snackbars } from "main/snackbars/Snackbars";
import sharedConfig from "_configs/sharedConfig";
import Suspenser from "_common/loaders/Suspenser";
import { AppContent } from "AppContent";
import { ScrollTop } from "_common/_utils/ScrollTop";
import favicon from "./assets/images/favicon.png";
import { URLS } from "_configs/URLS";
import { ToastContainer } from "react-toastify";
import { OverlayObserver } from "main/overlay/OverlayObserver";
import { WebsocketStore } from "websockets/_stores/WebsocketStore";
import { CookieBanner } from "main/cookieBanner/CookieBanner";
import { NoSsr } from "@material-ui/core";
import { PageTracker } from "pages/PageTracker";

const LazyAdminRootPage = lazy(() => import("./admin/AdminRootPage"));

export const App: React.FC = () => {
    useMemo(() => {
        if (__BROWSER__) new WebsocketStore();
    }, []);
    return (
        <div>
            <Helmet
                defaultTitle="Fast Car Bids - Car auctions"
                link={[{ rel: "icon", type: "image/png", href: favicon }]}
            >
                <meta name="robots" content={sharedConfig.isProd ? "INDEX,FOLLOW" : "NOINDEX,NOFOLLOW"} />
                <meta
                    name="description"
                    content={`Online car auctions. Offered to Canadian enthusiasts. Buy/Sell. Low rates. Simple to use. Have the right exposure on your car. No time wasted.`}
                />
            </Helmet>
            <StickyContainer>
                <PageTracker>
                    <Switch>
                        <AuthenticatedRoute path={URLS.auth.base()} component={AuthPage} reverse={true} />
                        <Route
                            path={URLS.admin()}
                            component={() => (
                                <Suspenser>
                                    <LazyAdminRootPage />
                                </Suspenser>
                            )}
                        />
                        <Route path="/" component={AppContent} />
                    </Switch>
                </PageTracker>
            </StickyContainer>
            {/*<TemporaryMessageTable />*/}
            <NoSsr>
                <ScrollTop />
                <Snackbars />
                <ToastContainer />
                <OverlayObserver />
                <CookieBanner />
            </NoSsr>
        </div>
    );
};
