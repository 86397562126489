import { action, observable } from "mobx";
import { IPaymentMethodMdl } from "payments/paymentMethods/_models/PaymentMethodMdl";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { userStore } from "users/_stores/userStore";

class PaymentMethodsStore {
    readonly paymentMethods = observable<IPaymentMethodMdl>([]);
    readonly loadingState = new LoadingStateMdl();

    @action async load() {
        if (this.loadingState.isIdle && userStore.user) {
            this.loadingState.startLoading();
            try {
                const { data } = await fetchUtils.get<{ items: IPaymentMethodMdl[] }>(
                    `${sharedConfig.apiUrl}/users/${userStore.user?._id}/paymentMethods`,
                );
                this.paymentMethods.replace(data.items);
                this.loadingState.setSuccess();
            } catch (err) {
                this.loadingState.setError(err);
            }
        }
        return this.loadingState;
    }

    add(stripePaymentMethodId: string) {
        return fetchUtils
            .post<IPaymentMethodMdl>(`${sharedConfig.apiUrl}/users/${userStore.user?._id}/paymentMethods`, {
                stripePaymentMethodId,
            })
            .then(
                action(({ data }) => {
                    this.paymentMethods.push(data);
                    return data;
                }),
            );
    }

    delete(paymentMethodId: string) {
        return fetchUtils
            .delete(`${sharedConfig.apiUrl}/users/${userStore.user?._id}/paymentMethods/${paymentMethodId}`)
            .then(
                action(() => {
                    this.paymentMethods.splice(
                        this.paymentMethods.findIndex((paymentMethod) => paymentMethod.id === paymentMethodId),
                        1,
                    );
                }),
            );
    }
}

const paymentMethodsStore = new PaymentMethodsStore();
export { paymentMethodsStore };
