import React, { ReactNode } from "react";
import styles from "./_css/input.module.css";
import clsx from "clsx";
import { FieldError } from "react-hook-form/dist/types/form";

type Props<T = string> = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    error?: FieldError;
    onValueChange?: (value: T, event: React.ChangeEvent<HTMLInputElement>) => void;
    endIcon?: ReactNode;
    theme?: "white" | "gray" | "label";
};

export const Input = React.forwardRef<HTMLInputElement, Props>(
    ({ error, className, endIcon, onValueChange, onChange, ...props }, ref) => {
        return (
            <>
                <div className={styles.container}>
                    <input
                        {...props}
                        className={clsx(
                            styles.input,
                            styles.input_default,
                            {
                                [styles.inputWithEndIcon]: !!endIcon,
                                [styles.input_label]: props.theme === "label",
                                [styles.input_white]: props.theme === "white",
                                [styles.input_gray]: props.theme === "gray" || props.disabled,
                                [styles.input_error]: error,
                            },
                            className,
                        )}
                        ref={ref}
                        onChange={(event) => {
                            if (onChange) onChange(event);
                            if (onValueChange) onValueChange(event.target.value, event);
                        }}
                    />
                    {endIcon && <div className={styles.endIcon}>{endIcon}</div>}
                </div>
                {error && <div className={styles.error}>{error.message}</div>}
            </>
        );
    },
);
