import React from "react";
import { Container, NoSsr, Tab, Tabs } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { URLS } from "_configs/URLS";
import { ProfileInfo } from "users/profile/info/ProfileInfo";
import { ProfileWatchlist } from "./watchlist/ProfileWatchlist";

export function ProfilePage() {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams<{ tab?: string }>();
    const tab = params.tab?.toUpperCase() ?? "INFO";

    return (
        <NoSsr>
            <Container className="mt_20 mb_40">
                <Tabs
                    className="mb_10"
                    value={tab}
                    onChange={(_e, tab) => {
                        if (tab === "INFO") history.push(URLS.user.profile());
                        else if (tab === "WATCHLIST") history.push(URLS.user.watchlist());
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label={t("profile.title")} value="INFO" />
                    <Tab label={t("profile.watchlist.title")} value="WATCHLIST" />
                </Tabs>
                {tab === "WATCHLIST" ? <ProfileWatchlist /> : <ProfileInfo />}
            </Container>
        </NoSsr>
    );
}
