import React, { useEffect, useMemo, useRef, useState } from "react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { PaymentMethods } from "payments/paymentMethods/PaymentMethods";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { IPaymentMethodMdl } from "payments/paymentMethods/_models/PaymentMethodMdl";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { TErrMdl } from "_common/errors/_models/ErrMdl";
import { overlayStore } from "main/overlay/_stores/overlayStore";
import i18next from "i18next";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { paymentsStore } from "payments/_stores/paymentsStore";
import { NETWORK_ERROR } from "_common/errors/errorUtils";
import { Stripe } from "@stripe/stripe-js";

type Props = {
    title: string;
    text: string;
    cta: string;
    amount: number;
    loadingError?: TErrMdl;
    onRetry: () => void;
    open?: boolean;
    onClose: () => void;
    onSuccess?: () => void;
    order: any;
    onPrepareCheckout: () => Promise<{ clientCode: string }>;
};

const handleSubmit = async (
    stripe: Stripe,
    paymentMethod: IPaymentMethodMdl,
    clientSecret: string,
    onSuccess: () => void,
    onError: (err: any) => void,
    onLocalError: (err: any) => void,
) => {
    if (!stripe) return;
    overlayStore.open({
        children: (
            <div key="children" className="textAlign_center">
                <h3>{i18next.t("payments.inProgress.title")}</h3>
                <p>{i18next.t("payments.inProgress.text")}</p>
            </div>
        ),
    });

    try {
        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethod.id,
        });
        if (result.error) {
            throw result.error;
        }
        if (result.paymentIntent?.status === "succeeded") {
            onSuccess();
        }
    } catch (error) {
        if (error && error.type === "validation_error") {
            onLocalError(error);
        } else {
            onError(error && error.message ? error.message : { key: "global.errors.unknownLight" });
        }
    }
    overlayStore.close();
};

function PaymentDialogLoaded(props: Props) {
    const requestCounter = useRef(0);
    const [paymentMethod, setPaymentMethod] = useState<IPaymentMethodMdl>();
    const [prepareState, setPrepareState] = useState<{
        state: "PENDING" | "ERROR" | "DONE";
        error?: TErrMdl;
        clientSecret?: string;
    }>({
        state: "PENDING",
    });
    const stripe = useStripe();
    const error = props.loadingError ?? prepareState.error;

    function prepareCheckout() {
        requestCounter.current++;
        const counter = requestCounter.current;
        setPrepareState({ state: "PENDING" });
        props.onPrepareCheckout().then(
            ({ clientCode }) => {
                if (requestCounter.current !== counter) return;
                setPrepareState({ state: "DONE", clientSecret: clientCode });
            },
            (error) => {
                if (requestCounter.current !== counter) return;
                setPrepareState({ state: "ERROR", error });
            },
        );
    }

    useEffect(prepareCheckout, []);

    return (
        <Dialog fullWidth maxWidth={"md"} closeCross open={!!props.open} onClose={props.onClose}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent className="pb_20">
                <ErrorBlock
                    error={error}
                    onRetry={() => {
                        if (props.loadingError) props.onRetry();
                        else prepareCheckout();
                    }}
                />
                <p className="textAlign_center mb_20 fontWeight_bold">{props.text}</p>
                <div className="mt_10">
                    <PaymentMethods
                        onSelect={(paymentMethod) => setPaymentMethod(paymentMethod)}
                        selectedPaymentMethodId={paymentMethod?.id}
                    />
                </div>
            </DialogContent>
            <hr />
            <DialogActions>
                <UiButton
                    color={"primary"}
                    variant={"contained"}
                    disabled={!stripe || prepareState.state !== "DONE" || !paymentMethod || !!error}
                    onClick={() => {
                        if (stripe && paymentMethod && prepareState.clientSecret) {
                            handleSubmit(
                                stripe,
                                paymentMethod,
                                prepareState.clientSecret,
                                () => {
                                    props.onSuccess?.();
                                },
                                (error: any) => {
                                    console.error("error", error);
                                },
                                (error: any) => {
                                    console.error("local error", error);
                                },
                            );
                        }
                    }}
                >
                    {props.cta}
                </UiButton>
            </DialogActions>
        </Dialog>
    );
}

export const PaymentDialog = (props: Omit<Props, "onRetry" | "loadingError">) => {
    const [retryCounter, setRetryCounter] = useState(0);
    const [loadingError, setLoadingError] = useState<TErrMdl>();
    const stripePromise = useMemo(() => {
        const promise = paymentsStore.loadStripe(retryCounter > 0);
        promise.catch(() => {
            setLoadingError(NETWORK_ERROR);
        });
        return promise;
    }, [retryCounter]);

    return (
        <Elements stripe={stripePromise} key={retryCounter}>
            <PaymentDialogLoaded
                {...props}
                onRetry={() => setRetryCounter(retryCounter + 1)}
                loadingError={loadingError}
            />
        </Elements>
    );
};
