import React from "react";
import { Grid } from "@material-ui/core";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "_common/ui/forms/Input";
import { useTranslation } from "react-i18next";
import { useAuctionStore } from "auctions/AuctionContext";
import { TCarConfigDef } from "_configs/carConfig";
import { AuctionOptionSelect } from "auctions/form/options/AuctionOptionSelect";
import { ColorInput } from "auctions/form/adInformation/ColorInput";

type Props = {
    carOption: TCarConfigDef;
    admin?: boolean;
};

export function AuctionOptionForm(props: Props) {
    const form = useFormContext();
    const { t } = useTranslation();
    const auctionStore = useAuctionStore();
    const required = props.carOption.required ? { required: t<string>("errors.forms.required") } : undefined;
    const disabled = !props.admin && !auctionStore.inEdition;

    function renderInput(props: Props, disabled: boolean) {
        switch (props.carOption.type) {
            case "color":
                return (
                    <Controller
                        disabled={disabled}
                        type={props.carOption.key}
                        name={`options[${props.carOption.key}]`}
                        control={form.control}
                        as={ColorInput}
                    />
                );
            case "enum":
                return (
                    <Controller
                        disabled={disabled}
                        name={`options[${props.carOption.key}]`}
                        control={form.control}
                        items={props.carOption.enumValues}
                        defaultValue={props.carOption.enumValues[0].value}
                        as={AuctionOptionSelect}
                    />
                );
            default:
                return (
                    <Input
                        disabled={disabled}
                        ref={form.register(required)}
                        placeholder={t(`car.options.${props.carOption.key}Placeholder`)}
                        name={`options[${props.carOption.key}]`}
                        error={form.errors.options?.[props.carOption.key]}
                    />
                );
        }
    }

    return (
        <>
            <Grid item xs={12} md={4}>
                <InputBlock required={props.carOption.required} label={t(`car.options.${props.carOption.key}`)}>
                    {renderInput(props, disabled)}
                </InputBlock>
            </Grid>
        </>
    );
}
