import React, { useEffect } from "react";
import { Container } from "@material-ui/core";
import { useParams } from "react-router";
import { AuctionsListingSearchResult } from "main/menu/search/AuctionsListingSearchResult";
import { searchAuctionsStore } from "main/menu/search/_stores/searchStore";

export function AuctionsSearchResultPage() {
    const { searchParams } = useParams<{ searchParams: string | undefined }>();

    useEffect(() => {
        if (searchParams && !searchAuctionsStore.search) {
            searchAuctionsStore.setSearch(searchParams);
        }
    }, []);

    return (
        <Container className="mt_20">
            <AuctionsListingSearchResult />
        </Container>
    );
}
