import React, { PropsWithChildren } from "react";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { isImage } from "_common/_utils/fileUtils";
import styles from "./_css/filePreview.module.css";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

type Props = {
    url: string;
    isImage?: boolean;
    selected?: boolean;
    onDelete?: () => void;
    onClick?: () => void;
};

export function FilePreview(props: PropsWithChildren<Props>) {
    const displayImage = props.isImage || isImage(props.url);
    return (
        <div className={styles.container}>
            {displayImage ? (
                <img
                    className={clsx({ [styles.selectedContainer]: props.selected })}
                    src={props.url}
                    alt=""
                    onClick={(e) => {
                        if (props.onClick) {
                            e.stopPropagation();
                            props.onClick();
                        }
                    }}
                />
            ) : (
                <InsertDriveFileIcon fontSize="large" />
            )}
            {props.children}
            {props.onDelete && (
                <div
                    className={styles.delete}
                    onClick={(event) => {
                        event.stopPropagation();
                        props.onDelete?.();
                    }}
                >
                    <CloseIcon className={styles.deleteIcon} />
                </div>
            )}
        </div>
    );
}
