import React from "react";
import { RootMobileMenu } from "main/menu/mobile/RootMobileMenu";
import { useTranslation } from "react-i18next";
import { MENU_BUSINESS_ITEMS } from "main/menu/MenuBusiness";

type Props = {
    onClose: () => void;
};

export function MobileMenuBusiness(props: Props) {
    const translation = useTranslation();

    return (
        <>
            {MENU_BUSINESS_ITEMS(translation).map((item, index) => (
                <RootMobileMenu key={index} withLang={true} menu={item} onClose={props.onClose} />
            ))}
        </>
    );
}
