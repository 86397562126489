import React from "react";
import styles from "auctions/card/_css/auctionCard.module.css";
import { WatchBtn } from "auctions/watchList/WatchBtn";
import { FacebookBtn } from "_common/ui/share/FacebookBtn";
import { AUCTION_STATUS, IBaseAuctionMdl } from "auctions/_models/AuctionMdl";
import { EmailBtn } from "_common/ui/share/EmailBtn";
import sharedConfig from "_configs/sharedConfig";
import { URLS } from "_configs/URLS";
import { AuctionCopyLinkBtn } from "auctions/share/AuctionCopyLinkBtn";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { getCoverPhoto } from "auctions/_utils/auctionUtils";

type Props = {
    auction: IBaseAuctionMdl;
    imageOnly?: boolean;
    smallImage?: boolean;
};

export function AuctionThumbnail(props: Props) {
    const { t } = useTranslation();
    const thumbnail = getCoverPhoto(props.auction);
    const auctionUrl = sharedConfig.appUrl + URLS.auction.see(props.auction.alias);
    const isSold =
        !props.auction?.notAvailable &&
        (props.auction.soldManually || props.auction.sold || props.auction.status === AUCTION_STATUS.PAID);
    return (
        <div className={styles.content}>
            {props.auction?.notAvailable && (
                <div className={clsx(styles.soldThumbnail, "flex_center_center")}>{t("auction.view.notAvailable")}</div>
            )}
            {isSold && <div className={clsx(styles.soldThumbnail, "flex_center_center")}>{t("auction.view.sold")}</div>}
            <div
                className={clsx(styles.thumbnail, { [styles.smallThumbnail]: props.smallImage })}
                style={{
                    backgroundImage: `url('${
                        thumbnail?.url ?? "https://via.placeholder.com/200x250"
                    }'), url("https://via.placeholder.com/200x250")`,
                }}
            />
            {!props.imageOnly && (
                <>
                    <div className={styles.watchBtn}>
                        <WatchBtn auctionId={props.auction._id} />
                    </div>
                    <div className={styles.copyLink}>
                        <AuctionCopyLinkBtn auction={props.auction} />
                    </div>
                    <div className={styles.facebook}>
                        <FacebookBtn url={auctionUrl} />
                    </div>
                    <div className={styles.mail}>
                        <EmailBtn subject={props.auction.name} body={auctionUrl} />
                    </div>
                </>
            )}
        </div>
    );
}
