import React, { PropsWithChildren } from "react";
import { FilePicker } from "_common/ui/forms/images/FilePicker";
import { useAuctionStore } from "auctions/AuctionContext";
import { GALLERY_CATEGORIES } from "auctions/_models/AuctionMdl";

type Props = {
    galleryCategory: GALLERY_CATEGORIES;
};

export function AuctionGalleryEditorAddZone({ children, galleryCategory }: PropsWithChildren<Props>) {
    const auctionStore = useAuctionStore();
    return (
        <FilePicker
            value={[]}
            onChange={(newUrls) => auctionStore.addPhotos(newUrls, galleryCategory)}
            maxNbFiles={25}
            onlyImage
        >
            {children}
        </FilePicker>
    );
}
