import React, { ReactNode } from "react";
import { TLoadingStatus } from "shared/_common/loaders/_models/LoadingStateMdl";
import { TErrMdl } from "shared/_common/errors/_models/ErrMdl";
import { Loader } from "shared/_common/loaders/Loader";

type Props<TValue> = {
    status: TLoadingStatus;
    error?: TErrMdl;
    value?: TValue;
    renderer: (status: "IDLE" | "SUCCEEDED", error?: TErrMdl, value?: TValue) => ReactNode;
};

export function Loadable<TValue>(props: Props<TValue>) {
    if (props.status === "LOADING") {
        return <Loader />;
    }
    return <>{props.renderer(props.status, props.error, props.value)}</>;
}
