import React, { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton, NoSsr } from "@material-ui/core";
import styles from "./_css/mobileMenuBtn.module.css";
import { MobileMenu } from "main/menu/mobile/MobileMenu";

export function MobileMenuBtn() {
    const [opened, setOpened] = useState(false);

    return (
        <div>
            <IconButton className={styles.container} onClick={() => setOpened(!opened)}>
                <MenuIcon style={{ color: "#3e6e8c" }} fontSize="large" />
            </IconButton>
            <NoSsr>
                <MobileMenu opened={opened} onClose={() => setOpened(false)} />
            </NoSsr>
        </div>
    );
}
