import React from "react";
import { observer } from "mobx-react";
import { IPaymentMethodMdl } from "payments/paymentMethods/_models/PaymentMethodMdl";
import { paymentMethodsStore } from "payments/paymentMethods/_stores/paymentMethodsStore";
import { PaymentMethod } from "payments/paymentMethods/PaymentMethod";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { PaymentMethodForm } from "payments/paymentMethods/form/PaymentMethodForm";

export type PaymentMethodsListProps = {
    selectedPaymentMethodId?: string;
    onSelect?: (paymentMethod: IPaymentMethodMdl) => void;
    canDelete?: boolean;
};

export const PaymentMethodsList = observer((props: PaymentMethodsListProps) => {
    const [formOpened, setFormOpened] = React.useState(false);
    const { t } = useTranslation();
    return (
        <div>
            {paymentMethodsStore.paymentMethods.map((paymentMethod) => (
                <PaymentMethod
                    key={paymentMethod.id}
                    paymentMethod={paymentMethod}
                    selected={props.selectedPaymentMethodId === paymentMethod.id}
                    onSelect={props.onSelect ? () => props.onSelect?.(paymentMethod) : undefined}
                    canDelete={props.canDelete}
                />
            ))}
            {paymentMethodsStore.paymentMethods.length === 0 && (
                <div className="flex_center_center mt_20 mb_25">{t("paymentMethods.empty")}</div>
            )}
            <div className="mt_10">
                {!formOpened ? (
                    <UiButton
                        variant={props.onSelect ? "outlined" : "contained"}
                        color="primary"
                        onClick={() => setFormOpened(true)}
                    >
                        {t("paymentMethods.add")}
                    </UiButton>
                ) : (
                    <PaymentMethodForm onClose={() => setFormOpened(false)} />
                )}
            </div>
        </div>
    );
});
