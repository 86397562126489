import React, { CSSProperties, useState } from "react";
import clsx from "clsx";
import { ZoomIn } from "@material-ui/icons";
import { useHistory } from "react-router";
import styles from "./_css/cardComponent.module.css";
import { TComponent } from "../../../pages/_models/PageMdl";
import { CardTitle } from "components/base/card/CardTitle";

type Props = {
    title?: string;
    text?: string;
    titleHeader?: "h2" | "h3" | "h4";
    image?: string;
    url?: string;
    style?: CSSProperties;
    $component: TComponent;
};

export function CardComponent(props: Props) {
    const history = useHistory();
    const [isOverCard, setIsOverCard] = useState(false);

    return (
        <div
            style={props.style}
            onMouseEnter={() => setIsOverCard(true)}
            onMouseLeave={() => setIsOverCard(false)}
            onClick={() => {
                if (props.url?.startsWith("/")) {
                    history.push(props.url);
                } else if (props.url) {
                    window.location.href = props.url;
                }
            }}
            className={styles.solutionCard}
        >
            <div className={clsx(styles.container, "mb_40")}>
                <div className={clsx(styles.bgImage, "flex_center_center")}>
                    <img className={styles.image} src={props.image} style={{ opacity: isOverCard ? 0.5 : 1 }} alt="" />
                    {isOverCard && (
                        <div className={styles.icon}>
                            <ZoomIn style={{ width: 100, height: 100, opacity: 1 }} color={"primary"} />
                        </div>
                    )}
                </div>
                {props.title && (
                    <div className={"flex_row pt_20 ph_20"}>
                        <CardTitle title={props.title} titleHeader={props.titleHeader} className={styles.title} />
                    </div>
                )}
                {props.text && <div className={clsx(styles.resume, "flex_row p_20")}>{props.text}</div>}
            </div>
        </div>
    );
}
