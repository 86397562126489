import { TComponentsConfigs } from "components/ComponentRenderer";
import { GridContainerComponent } from "components/layout/gridContainer/GridContainerComponent";
import { GridItemComponent } from "components/layout/gridItem/GridItemComponent";
import { ContainerComponent } from "components/layout/container/ContainerComponent";
import { SeparatorComponent } from "components/layout/separator/SeparatorComponent";

export const LAYOUT_COMPONENTS_CONFIGS: TComponentsConfigs = {
    container: { component: ContainerComponent },
    gridContainer: { component: GridContainerComponent },
    gridItem: { component: GridItemComponent },
    separator: { component: SeparatorComponent },
};
