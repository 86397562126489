import { ICommentMdl } from "comments/_models/CommentMdl";
import dayjs from "dayjs";

export function reformatComment(comment: ICommentMdl) {
    const reformattedComment = { ...comment };
    reformattedComment.createdAt = dayjs(comment.createdAt);
    if (comment.replies) reformattedComment.replies = comment.replies.map(reformatComment);
    else reformattedComment.replies = [];
    return reformattedComment;
}
