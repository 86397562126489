import { ListStore } from "_common/list/ListStore";
import { IListProvider } from "_common/list/IListProvider";
import { IUserAuctionListingMdl } from "auctions/_models/AuctionMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { userStore } from "users/_stores/userStore";
import { reformatUserListingAuction } from "auctions/_utils/auctionUtils";
import eventsStore from "main/events/eventsStore";

class UserAuctionsProvider implements IListProvider<IUserAuctionListingMdl> {
    list(offset?: number, limit?: number): Promise<{ count: number; items: IUserAuctionListingMdl[] }> {
        const url = `${sharedConfig.apiUrl}/users/${userStore.user?._id}/auctions?offset=${offset}&limit=${limit}`;
        return fetchUtils
            .get<{ count: number; items: IUserAuctionListingMdl[] }>(url)
            .then(({ data: { count, items } }) => ({
                count,
                items: items.map(reformatUserListingAuction),
            }));
    }

    putItemInCache(): void {}
}

let userAuctionsStore: ListStore<IUserAuctionListingMdl> | undefined;

export function getUserAuctionsStore() {
    if (!userAuctionsStore) {
        userAuctionsStore = new ListStore("auctions/userListing", new UserAuctionsProvider());
        if (__BROWSER__) {
            eventsStore.on("auctions/created", (event) => {
                if (userAuctionsStore?.getLoadingState(1).isSucceeded) {
                    userAuctionsStore?.onCreate(event.payload.item);
                }
            });
        }
    }
    return userAuctionsStore;
}
