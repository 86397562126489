import { fetchUtils } from "shared/_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { tokenStore } from "users/_stores/tokenStore";

class AuthStore {
    signIn(email: string, password: string) {
        return fetchUtils
            .post<{ token: string }>(sharedConfig.apiUrl + "/auth/signIn", {
                email,
                password,
            })
            .then(({ data }) => tokenStore.setToken(data.token));
    }

    signUp(
        email: string,
        phone: string,
        password: string,
        nickname: string,
        firstName: string,
        lastName: string,
        // heardAboutUs: string,
        dailyNotification: boolean,
        weeklyNotification: boolean,
    ) {
        return fetchUtils
            .post<{ token: string }>(sharedConfig.apiUrl + "/auth/signUp", {
                email,
                phone,
                password,
                nickname,
                firstName,
                lastName,
                // heardAboutUs,
                dailyNotification,
                weeklyNotification,
            })
            .then(({ data }) => tokenStore.setToken(data.token));
    }

    askResetPassword(email: string) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/auth/askResetPassword", { email });
    }

    resetPassword(token: string, password: string) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/auth/resetPassword", { token, password });
    }

    signOut() {
        tokenStore.setToken(undefined);
        window.location.reload();
    }

    verify(search: string) {
        return fetchUtils.get<{ token: string }>(sharedConfig.apiUrl + "/auth/verify" + search);
    }
}

const authStore = new AuthStore();
export { authStore };
