import sharedConfig, { TLang } from "_configs/sharedConfig";
import { universalLanguageDetect } from "@unly/universal-language-detector";

export function detectLanguage(pathname: string, acceptLanguageHeader?: string) {
    if (pathname.startsWith("/en")) return sharedConfig.languages.en;
    if (pathname.startsWith("/fr")) return sharedConfig.languages.fr;
    const lang = universalLanguageDetect({
        supportedLanguages: ["en", "fr"],
        fallbackLanguage: "en",
        acceptLanguageHeader,
    }) as TLang;
    return sharedConfig.languages[lang];
}
