import React from "react";
import { Grid } from "@material-ui/core";
import { useAuctionStore } from "auctions/AuctionContext";
import styles from "../_css/auctionGalleryView.module.css";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";

export function AuctionGalleryView() {
    const auctionStore = useAuctionStore();
    const { t } = useTranslation();
    if (auctionStore.allPhotos.length === 0) return null;
    return (
        <Grid className={styles.container} onClick={() => auctionStore.openGalleryPage()} container spacing={2}>
            <Grid item xs={12}>
                <div
                    className={styles.mainPhoto}
                    style={{
                        backgroundImage: `url(${auctionStore.coverPhotos[0]?.url})`,
                    }}
                >
                    <div className={styles.seeAllPhotos}>
                        <UiButton color={"default"} variant={"contained"}>
                            {t("auction.gallery.seeAllPhotos", { nb: auctionStore.allPhotos.length })}
                        </UiButton>
                    </div>
                </div>
            </Grid>
            {auctionStore.coverPhotos[1] && (
                <Grid item xs={4}>
                    <div
                        className={styles.secondaryPhoto}
                        style={{
                            backgroundImage: `url(${auctionStore.coverPhotos[1]?.url})`,
                        }}
                    />
                </Grid>
            )}
            {auctionStore.coverPhotos[2] && (
                <Grid item xs={4}>
                    <div
                        className={styles.secondaryPhoto}
                        style={{
                            backgroundImage: `url(${auctionStore.coverPhotos[2]?.url})`,
                        }}
                    />
                </Grid>
            )}
            {auctionStore.coverPhotos[3] && (
                <Grid item xs={4}>
                    <div
                        className={styles.secondaryPhoto}
                        style={{
                            backgroundImage: `url(${auctionStore.coverPhotos[3]?.url})`,
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
}
