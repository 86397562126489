import React, { useState } from "react";
import styles from "./_css/searchBar.module.css";
import { Input } from "_common/ui/forms/Input";
import { observer } from "mobx-react";
import clsx from "clsx";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { searchAuctionsStore } from "main/menu/search/_stores/searchStore";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@material-ui/icons";

const KEY_ENTER = "Enter";

export const SearchBar = observer(() => {
    const [search, setSearch] = useState("");
    const lang = i18nextInstance.language;
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <div className={clsx("mr_20", styles.container)}>
            <Input
                placeholder={t("auction.search.pressEnter") + " ↩"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === KEY_ENTER) {
                        searchAuctionsStore.setSearch(search);
                        history.push(`/${lang}/search/${search}`);
                    }
                }}
                className={styles.searchInput}
                endIcon={<SearchOutlined />}
            />
        </div>
    );
});
