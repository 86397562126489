import { IListProvider } from "_common/list/IListProvider";
import { IAuctionListingMdl, IAuctionMdl } from "auctions/_models/AuctionMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { fetchUtils } from "_common/_utils/fetchUtils";
import { auctionsStore } from "auctions/_stores/auctionsStore";
import sharedConfig from "_configs/sharedConfig";

class AuctionsSearchListingStore implements IListProvider<IAuctionListingMdl> {
    list(offset = 0, limit?: number, _listId?: string, sort?: { [key: string]: number }, filters?: TFilter[]) {
        const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : "";
        let filtersParam = "";
        if (filters) filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
        const url = `${sharedConfig.apiUrl}/auctions/searchListing?offset=${offset}&limit=${limit}${sortParam}${filtersParam}`;
        const promise = fetchUtils
            .get<{ count: number; items: IAuctionMdl[] }>(url)
            .then(({ data: { count, items } }) => ({
                count,
                items: items.map((item) => {
                    const reformattedItem = auctionsStore.reformatItem(item);
                    return reformattedItem;
                }),
            }));
        return promise;
    }

    putItemInCache(): void {}
}

export const auctionsSearchListingStore = new AuctionsSearchListingStore();
