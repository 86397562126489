import "mobx-react-lite/batchingForReactDom";
import React from "react";
import { hydrate } from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import IntlProvider from "../shared/_common/i18n/IntlProvider";
import * as Sentry from "@sentry/browser";
import { App } from "App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "shared/_common/theme/theme";
import sharedConfig from "_configs/sharedConfig";
import "_common/_utils/analyticsUtils";

if (!sharedConfig.isDev && sharedConfig.sentry.dsn) {
    Sentry.init({
        dsn: sharedConfig.sentry.dsn,
        environment: sharedConfig.isStaging ? "staging" : "production",
    });
}

const Main = () => {
    React.useEffect(() => {
        const jssStyles = document.querySelector("#jss-server-side");
        jssStyles?.parentElement?.removeChild(jssStyles);
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <IntlProvider>
                    <HelmetProvider>
                        <App />
                    </HelmetProvider>
                </IntlProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
};

hydrate(<Main />, document.getElementById("app"));

if (process.env.NODE_ENV === "development") {
    if ((module as any).hot) {
        (module as any).hot.accept();
    }
}
