import React, { PropsWithChildren, useMemo, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { TErrMdl } from "_common/errors/_models/ErrMdl";
import { paymentsStore } from "payments/_stores/paymentsStore";
import { ErrorBlock } from "_common/errors/ErrorBlock";

export const StripeLoader = (props: PropsWithChildren<{}>) => {
    const [retryCounter, setRetryCounter] = useState(0);
    const [loadingError, setLoadingError] = useState<TErrMdl | undefined>();
    const [loaded, setLoaded] = React.useState(false);
    const stripePromise = useMemo(() => {
        const promise = paymentsStore.loadStripe(retryCounter > 0);
        promise
            .then(() => setLoaded(true))
            .catch(() => {
                setLoadingError({ key: "global.errors.network" });
            });
        return promise;
    }, [retryCounter]);

    return (
        <Elements stripe={stripePromise} key={retryCounter}>
            <ErrorBlock error={loadingError} onRetry={() => setRetryCounter(retryCounter + 1)} />
            {loaded && props.children}
        </Elements>
    );
};
