import React, { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import styles from "main/menu/_css/rootMenu.module.css";

type Props = {
    url?: string;
    className?: string;
    activeClassName?: string;
    onClick?: () => void;
};

export function ExternalOrNavLink(props: PropsWithChildren<Props>) {
    const url = props.url ?? "/";
    return url.startsWith("/") ? (
        <NavLink
            className={props.className}
            activeClassName={styles.menu_active}
            to={url}
            exact
            onClick={props.onClick}
        >
            {props.children}
        </NavLink>
    ) : (
        <a className={props.className} href={url} target="_blank" rel="noopener noreferrer" onClick={props.onClick}>
            {props.children}
        </a>
    );
}
