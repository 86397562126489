import React from "react";
import { observer } from "mobx-react";
import { ListStore } from "_common/list/ListStore";
import { ActionBtn } from "_common/ui/utils/ActionBtn";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";

type Props = {
    listStore: ListStore<any>;
};

export const LoadMoreBtn = observer((props: Props) => {
    const { t } = useTranslation();
    if (!props.listStore.hasMorePage) return null;

    return (
        <ActionBtn onExecute={() => props.listStore.setSelectedPage(props.listStore.selectedPage + 1).promise}>
            <UiButton variant="contained" color="primary">
                {t("words.loadMore")}
            </UiButton>
        </ActionBtn>
    );
});
