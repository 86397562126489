import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CardContent, CardHeader } from "@material-ui/core";
import { URLS } from "_configs/URLS";
import { Link, useLocation } from "react-router-dom";
import { authStore } from "users/auth/_stores/authStore";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ErrorBlock } from "_common/errors/ErrorBlock";

export function VerifiedBlock() {
    const { t } = useTranslation();
    const location = useLocation();
    const { loading, setPromise } = useLoadingFromPromise();

    useEffect(() => {
        if (!__BROWSER__) return;
        const promise = authStore.verify(location.search);
        setPromise(promise);
    }, []);

    if (!loading) return null;

    return (
        <div style={{ width: 450 }}>
            <CardHeader className="mt_10" style={{ textAlign: "center" }} title={t("auth.verifyAccount.title")} />
            <CardContent>
                <LoadableFromLoading
                    loading={loading}
                    renderer={(status, error) => {
                        return (
                            <div>
                                <ErrorBlock error={error} />
                                {status === "SUCCEEDED" && <div>{t("auth.verifyAccount.success")}</div>}
                            </div>
                        );
                    }}
                />
            </CardContent>
            <hr />
            <CardContent className="flex_center_center flex_column">
                <Link to={URLS.auth.signIn()} className="link">
                    {t("auth.verifyAccount.signIn")}
                </Link>
            </CardContent>
        </div>
    );
}
