import { IBidMdl, IBidPaymentStatusMdl } from "auctions/_models/BidMdl.ts";
import { userStore } from "users/_stores/userStore";
import dayjs, { Dayjs } from "dayjs";
import { UNKNOWN_ERROR } from "_common/errors/errorUtils";

export enum AUCTION_STATUS {
    DRAFT = "DRAFT",
    APPROVED = "APPROVED",
    PUBLISHED_NOT_LIVE = "PUBLISHED_NOT_LIVE",
    IN_PROGRESS = "IN_PROGRESS",
    CANCELED = "CANCELED",
    CLOSED = "CLOSED",
    CLOSED_NO_RESERVE = "CLOSED_NO_RESERVE",
    PAID = "PAID",
    BUY_NOW = "BUY_NOW",
}

export const AUCTION_CLOSED_STATUSES = [AUCTION_STATUS.CLOSED, AUCTION_STATUS.PAID];
export const AUCTION_SORTABLE_STATUSES = [
    AUCTION_STATUS.IN_PROGRESS,
    AUCTION_STATUS.BUY_NOW,
    ...AUCTION_CLOSED_STATUSES,
];
export enum AUCTION_TYPE {
    DEALER = "DEALER",
    PRIVATE = "PRIVATE",
}

export enum GALLERY_CATEGORIES {
    ALL = "photos",
    EXTERIOR = "photosExterior",
    INTERIOR = "photosInterior",
    ENGINE = "photosEngine",
    UNDERCARRIAGE = "photosUndercarriage",
    EXTRA = "photosExtra",
}

export type IOption = { [key: string]: string | number | boolean | undefined };

export type TAuctionPhoto = { url: string };
export type TLocation = { province: string; city: string; country: string };

export interface IAbstractAuctionMdl {
    _id: string;
    status: AUCTION_STATUS;
    sold?: boolean;
    soldManually: boolean;
    soldManuallyPrice?: number;
    notAvailable: boolean;
    buyNowIfNotSold: boolean;
    alias: string;
    name: string;
    mileage: number;
    options: IOption;
    location: TLocation;
    endDate: Dayjs;
    photos: TAuctionPhoto[];
    photosExterior: TAuctionPhoto[];
    photosInterior: TAuctionPhoto[];
    photosEngine: TAuctionPhoto[];
    photosUndercarriage: TAuctionPhoto[];
    photosExtra: TAuctionPhoto[];
    publishedDate?: Dayjs;
    withReserve?: boolean;
    minPrice?: number;
    carfax?: { url: string } | undefined;
    chart?: string;
    video1?: string;
    video2?: string;
    video3?: string;
    video4?: string;
    video5?: string;
    video6?: string;
    video7?: string;
    video8?: string;
}

export type IUserBaseAuctionMdl = IAbstractAuctionMdl;

export interface IBaseAuctionMdl extends IAbstractAuctionMdl {
    bids?: IBidMdl[];
    offers?: IBidMdl[];
}

export interface IAuctionMdl extends IBaseAuctionMdl {
    cancelRequested?: boolean;
    user: {
        _id: string;
        nickname?: string;
    };

    auctionType: AUCTION_TYPE;

    phone: string;
    year: number;
    brand: string;
    type: string;
    matriculation: string;
    extraOptions: string;
    issues: string;
    modifications: string;
    services: string;

    __v: number;
    updatedAt: Dayjs;
    createdAt: Dayjs;
    position: number;
}

export type IAuctionListingMdl = IBaseAuctionMdl;

export interface IUserAuctionListingMdl extends IUserBaseAuctionMdl {
    cancelRequested: boolean;
    withReserve: boolean;
    bids?: IBidPaymentStatusMdl[];
    offers?: IBidPaymentStatusMdl[];
}

export type IAuctionEditionMdl = Omit<IAuctionMdl, "_id" | "__v" | "alias" | "updatedAt" | "createdAt"> & {
    _id?: string;
    alias?: string;
    bids?: IBidMdl[];
    __v?: number;
};

export const createDefaultAuction = (): {
    year: number;
    endDate: dayjs.Dayjs;
    type: string;
    photos: any[];
    issues: string;
    services: string;
    modifications: string;
    matriculation: string;
    options: IOption;
    extraOptions: string;
    phone: string;
    minPrice: number;
    name: string;
    location: TLocation;
    auctionType: AUCTION_TYPE;
    user: { nickname: string; _id: string };
    brand: string;
    status: AUCTION_STATUS;
    mileage: number;
    carfax: { url: "" };
    soldManually: boolean;
    position: number;
    chart: string;
    video1: string;
    video2: string;
    video3: string;
    video4: string;
    video5: string;
    video6: string;
    video7: string;
    video8: string;
} => {
    const user = userStore.user;
    if (!user) throw UNKNOWN_ERROR;
    return {
        auctionType: AUCTION_TYPE.DEALER,
        status: AUCTION_STATUS.DRAFT,
        minPrice: 0,
        user: {
            _id: user._id,
            nickname: user.nickname,
        },
        name: "",
        phone: "",
        year: 2020,
        brand: "",
        type: "",
        extraOptions: "",
        options: {},
        location: { province: "QC", city: "", country: "canada" },
        matriculation: "",
        mileage: 0,
        issues: "",
        services: "",
        modifications: "",
        photos: [],
        endDate: dayjs().add(7, "day"),
        carfax: { url: "" },
        soldManually: false,
        position: 0,
        chart: "",
        video1: "",
        video2: "",
        video3: "",
        video4: "",
        video5: "",
        video6: "",
        video7: "",
        video8: "",
    };
};
