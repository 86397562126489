import React, { useState } from "react";
import { getUserAuctionsStore } from "auctions/user/_stores/userAuctionsStore";
import clsx from "clsx";
import styles from "auctions/user/_css/userAuction.module.css";
import { Grid } from "@material-ui/core";
import { formatPrice } from "_common/_utils/currencyUtils";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { auctionsStore } from "auctions/_stores/auctionsStore";
import { AUCTION_STATUS } from "auctions/_models/AuctionMdl";
import { URLS } from "_configs/URLS";
import { useHistory } from "react-router-dom";
import { reformatUserListingAuction } from "auctions/_utils/auctionUtils";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import { ConfirmationDialog } from "_common/ui/dialogs/ConfirmationDialog";
import { PAYMENT_STATUS } from "admin/payments/_models/CrudPaymentMdl";
import { UserOfferStatus } from "auctions/user/UserOfferStatus";

const UserOffersList = observer(() => {
    const { t } = useTranslation();
    const userAuctionsStore = getUserAuctionsStore();
    const history = useHistory();
    const hasOffers = userAuctionsStore.items
        .flatMap((auction) => {
            if (auction && auction.offers && auction.offers.length > 0) return auction?.offers;
        })
        .filter((el) => el !== undefined);
    const [action, setAction] = useState<
        { cb: () => Promise<void>; type: PAYMENT_STATUS.CAPTURED | PAYMENT_STATUS.CANCELED } | undefined
    >();
    if (!hasOffers || !hasOffers.length) return <p className={"flex_center_center"}>{t("profile.noOffer")}</p>;
    return (
        <div>
            {userAuctionsStore.items.map((auction) => {
                if (!auction?.offers?.length) return null;
                const link =
                    auction.status === AUCTION_STATUS.DRAFT || auction.status === AUCTION_STATUS.APPROVED
                        ? URLS.auction.edit(auction._id)
                        : URLS.auction.see(auction.alias);
                return auction.offers.reverse().map((offer, index) => {
                    const showActionButtons = 0 === index && offer.payment.status === "IN_HOLD";
                    return (
                        <div key={index} className={clsx("flex_row", styles.container)}>
                            <Grid justify={"space-between"} alignItems={"center"} container>
                                <Grid onClick={() => history.push(link)} item sm={4} xs={12}>
                                    {auction.name}
                                </Grid>
                                <Grid onClick={() => history.push(link)} item sm={2} xs={12}>
                                    {formatPrice(offer.price)}
                                </Grid>
                                {showActionButtons ? (
                                    <>
                                        <Grid item sm={3} xs={12} className={"flex_row"}>
                                            <div className={"flex-1"} />
                                            <UiButton
                                                success
                                                variant={"contained"}
                                                onClick={() => {
                                                    setAction({
                                                        type: PAYMENT_STATUS.CAPTURED,
                                                        cb: () =>
                                                            auctionsStore
                                                                .acceptOffer(auction._id)
                                                                .then(({ data: updatedAuction }) => {
                                                                    userAuctionsStore.onUpdate(
                                                                        reformatUserListingAuction(updatedAuction),
                                                                    );
                                                                    toast.success(
                                                                        t("comments.offers.successfullyAccept"),
                                                                    );
                                                                }),
                                                    });
                                                }}
                                            >
                                                {t("words.accept")}
                                            </UiButton>
                                        </Grid>
                                        <Grid item sm={3} xs={12} className={"flex_row"}>
                                            <div className={"flex-1"} />
                                            <UiButton
                                                color={"secondary"}
                                                variant={"contained"}
                                                onClick={() => {
                                                    setAction({
                                                        type: PAYMENT_STATUS.CANCELED,
                                                        cb: () =>
                                                            auctionsStore
                                                                .declineOffer(auction._id)
                                                                .then(({ data: updatedAuction }) => {
                                                                    userAuctionsStore.onUpdate(
                                                                        reformatUserListingAuction(updatedAuction),
                                                                    );
                                                                    toast.success(
                                                                        t("comments.offers.successfullyDecline"),
                                                                    );
                                                                }),
                                                    });
                                                }}
                                            >
                                                {t("words.decline")}
                                            </UiButton>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item sm={3} />
                                        <Grid item sm={3} className={"flex_row"}>
                                            <div className={"flex-1"} />
                                            <UserOfferStatus paymentStatus={offer.payment.status} />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </div>
                    );
                });
            })}
            <ConfirmationDialog
                agreeLabel={t("words.yes")}
                disagreeLabel={t("words.no")}
                onAgree={() => {
                    action
                        ?.cb()
                        .then(() => {
                            setAction(undefined);
                        })
                        .catch(() => toast.error(t("errors.unknownError")));
                }}
                title={t("offers.confirmationTitle")}
                message={
                    action?.type === PAYMENT_STATUS.CAPTURED
                        ? t("offers.confirmationAcceptMessage")
                        : t("offers.confirmationDeclineMessage")
                }
                onDisagree={() => setAction(undefined)}
                open={!!action}
            />
        </div>
    );
});

export default UserOffersList;
