import React, { ReactNode, useMemo } from "react";
import { useAuctionStore } from "auctions/AuctionContext";
import { AuctionCommentsStore } from "comments/_stores/AuctionCommentsStore";
import { AuctionCommentsContext } from "comments/AuctionCommentsContext";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { AuctionCommentForm } from "comments/AuctionCommentForm";
import { AuctionCommentsAndBidsList } from "comments/AuctionCommentsAndBidsList";
import { ICommentMdl } from "comments/_models/CommentMdl";

type Props = {
    actionsRenderer?: (comment: ICommentMdl) => ReactNode;
    hideBids?: boolean;
};

export function AuctionCommentsAndBids(props: Props) {
    const auctionStore = useAuctionStore();
    const auctionId = auctionStore.auction._id ?? "";
    const auctionCommentsStore = useMemo(() => {
        const store = new AuctionCommentsStore(auctionId);
        store.listStore.load();
        return store;
    }, [auctionId]);
    React.useEffect(() => () => auctionCommentsStore.destroy(), [auctionCommentsStore]);

    return (
        <AuctionCommentsContext auctionCommentsStore={auctionCommentsStore}>
            <LoadableFromLoading
                loading={auctionCommentsStore.listStore.getLoadingState(1)}
                renderer={(status, error) => (
                    <div>
                        <ErrorBlock error={error} onRetry={() => auctionCommentsStore.listStore.load()} />
                        {status === "SUCCEEDED" ? (
                            <div>
                                <div className="mb_25">
                                    <AuctionCommentForm />
                                </div>
                                <AuctionCommentsAndBidsList
                                    actionsRenderer={props.actionsRenderer}
                                    hideBids={props.hideBids}
                                />
                            </div>
                        ) : null}
                    </div>
                )}
            />
        </AuctionCommentsContext>
    );
}
