import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import clsx from "clsx";
import styles from "./_css/twoColsComponent.module.css";
import { Container, Grid, GridSize } from "@material-ui/core";
import { ChildrenComponentRenderer } from "components/ChildrenComponentRenderer";

type TVariant = "smallThenBig" | "bigThenSmall" | "halfHalf";
type TColSize = { xs?: GridSize; md?: GridSize };

type Props = {
    style?: CSSProperties;
    theme: "light" | "dark";
    col1Children: TComponent[];
    col2Children: TComponent[];
    variant: TVariant;
    reverse?: boolean;
    $component: TComponent;
};

function getColSizes(variant: TVariant): { col1: TColSize; col2: TColSize } {
    switch (variant) {
        case "smallThenBig":
            return {
                col1: { xs: 12, md: 4 },
                col2: { xs: 12, md: 8 },
            };
        case "bigThenSmall":
            return {
                col1: { xs: 12, md: 8 },
                col2: { xs: 12, md: 4 },
            };
        case "halfHalf":
        default:
            return {
                col1: { xs: 12, md: 6 },
                col2: { xs: 12, md: 6 },
            };
    }
}

export function TwoColsComponent(props: Props) {
    const colSizes = getColSizes(props.variant);
    return (
        <div
            style={props.style}
            className={clsx(styles.container, {
                [styles.light]: props.theme === "light",
                [styles.dark]: props.theme === "dark",
            })}
        >
            <Container>
                <Grid container spacing={3} style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid item {...colSizes.col1}>
                        <ChildrenComponentRenderer
                            propName={props.reverse ? "col2Children" : "col1Children"}
                            parent={props.$component}
                            children={props.reverse ? props.col2Children : props.col1Children}
                        />
                    </Grid>
                    <Grid item {...colSizes.col2}>
                        <ChildrenComponentRenderer
                            propName={props.reverse ? "col1Children" : "col2Children"}
                            parent={props.$component}
                            children={props.reverse ? props.col1Children : props.col2Children}
                        />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
