import React, { useState } from "react";
import { TRootMenu } from "settings/_models/MenuMdl";
import styles from "./_css/rootMenu.module.css";
import clsx from "clsx";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { SubGroupsMenu } from "main/menu/SubGroupsMenu";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { IconComponent } from "components/base/icon/IconComponent";
import { i18nextInstance } from "_common/i18n/IntlProvider";

type Props = {
    menu: TRootMenu;
    withLang?: boolean;
};

function renderInnerNav(props: Props, className: string) {
    return (
        <div className={clsx("flex_row_center", className, props.menu.className)}>
            {props.menu.image ? (
                <div className={styles.image} style={{ backgroundImage: `url('${props.menu.image}')` }} />
            ) : (
                props.menu.icon && <IconComponent style={{ marginRight: 5 }} icon={props.menu.icon} />
            )}{" "}
            <div className="text_ellipsis">{props.menu.title}</div>
        </div>
    );
}

function NavContent(props: Props) {
    return props.menu.highlighted ? (
        <UiButton variant={"contained"} color={"primary"}>
            {renderInnerNav(props, styles.textHighlighted)}
        </UiButton>
    ) : (
        renderInnerNav(props, styles.text)
    );
}

export function RootMenu(props: Props) {
    const [menuOpened, setMenuOpened] = useState(false);
    const menuClassName = clsx(styles.menu, "flex_center_center");
    return (
        <div
            className={clsx(styles.container, {
                [styles.container_separator]: props.menu.separator,
            })}
            onMouseEnter={() => setMenuOpened(true)}
            onMouseLeave={() => setMenuOpened(false)}
        >
            {props.menu.url ? (
                <ExternalOrNavLink
                    className={menuClassName}
                    activeClassName={styles.menu_active}
                    url={(props.withLang ? "" : "/" + i18nextInstance.language) + props.menu.url}
                >
                    <NavContent {...props} />
                </ExternalOrNavLink>
            ) : (
                <div className={menuClassName}>
                    <NavContent {...props} />
                </div>
            )}
            {menuOpened && props.menu.subGroups?.length && <SubGroupsMenu subGroups={props.menu.subGroups} />}
        </div>
    );
}
