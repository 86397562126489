import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IAuctionEditionMdl, IAuctionMdl } from "auctions/_models/AuctionMdl";
import { UiPaper } from "_common/ui/mui/surfaces/UiPaper";
import { useTranslation } from "react-i18next";
import { AuctionAdvancedCarForm } from "auctions/form/advanced/AuctionAdvancedCarForm";
import { AuctionOptionsForm } from "auctions/form/options/AuctionOptionsForm";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useAuctionStore } from "auctions/AuctionContext";
import { userStore } from "users/_stores/userStore";
import { observer } from "mobx-react";
import { AuctionCarInformationForm } from "./carInformation/AuctionCarInformationForm";
import dayjs from "dayjs";
import { AdForm } from "auctions/form/adInformation/AdForm";
import { PaymentDialog } from "payments/paymentDialog/PaymentDialog";
import { getErrorMessage, UNKNOWN_ERROR } from "_common/errors/errorUtils";
import { auctionsStore } from "auctions/_stores/auctionsStore";
import { getAuctionSubmittingFee } from "auctions/_utils/auctionUtils";
import { formatPrice } from "_common/_utils/currencyUtils";
import { toast } from "react-toastify";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import galleryGuide from "../../../shared/assets/fcb_gallery_guide.pdf";
import ContactInformationForm from "auctions/form/contactInformation/ContactInformationForm";

type Props = {
    onSuccess?: () => void;
};

export const AuctionForm = observer((props: Props) => {
    const auctionStore = useAuctionStore();
    const form = useForm<IAuctionEditionMdl>({
        mode: "onBlur",
        defaultValues: JSON.parse(JSON.stringify({ ...auctionStore.auction, phone: userStore?.user?.phone })),
    });
    const { t } = useTranslation();
    const [loading, setIsLoading] = useState(false);
    const [order, setOrder] = React.useState<IAuctionEditionMdl>();
    const postingFee = getAuctionSubmittingFee(order?.minPrice);

    return (
        <div className="mt_20">
            <FormProvider {...form}>
                <UiPaper className="p_25" color={"white"} elevation={0}>
                    <ContactInformationForm />
                </UiPaper>
                <UiPaper className="mt_30 p_25" color={"white"} elevation={0}>
                    <AdForm />
                </UiPaper>
                <UiPaper className="mt_30 p_25" color={"white"} elevation={0}>
                    <AuctionCarInformationForm />
                </UiPaper>
                <UiPaper className="mt_30 p_25" color={"white"} elevation={0}>
                    <AuctionOptionsForm />
                </UiPaper>
                <UiPaper className="mt_30 p_25" color={"white"} elevation={0}>
                    <AuctionAdvancedCarForm />
                </UiPaper>
                <UiPaper className="mt_30 p_25" color={"white"} elevation={0}>
                    <a href={galleryGuide} target={"_blank"} rel="noopener noreferrer">
                        {t("auction.form.galleryGuide")}
                    </a>
                    {t("auction.form.galleryGuideSuffix")}
                </UiPaper>
                <div className="mt_25 mb_25 ml_25">
                    {!auctionStore.isApproved ? (
                        <UiButton
                            onClick={form.handleSubmit(async (data) => {
                                setIsLoading(true);
                                const user = userStore.user;
                                if (!user) return;
                                const auction = {
                                    ...data,
                                    endDate: dayjs().add(7, "day"),
                                    user: {
                                        _id: user._id,
                                        nickname: user.nickname,
                                    },
                                } as IAuctionMdl;
                                if (!auctionStore.auction._id) {
                                    // setOrder(auction);
                                    auctionStore
                                        .save(auction)
                                        .promise?.then(() => {
                                            toast.success(t("auction.successfullyPosted"));
                                            props.onSuccess?.();
                                        })
                                        .catch((err) => {
                                            toast.error(getErrorMessage(err));
                                        })
                                        .finally(() => setIsLoading(false));
                                } else {
                                    auctionStore
                                        .save(auction)
                                        .promise?.then(
                                            () => {
                                                if (auctionStore.auctionSavingState.isSucceeded) {
                                                    props.onSuccess?.();
                                                } else {
                                                    toast.error(getErrorMessage(auctionStore.auctionSavingState.error));
                                                }
                                            },
                                            (err) => toast.error(getErrorMessage(err)),
                                        )
                                        .finally(() => setIsLoading(false));
                                }
                            })}
                            variant={"contained"}
                            color={"primary"}
                        >
                            {t(!loading ? "words.submit" : "loaders.message")}
                        </UiButton>
                    ) : null}
                </div>
            </FormProvider>
            {order ? (
                <PaymentDialog
                    title={t("auction.checkout.title")}
                    text={t("auction.checkout.text", { count: formatPrice(postingFee) } as any)}
                    cta={t("auction.checkout.cta", { count: formatPrice(postingFee) } as any)}
                    amount={postingFee}
                    onClose={() => {
                        setOrder(undefined);
                        setIsLoading(false);
                    }}
                    order={order}
                    open={!!order}
                    onPrepareCheckout={() =>
                        order
                            ? auctionsStore.checkout(order, getAuctionSubmittingFee(order?.minPrice))
                            : Promise.reject(UNKNOWN_ERROR)
                    }
                    onSuccess={() => {
                        setIsLoading(false);
                        props.onSuccess?.();
                    }}
                />
            ) : null}
        </div>
    );
});
