export function formatPrice(price: number) {
    price = price / 100;
    const thousands = Math.floor(price / 1000);
    const left: number = price % 1000;
    let _left;
    if (thousands) {
        if (left === 0) _left = "000";
        else if (left < 10) _left = "00" + left.toFixed(2);
        else if (left < 100) _left = "0" + left.toFixed(2);
        else _left = left.toFixed(2);
    } else _left = left.toFixed(2);

    return (thousands ? thousands + " " : "") + _left + " CA$";
}
