import React from "react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { searchAuctionsStore } from "main/menu/search/_stores/searchStore";
import { AuctionsList } from "auctions/listing/AuctionsList";
import { Container, NoSsr } from "@material-ui/core";
import clsx from "clsx";
import styles from "auctions/listing/_css/auctionsClosedListingPage.module.css";
import { useTranslation } from "react-i18next";

export function AuctionsListingSearchResult() {
    const { t } = useTranslation();

    return (
        <LoadableFromLoading
            loading={searchAuctionsStore.searchState}
            renderer={(_status, _error, value) => {
                if (!value) return null;
                return (
                    <Container className="mt_20 pb_10">
                        <h1 className={clsx("mb_25 flex_row_center flexWrap_wrap", styles.titleContainer)}>
                            <div className={"flex-1"}>
                                <div className={styles.title}>{t("auction.search.listing.title")}</div>
                            </div>
                        </h1>
                        <NoSsr>
                            {!value || value.length === 0 ? (
                                <div> {t("searchBar.noResult")} </div>
                            ) : (
                                <AuctionsList auctions={value} />
                            )}
                        </NoSsr>
                    </Container>
                );
            }}
        />
    );
}
