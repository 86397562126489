import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";

type Props = {
    disabled: boolean;
    name: string;
    defaultValue: string;
    items: { value: string; label: string | undefined }[];
    onChange: (value: string) => void;
    value?: string;
};

export function AuctionOptionSelect(props: Props) {
    return (
        <UiSelect
            disabled={props.disabled}
            name={props.name}
            value={props.value ?? props.defaultValue}
            onChange={(e) => props.onChange(e.target.value as string)}
        >
            {props.items.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </UiSelect>
    );
}
