import { IListProvider } from "_common/list/IListProvider";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { auctionsSearchListingStore } from "auctions/_stores/auctionsSearchListingStore";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { observable } from "mobx";

class SearchStore<T> {
    search = "";
    provider: IListProvider<T>;
    @observable searchState = new LoadingStateMdl();

    constructor(provider: IListProvider<T>) {
        this.provider = provider;
    }

    async fetchResults() {
        if (!this.searchState.isLoading) {
            this.searchState.startLoading();
            this.provider
                .list(undefined, 0, undefined, undefined, [
                    { id: "name", type: TFilterType.STRING, value: this.search.toLowerCase() },
                    { id: "location.province", type: TFilterType.STRING, value: this.search.toLowerCase() },
                    { id: "location.city", type: TFilterType.STRING, value: this.search.toLowerCase() },
                ])
                .then((data) => {
                    this.searchState.setSuccess(data.items);
                })
                .catch((error) => this.searchState.setError(error));
        }
    }

    setSearch(search: string) {
        this.search = search;
        this.fetchResults();
    }
}

export const searchAuctionsStore = new SearchStore(auctionsSearchListingStore);
