import React, { useEffect, useState } from "react";
import { Slider } from "_common/slider/Slider";
import clsx from "clsx";
import { NoSsr } from "@material-ui/core";
import AlbumIcon from "@material-ui/icons/Album";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styles from "./_css/fullSizeSlider.module.css";
import { AutoSizer } from "react-virtualized";

type TSlide = { title?: string; text?: string; img: string };

type Props = {
    slides: TSlide[];
    autoRotateDelay?: number;
    withBottomPadding?: boolean;
};

function renderSlide(key: any, slide: TSlide, width: number | string) {
    return (
        <div key={key} style={{ width, backgroundImage: `url('${slide.img}')` }} className={styles.slide}>
            <div className={styles.textContainer}>
                <div className={styles.title}>{slide.title}</div>
                <div className={styles.text}>{slide.text}</div>
            </div>
        </div>
    );
}

const renderServer = (props: Props) => {
    return <div>{renderSlide(0, props.slides[0], "100%")}</div>;
};

export const FullSizeSlider = (props: Props) => {
    const [slideIndex, setSlideIndex] = useState(0);
    const [hover, setHover] = useState(false);

    const showNextImage = () => {
        setSlideIndex(slideIndex < props.slides.length - 1 ? slideIndex + 1 : 0);
    };

    useEffect(() => {
        if (hover || !props.autoRotateDelay) return;
        const timeoutId = setTimeout(showNextImage, props.autoRotateDelay);
        return () => clearTimeout(timeoutId);
    }, [showNextImage, hover, props.autoRotateDelay]);

    if (props.slides.length === 0) return null;

    return (
        <div
            className={clsx({
                [styles.container_withBottomPadding]: props.withBottomPadding,
            })}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <NoSsr fallback={renderServer(props)}>
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <Slider
                            index={slideIndex}
                            slideWidth={width}
                            onChange={setSlideIndex}
                            nbSlides={props.slides.length}
                        >
                            {props.slides.map((slide, index) => renderSlide(index, slide, width))}
                        </Slider>
                    )}
                </AutoSizer>
            </NoSsr>

            {props.slides.length > 1 && (
                <>
                    <div
                        className={styles.arrowLeft}
                        onClick={() => setSlideIndex(slideIndex === 0 ? props.slides.length - 1 : slideIndex - 1)}
                    >
                        <ArrowBackIosIcon fontSize={"large"} />
                    </div>
                    <div className={styles.arrowRight} onClick={showNextImage}>
                        <ArrowForwardIosIcon fontSize={"large"} />
                    </div>

                    <div className={clsx(styles.navigationDots, "flex_row justifyContent_center")}>
                        {props.slides.map((_slide, index) => {
                            return index === slideIndex ? (
                                <AlbumIcon key={index} className={styles.navigationDot} />
                            ) : (
                                <Brightness1Icon
                                    key={index}
                                    className={styles.navigationDot}
                                    onClick={() => setSlideIndex(index)}
                                />
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
};
