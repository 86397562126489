import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "auctions/view/_css/auctionInfo.module.css";
import { AuctionCommentsAndBids } from "comments/AuctionCommentsAndBids";

export function AuctionFeed() {
    const { t } = useTranslation();
    return (
        <div id="commentsAndBids" className={clsx("p_20", styles.container)}>
            <h3 className="mb_20">{t("auction.view.commentsAndBids")}</h3>
            <AuctionCommentsAndBids />
        </div>
    );
}
