import React from "react";
import Header from "main/menu/Header";
import { Footer } from "main/footer/Footer";
import styles from "_css/appContent.module.css";
import clsx from "clsx";
import { NoSsr } from "@material-ui/core";
import NoListingFeesPopup from "_common/ui/popup/NoListingFeesPopup";

export const PAGE_REF = { current: null as any };

export function PageRegular(props: any) {
    return (
        <div ref={(ref) => (PAGE_REF.current = ref)} className={styles.container}>
            <div className={clsx("flex_column", styles.innerContainer)}>
                <NoSsr>
                    <NoListingFeesPopup />
                </NoSsr>
                <Header />
                <div className="flex-1 position_relative">{props.children}</div>
                <Footer />
            </div>
        </div>
    );
}
