import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Input } from "shared/_common/ui/forms/Input";
import { InputBlock } from "shared/_common/ui/forms/InputBlock";
import { useLoadingFromPromise } from "shared/_common/loaders/useLoadingFromPromise";
import { observer } from "mobx-react";
import { authStore } from "users/auth/_stores/authStore";
import { ErrorBlock } from "shared/_common/errors/ErrorBlock";
import { CardContent, CardHeader, IconButton } from "@material-ui/core";
import { UiButton } from "shared/_common/ui/mui/buttons/UiButton";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Visibility, VisibilityOff } from "@material-ui/icons";

type TFormData = {
    email: string;
    password: string;
};

export const SignInBlock = observer(() => {
    const { t } = useTranslation();
    const { handleSubmit, register, errors } = useForm<TFormData>({
        mode: "onBlur",
    });
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const { loading, setPromise } = useLoadingFromPromise();
    const onSubmit = (values: TFormData) => {
        const promise = authStore.signIn(values.email, values.password);
        setPromise(promise);
        promise.then(() => history.push(URLS.home()));
    };
    const isLoading = loading?.status === "LOADING";

    return (
        <div style={{ width: 450 }}>
            <CardHeader className="mt_10" style={{ textAlign: "center" }} title={t("auth.signIn.title")} />
            <CardContent>
                <ErrorBlock error={loading?.error} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputBlock className="mb_25" label={t("words.email")}>
                        <Input
                            name="email"
                            ref={register({
                                required: t<string>("errors.forms.required"),
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: t("errors.forms.emailInvalid"),
                                },
                            })}
                            error={errors.email}
                            disabled={isLoading}
                        />
                    </InputBlock>
                    <InputBlock className="mb_40" label={t("words.password")}>
                        <Input
                            name="password"
                            type={showPassword ? "text" : "password"}
                            ref={register({
                                required: t<string>("errors.forms.required"),
                            })}
                            error={errors.password}
                            disabled={isLoading}
                            endIcon={
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    size="small"
                                >
                                    {showPassword ? (
                                        <Visibility fontSize="small" />
                                    ) : (
                                        <VisibilityOff fontSize="small" />
                                    )}
                                </IconButton>
                            }
                        />
                    </InputBlock>
                    <div className="flex_center_center mb_15">
                        <UiButton type="submit" disabled={isLoading} variant="contained" color="primary">
                            {t(isLoading ? "loaders.message" : "auth.signIn.title")}
                        </UiButton>
                    </div>
                </form>
            </CardContent>
            <hr />
            <CardContent className="flex_center_center flex_column">
                <div>{t("auth.signUp.notRegisteredYet")}</div>
                <Link to={URLS.auth.signUp()} className="link">
                    {t("auth.signUp.title")}
                </Link>
            </CardContent>
            <hr />
            <CardContent className="flex_center_center flex_column">
                <div>{t("auth.forgottenPassword.forgotPassword")}</div>
                <Link to={URLS.auth.askResetPassword()} className="link">
                    {t("auth.forgottenPassword.title")}
                </Link>
            </CardContent>
        </div>
    );
});
