import { action, autorun, computed, observable } from "mobx";
import { tokenStore } from "users/_stores/tokenStore";
import { IUserMdl } from "users/_models/UserMdl";
import { authStore } from "users/auth/_stores/authStore";
import { fetchUtils, TFilesData } from "shared/_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { analyticsUtils } from "_common/_utils/analyticsUtils";

class UserStore {
    @observable user: IUserMdl | undefined = undefined;
    private readonly _setDataFromToken = action((_token: string | undefined) => {
        const tokenData = tokenStore.getTokenData<IUserMdl>();
        if (!tokenData) {
            if (this.isLogged) authStore.signOut();
        } else {
            this.user = tokenData;
            analyticsUtils.setUserInfo(this.user.email);
        }
    });

    constructor() {
        autorun(() => {
            const token = tokenStore.token;
            this._setDataFromToken(token);
        });
    }

    @computed get isLogged() {
        return this.user !== undefined;
    }

    @computed get isAdmin() {
        return this.user?.roles.includes("ADMIN");
    }

    deleteUser() {
        return fetchUtils.delete(sharedConfig.apiUrl + "/users/" + this.user?._id).then(() => authStore.signOut());
    }

    save(user: IUserMdl, files?: TFilesData) {
        const body = files ? fetchUtils.createBodyWithFiles(user, files) : user;
        return fetchUtils.patch(sharedConfig.apiUrl + "/users/" + this.user?._id, body, !!files).then(({ data }) => {
            tokenStore.refreshToken();
            this.user = data as IUserMdl;
        });
    }
}

export const userStore = new UserStore();
