import React from "react";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import styles from "./_css/footerMenu.module.css";
import clsx from "clsx";

type Props = {
    title: string;
    links: { url: string; title: string }[];
    className?: string;
};

export function FooterMenu(props: Props) {
    return (
        <div className={clsx(props.className, "mb_20")}>
            <h5 className={styles.title}>{props.title}</h5>
            {props.links.map((link, index) => (
                <ExternalOrNavLink key={index} className={styles.link} url={link.url}>
                    {link.title}
                </ExternalOrNavLink>
            ))}
        </div>
    );
}
