import React from "react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { pagesStore } from "pages/_stores/pagesStore";
import { FullSizeSlider } from "components/sliders/FullSizeSlider";

export const FullSizePagesSlider = () => {
    return (
        <LoadableFromLoading
            loading={pagesStore.getFeaturedPages()}
            renderer={(_status, _error, featuredPages) => {
                if (!featuredPages) return null;
                return (
                    <FullSizeSlider
                        slides={featuredPages.map((page) => ({
                            title: page.title,
                            text: page.description,
                            img: page.image ?? "",
                        }))}
                        autoRotateDelay={5000}
                        withBottomPadding
                    />
                );
            }}
        />
    );
};
