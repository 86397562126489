import React, { useMemo, useState } from "react";
import { Container } from "@material-ui/core";
import { AuctionForm } from "auctions/form/AuctionForm";
import { AuctionContext } from "auctions/AuctionContext";
import { AuctionStore } from "auctions/_stores/AuctionStore";
import { createDefaultAuction } from "auctions/_models/AuctionMdl";
import { useTranslation } from "react-i18next";
import { AuctionNewSuccess } from "./AuctionNewSuccess";

export function AuctionNewPage() {
    const { t } = useTranslation();
    const auctionStore = useMemo(() => new AuctionStore(createDefaultAuction()), []);
    const [isSuccess, setIsSuccess] = useState(false);

    return (
        <Container className="mt_20">
            <AuctionContext auctionStore={auctionStore}>
                {!isSuccess ? (
                    <>
                        <h2>{t("auction.new")}</h2>
                        <AuctionForm onSuccess={() => setIsSuccess(true)} />
                    </>
                ) : (
                    <AuctionNewSuccess />
                )}
            </AuctionContext>
        </Container>
    );
}
