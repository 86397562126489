import { TDef } from "admin/pages/builder/props/values/ValueEditor";

export type TCarConfigDef = TDef & { key: string; required: boolean };

export const CAR_OPTIONS_CONFIG: TCarConfigDef[] = [
    { key: "exteriorColor", type: "color", required: true, onlyString: true },
    { key: "interiorColor", type: "color", required: true, onlyString: true },
    {
        key: "vehTitleStatus",
        type: "enum",
        required: true,
        enumValues: [
            { value: "clean", label: "Clean" },
            { value: "salvage", label: "Salvage" },
            { value: "rebuilt", label: "Rebuilt" },
        ],
    },
    {
        key: "driveTrain",
        type: "enum",
        required: true,
        enumValues: [
            { value: "4wd", label: "4WD/AWD" },
            { value: "fwd", label: "FWD" },
            { value: "rwd", label: "RWD" },
            { value: "6wd", label: "6x6/6WD" },
        ],
    },
    { key: "engine", type: "string", required: true, onlyString: true },
    {
        key: "transmission",
        type: "enum",
        required: true,
        enumValues: [
            { value: "automatic", label: "Automatic" },
            { value: "manual", label: "Manual" },
            { value: "dualClutch", label: "Dual-clutch" },
            { value: "dsg", label: "DSG" },
            { value: "tiptronic", label: "Tiptronic" },
        ],
    },
];

export const CAR_COLOR = {
    "#FFFFFF": "white",
    "#C0C0C0": "silver",
    "#808080": "gray",
    "#000000": "black",
    "#FFA500": "orange",
    "#FF8C00": "darkorange",
    "#FF0000": "red",
    "#800000": "maroon",
    "#FFD700": "gold",
    "#FFFF00": "yellow",
    "#808000": "olive",
    "#00FF00": "lime",
    "#008000": "green",
    "#00FFFF": "aqua",
    "#008080": "teal",
    "#0000FF": "blue",
    "#000080": "navy",
    "#FF69B4": "pink",
    "#FF00FF": "fuchsia",
    "#800080": "purple",
    "#4B0082": "indigo",
    "#e6cf79": "beige",
    "#16c3f7": "lightblue",
    "#06c409": "lightgreen",
    "#f7e011": "lightyellow",
    "#544841": "brown",
    "#e8e8df": "altwhite",
    "#6a20ba": "altpurple",
    "#070b78": "darkblue",
};
