import React from "react";
import { Container, MenuItem, NoSsr } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { AuctionsListObserver } from "./AuctionsListObserver";
import { AUCTION_LISTING_SORT, getAuctionsListingStore } from "auctions/_stores/auctionsListingStore";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { Link } from "react-router-dom";
import clsx from "clsx";
import styles from "./_css/auctionsListingPage.module.css";
import { URLS } from "_configs/URLS";

export function AuctionsListingPage() {
    const [sort, setSort] = React.useState<AUCTION_LISTING_SORT>(AUCTION_LISTING_SORT.ENDING_SOON);
    const { t } = useTranslation();
    return (
        <Container className="mt_20">
            <h1 className={clsx("mb_25 flex_row_center flexWrap_wrap", styles.titleContainer)}>
                <div className={"flex-1"}>
                    <div className={clsx("flex-1 mr_5", styles.title)}>{t("auction.listing.title")}</div>
                    <Link to={URLS.auth.signIn()}>
                        <div className={clsx("flex-1 mr_5 flex_row_center", styles.subtitle)}>
                            {t("auction.listing.subtitle")}
                        </div>
                    </Link>
                </div>
                <div>
                    <UiSelect value={sort} onChange={(event) => setSort(event.target.value as AUCTION_LISTING_SORT)}>
                        {Object.values(AUCTION_LISTING_SORT).map((auctionListingSort) => (
                            <MenuItem key={auctionListingSort} value={auctionListingSort}>
                                {t(`auction.listing.sort.${auctionListingSort}`)}
                            </MenuItem>
                        ))}
                    </UiSelect>
                </div>
            </h1>
            <NoSsr>
                <LoadableFromLoading
                    loading={getAuctionsListingStore(sort).getLoadingState(1)}
                    renderer={(status, error) => {
                        return (
                            <div>
                                <ErrorBlock error={error} onRetry={() => getAuctionsListingStore(sort).load(1)} />
                                {status === "SUCCEEDED" ? (
                                    <AuctionsListObserver
                                        sideList
                                        textKey={"auction.listing.empty"}
                                        listStore={getAuctionsListingStore(sort)}
                                    />
                                ) : null}
                            </div>
                        );
                    }}
                />
            </NoSsr>
        </Container>
    );
}
