import React from "react";
import { AUCTION_STATUS } from "auctions/_models/AuctionMdl";
import { useAuctionStore } from "auctions/AuctionContext";
import { Redirect } from "react-router";
import { URLS } from "_configs/URLS";
import { AuctionForm } from "auctions/form/AuctionForm";
import { Tab, Tabs } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { AuctionGalleryEditor } from "auctions/gallery/editor/AuctionGalleryEditor";
import clsx from "clsx";
import styles from "./_css/auctionEdit.module.css";
import { toast } from "react-toastify";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import galleryGuide from "shared/assets/fcb_gallery_guide.pdf";
import { UiPaper } from "_common/ui/mui/surfaces/UiPaper";

export function AuctionEdit() {
    const [selectedTab, setSelectedTab] = React.useState("FORM");
    const auctionStore = useAuctionStore();
    const { t } = useTranslation();

    const alias = auctionStore.auction.alias;
    const isApproved = auctionStore.auction.status === AUCTION_STATUS.APPROVED;
    const isDraft = auctionStore.auction.status === AUCTION_STATUS.DRAFT;
    if (!isApproved && !isDraft) return <Redirect to={alias ? URLS.auction.see(alias) : URLS.home()} />;

    return (
        <div className="overflow_hidden">
            <h2>{auctionStore.auction.name}</h2>
            <div>
                <Tabs
                    className="mb_10 mt_10"
                    value={selectedTab}
                    onChange={(_e, newValue) => setSelectedTab(newValue)}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label={t("auction.edit.form")} value="FORM" />
                    <Tab label={t("auction.edit.gallery")} value="GALLERY" />
                </Tabs>
            </div>
            <div
                className={clsx(styles.tabContent, {
                    [styles.firstTab]: selectedTab === "FORM",
                    [styles.secondTab]: selectedTab === "GALLERY",
                })}
            >
                <div className={styles.tab}>
                    <AuctionForm onSuccess={() => toast.success(t("auction.successfullyUpdated"))} />
                </div>
                <div className={styles.tab}>
                    <UiPaper className="mt_20 p_25" color={"white"} elevation={0}>
                        <div>{t("auction.postPhotos")}</div>
                        <div className={"mb_10"}>
                            <a href={galleryGuide} target={"_blank"} rel="noopener noreferrer">
                                {t("auction.form.galleryGuide")}
                            </a>
                        </div>
                        <AuctionGalleryEditor />
                    </UiPaper>
                </div>
            </div>
        </div>
    );
}
