import React from "react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { getUserAuctionsStore } from "auctions/user/_stores/userAuctionsStore";
import { UserAuctionsList } from "auctions/user/UserAuctionsList";

export const UserAuctions = () => {
    return (
        <LoadableFromLoading
            loading={getUserAuctionsStore().getLoadingState(1)}
            renderer={(status, error) => (
                <div>
                    <ErrorBlock error={error} onRetry={() => getUserAuctionsStore().load(1)} />
                    {status === "SUCCEEDED" ? <UserAuctionsList /> : null}
                </div>
            )}
        />
    );
};
