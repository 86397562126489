import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { ISettingsMdl } from "settings/_models/SettingsMdl";
import { menuConfig } from "_configs/menuConfig";

class SettingsStore extends BaseResourceStore<ISettingsMdl> {
    constructor() {
        super("settings");
    }

    getOneMenu() {
        if (menuConfig) {
            return { menu: menuConfig };
        } else return this.getOne();
    }
}

const settingsStore = new SettingsStore();
export { settingsStore };
