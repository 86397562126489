import React, { Fragment } from "react";
import { Grid } from "@material-ui/core";
import { AuctionCard } from "auctions/card/AuctionCard";
import { IAuctionListingMdl } from "auctions/_models/AuctionMdl";
import { AuctionSideListing } from "auctions/listing/sidebar/AuctionSideListing";
import styles from "./_css/auctionsList.module.css";
import AuctionsBuyNowListingPage from "auctions/listing/AuctionsBuyNowListingPage";

type Props = {
    auctions: (IAuctionListingMdl | undefined)[];
    isClosed?: boolean;
    sideList?: boolean;
};

export function AuctionsList(props: Props) {
    const El = props.sideList ? "div" : Fragment;
    return (
        <>
            <Grid container spacing={props.isClosed ? 1 : 4} style={{ height: "100%" }}>
                <Grid
                    item
                    container
                    md={props.isClosed ? 12 : props.sideList ? 9 : 12}
                    sm={props.sideList ? 9 : 12}
                    xs={12}
                    style={{ height: "fit-content" }}
                >
                    <El {...(props.sideList && { className: styles.container })}>
                        {props.auctions.map((auction) => {
                            if (!auction) return null;
                            return (
                                <Grid key={auction._id} item md={props.isClosed ? 4 : 12} xs={12}>
                                    <AuctionCard auction={auction} isClosed={props.isClosed} />
                                </Grid>
                            );
                        })}
                    </El>
                </Grid>
                {props.sideList && (
                    <Grid item xs={12} sm={3}>
                        <div className={styles.hiddenMobile}>
                            <AuctionSideListing />
                        </div>
                        <div className={styles.hiddenDesktop}>
                            <AuctionsBuyNowListingPage />
                        </div>
                    </Grid>
                )}
            </Grid>
        </>
    );
}
