import React, { PropsWithChildren } from "react";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { observer } from "mobx-react";
import styles from "./_css/loaderWrapper.module.css";
import { Spinner } from "_common/loaders/Spinner";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import clsx from "clsx";

type Props = {
    loadingState?: LoadingStateMdl<any>;
};

export const LoaderWrapper = observer((props: PropsWithChildren<Props>) => {
    return (
        <div className={styles.container}>
            {props.children}
            {props.loadingState?.isLoading || props.loadingState?.error ? (
                <div className={clsx(styles.overlay, "flex_center_center")}>
                    <ErrorBlock error={props.loadingState.error} />
                    {props.loadingState.isLoading && <Spinner />}
                </div>
            ) : null}
        </div>
    );
});
