import React from "react";
import { useAuctionStore } from "auctions/AuctionContext";
import { useTranslation } from "react-i18next";
import { Meta } from "_common/_utils/Meta";
import _ from "lodash";
import { URLS } from "_configs/URLS";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { i18nextInstance } from "_common/i18n/IntlProvider";

export function AuctionMeta() {
    const currentLang = i18nextInstance.language as TLang;
    const auctionStore = useAuctionStore();
    const { t } = useTranslation();
    const title = `${auctionStore.auction.name} ${t("name")}`;
    const description = t("auction.view.meta", auctionStore.auction);
    const image = _.first(auctionStore.auction.photos)?.url;
    const url = sharedConfig.appUrl + URLS.auction.see(auctionStore.auction.alias ?? "");
    const alternateUrls = Object.values(sharedConfig.languages).reduce((acc, { lang, baseUrl }) => {
        if (lang !== currentLang) {
            acc.push({ lang: lang as TLang, url: baseUrl + URLS.auction.see(auctionStore.auction.alias ?? "", lang) });
        }
        return acc;
    }, [] as { lang: TLang; url: string }[]);
    return <Meta title={title} description={description} image={image} url={url} alternateUrls={alternateUrls} />;
}
