import { userStore } from "users/_stores/userStore";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { when } from "mobx";
import { TRootMenu } from "settings/_models/MenuMdl";

export function useMenuUserItems() {
    const [isLogged, setIsLogged] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        return when(
            () => isLogged !== userStore.isLogged,
            () => setIsLogged(userStore.isLogged),
        );
    }, []);

    return [
        {
            title: isLogged ? userStore.user?.nickname ?? t("header.profile") : t("header.signIn"),
            url: isLogged ? URLS.user.profile() : URLS.auth.signIn(),
            image: isLogged ? userStore.user?.photo : undefined,
            subGroups: isLogged
                ? [
                      {
                          items: [
                              {
                                  url: URLS.user.profile(),
                                  title: t("header.profile"),
                              },
                              {
                                  url: URLS.user.watchlist(),
                                  title: t("profile.watchlist.title"),
                              },
                          ],
                      },
                  ]
                : undefined,
        },
    ] as TRootMenu[];
}
