import React from "react";
import { useTranslation, UseTranslationResponse } from "react-i18next";
import { URLS } from "_configs/URLS";
import { TRootMenu } from "settings/_models/MenuMdl";
import { RootMenu } from "main/menu/RootMenu";

export function MENU_BUSINESS_ITEMS(translation: UseTranslationResponse): TRootMenu[] {
    return [
        {
            title: translation.t("header.currentAuctions"),
            url: URLS.auction.current(),
        },
        {
            title: translation.t("header.endedAuctions"),
            url: URLS.auction.ended(),
        },
        {
            title: translation.t("header.buyNowOrMakeAnOffer"),
            url: URLS.auction.buyNowOrMakeAnOffer(),
        },
    ];
}

export function MenuBusiness() {
    const translation = useTranslation();
    /*    const history = useHistory();
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(false);*/

    return (
        /*<div className={styles.menuAuction} onMouseLeave={() => setOpen(false)}>
            <div className={clsx("flex_row_center")} onMouseOver={() => setOpen(true)}>
                <RootMenu withLang={true} menu={{ title: t("header.auctions") }} />
                <ArrowDropDown className={styles.arrowBottomIcon} />
            </div>
            <div>
                <div style={{ visibility: isOpen ? "visible" : "hidden" }} className={clsx(styles.subMenuAuction)}>
                    {MENU_BUSINESS_ITEMS(translation).map((item, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                setOpen(false);
                                history.push(item.url as Path);
                            }}
                        >
                            <div className="text_ellipsis">{item.title}</div>
                        </MenuItem>
                    ))}
                </div>
            </div>
        </div>*/
        <>
            {MENU_BUSINESS_ITEMS(translation).map((item, index) => (
                <RootMenu key={index} withLang={true} menu={item} />
            ))}
        </>
    );
}
