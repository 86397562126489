import React, { CSSProperties, useRef } from "react";
import { AuctionGalleryPhotoEditor } from "auctions/gallery/editor/AuctionGalleryPhotoEditor";
import { useAuctionStore } from "auctions/AuctionContext";
import { DragDropContext, Draggable, DraggingStyle, Droppable, NotDraggingStyle } from "react-beautiful-dnd";
import { useWindowSize } from "_common/ui/utils/slider";
import _ from "lodash";
import { observer } from "mobx-react";
import { GALLERY_CATEGORIES } from "auctions/_models/AuctionMdl";

const PHOTO_SIZE = 240;

const LIST_STYLE = {
    display: "flex",
    overflow: "hidden",
};

const getItemStyle = (_isDragging: boolean, draggableStyle?: DraggingStyle | NotDraggingStyle): CSSProperties => ({
    userSelect: "none",
    ...draggableStyle,
    padding: 0,
});

type Props = {
    galleryCategory: GALLERY_CATEGORIES;
};

export const AuctionGalleryPhotosEditor = observer(({ galleryCategory }: Props) => {
    const draggingRef = useRef(false);
    const { width } = useWindowSize();
    const auctionStore = useAuctionStore();
    const nbPerRow = Math.floor(width / PHOTO_SIZE) || 1;
    const photosRows = _.chunk(auctionStore.auction[galleryCategory], nbPerRow);
    const isDropEnabled = !auctionStore.currentPhotosLoadingState;

    return (
        <div
            onClick={(event) => {
                if (draggingRef.current) event.stopPropagation();
            }}
        >
            <DragDropContext
                onDragStart={() => (draggingRef.current = true)}
                onDragEnd={({ source, destination }) => {
                    draggingRef.current = false;
                    if (!destination) return;
                    const sourceRowIndex = +source.droppableId;
                    const initialIndex = sourceRowIndex * nbPerRow + source.index;
                    const dropRowIndex = +destination.droppableId;
                    const droppedIndex = dropRowIndex * nbPerRow + destination.index;
                    auctionStore.movePhoto(initialIndex, droppedIndex, galleryCategory);
                }}
            >
                {photosRows.map((photos, rowIndex) => (
                    <Droppable
                        key={rowIndex}
                        droppableId={"" + rowIndex}
                        direction="horizontal"
                        isDropDisabled={!isDropEnabled}
                    >
                        {(provided) => (
                            <div ref={(ref) => provided.innerRef(ref)} style={LIST_STYLE} {...provided.droppableProps}>
                                {photos.map((photo, index) => (
                                    <Draggable
                                        key={index}
                                        draggableId={"" + (rowIndex * nbPerRow + index)}
                                        index={index}
                                        isDragDisabled={!isDropEnabled}
                                    >
                                        {(provided, snapshot) => {
                                            return (
                                                <div
                                                    ref={(ref) => provided.innerRef(ref)}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style,
                                                    )}
                                                >
                                                    <AuctionGalleryPhotoEditor
                                                        key={index}
                                                        photo={photo}
                                                        galleryCategory={galleryCategory}
                                                    />
                                                </div>
                                            );
                                        }}
                                    </Draggable>
                                ))}
                            </div>
                        )}
                    </Droppable>
                ))}
            </DragDropContext>
        </div>
    );
});
