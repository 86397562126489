import { Button, ButtonProps } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import styles from "./_css/uiButton.module.css";

type Props = ButtonProps & {
    isDeleteButton?: boolean;
    success?: boolean;
    light?: boolean;
};

export const UiButton = ({ isDeleteButton, ...props }: Props) => {
    return (
        <Button
            {...props}
            className={clsx(
                styles.container,
                {
                    [styles.containerForDelete]: isDeleteButton,
                    [styles.containedSuccess]: props.success,
                    [styles.light]: props.light,
                },
                props.className,
            )}
            classes={{
                root: styles.root,
                label: styles.label,
                sizeSmall: styles.sizeSmall,
                contained: styles.contained,
                containedPrimary: styles.containedPrimary,
            }}
        />
    );
};
