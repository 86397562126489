import React from "react";
import { UiCountdown } from "_common/ui/utils/UiCountdown";
import { AUCTION_STATUS, IBaseAuctionMdl } from "auctions/_models/AuctionMdl";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { getHighestBidOrOffer } from "auctions/_utils/auctionUtils";
import { formatPrice } from "_common/_utils/currencyUtils";

type Props = {
    auction: IBaseAuctionMdl;
};

export const AuctionCountdown = observer((props: Props) => {
    const { t } = useTranslation();
    let completeLabel = t("auction.ended");
    if (props.auction.soldManually) {
        if (!props.auction.soldManuallyPrice) return null;
        completeLabel = t("auction.sold", { price: formatPrice(props.auction.soldManuallyPrice) });
        return <UiCountdown theme={"dark"} date={props.auction.endDate} completeLabel={completeLabel} />;
    }
    if (props.auction.status === AUCTION_STATUS.CLOSED || props.auction.status === AUCTION_STATUS.PAID) {
        const highestBidOrOffer = getHighestBidOrOffer([
            ...(props.auction.bids ?? []),
            ...(props.auction.offers ?? []),
        ]);
        if (props.auction.sold && highestBidOrOffer) {
            completeLabel = t("auction.sold", { price: formatPrice(highestBidOrOffer.price) });
        } else {
            completeLabel = t("auction.didNotSell");
        }
    }
    return <UiCountdown theme={"dark"} date={props.auction.endDate} completeLabel={completeLabel} />;
});
