import React from "react";
import { useLocation } from "react-router";
import { pagesStore } from "pages/_stores/pagesStore";
import { IPageMdl } from "pages/_models/PageMdl";
import { PageRenderer } from "pages/PageRenderer";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { PageNotFound } from "pages/PageNotFound";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { TLang } from "_configs/sharedConfig";

export function PageRouter() {
    const location = useLocation();
    const page = pagesStore.getByUrl(location.pathname, i18nextInstance.language as TLang);
    const { loading } = useLoadingFromPromise(page.then ? (page as Promise<IPageMdl>) : undefined);
    if (page.then) {
        if (!loading) return null;
        return (
            <LoadableFromLoading
                loading={loading}
                renderer={(_status, error, fetchedPage) => {
                    if (error) {
                        return <ErrorBlock error={error} />;
                    }
                    if (!fetchedPage) {
                        return <PageNotFound />;
                    }
                    return <PageRenderer page={fetchedPage} />;
                }}
            />
        );
    }

    return <PageRenderer page={page as IPageMdl} />;
}
