import React from "react";
import { Pagination } from "@material-ui/lab";

type Props = {
    count?: number;
    selectedPage: number;
    pageSize: number;
    onPageClick: (page: number) => void;
};

export const Paginator = (props: Props) => {
    if (!props.count) return null;
    const nbPages = Math.ceil(props.count / props.pageSize);

    return (
        <Pagination
            count={nbPages}
            page={props.selectedPage}
            onChange={(event, page) => {
                event.preventDefault();
                props.onPageClick(page);
            }}
        />
    );
};