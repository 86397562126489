import React from "react";
import { Grid } from "@material-ui/core";
import { useAuctionStore } from "auctions/AuctionContext";
import { useTranslation } from "react-i18next";
import styles from "./_css/auctionMainInfo.module.css";
import clsx from "clsx";
import AuctionMainBottomInfos from "auctions/view/AuctionMainBottomInfos";

export function AuctionMainInfo() {
    const auctionStore = useAuctionStore();
    const { t } = useTranslation();
    if (!auctionStore.auction) return null;
    return (
        <div className={clsx(styles.container, "p_20")}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h3>{t("auction.view.mainInformation")}</h3>
                </Grid>
                <Grid item xs={6}>
                    <h4>{t("auction.brand")}</h4>
                    {auctionStore.auction.brand}
                </Grid>
                <Grid item xs={6}>
                    <h4>{t("auction.type")}</h4>
                    {auctionStore.auction.type}
                </Grid>
                <Grid item xs={6}>
                    <h4>{t("auction.year")}</h4>
                    {auctionStore.auction.year}
                </Grid>
                <Grid item xs={6}>
                    <h4>{t("auction.mileage")}</h4>
                    {auctionStore.auction?.mileage?.toLocaleString()} km
                </Grid>
                {!!auctionStore.auction.location && (
                    <>
                        <Grid item xs={6}>
                            <h4>
                                {t(
                                    auctionStore.auction.location.country === "usa"
                                        ? "auction.location.state"
                                        : "auction.location.province",
                                )}
                            </h4>
                            {t("countries." + (auctionStore.auction.location.country ?? "canada"))},{" "}
                            {t(
                                `${auctionStore.auction.location.country === "usa" ? "states" : "provinces"}.${
                                    auctionStore.auction.location.province
                                }`,
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <h4>{t("auction.location.city")}</h4>
                            {auctionStore.auction.location.city}
                        </Grid>
                    </>
                )}
                <Grid item xs={6} className={styles.bottomBottom}>
                    <h4>{t("auction.matriculation")}</h4>
                    {auctionStore.auction.matriculation?.toUpperCase()}
                </Grid>
                {auctionStore.auction.carfax?.url && (
                    <Grid item xs={6} className={styles.bottomBottom}>
                        <h4>{t("auction.carfax")}</h4>
                        <a href={auctionStore.auction.carfax.url} target="_blank" rel="noopener noreferrer">
                            {t("auction.carfaxLink")}
                        </a>
                    </Grid>
                )}
            </Grid>
            <AuctionMainBottomInfos />
        </div>
    );
}
