import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";

export type TextComponentProps = {
    style: CSSProperties;
    value: string;
    $component: TComponent;
};

export function TextComponent({ value, $component, ...props }: TextComponentProps) {
    return (
        <div
            {...props}
            style={{ margin: 5, ...props.style }}
            className="text_normal"
            dangerouslySetInnerHTML={{ __html: value }}
        />
    );
}
