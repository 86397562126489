import React from "react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { AUCTION_TYPE } from "auctions/_models/AuctionMdl";

type Props = {
    value?: AUCTION_TYPE;
    onChange?: (value: AUCTION_TYPE) => void;
    disabled?: boolean;
};

export function DealerOrPrivateInput(props: Props) {
    const { t } = useTranslation();
    return (
        <div className="flex_row_center">
            <UiButton
                className="mr_10"
                variant={"outlined"}
                color={props.value === AUCTION_TYPE.DEALER ? "primary" : "inherit"}
                onClick={() => props.onChange?.(AUCTION_TYPE.DEALER)}
                disabled={props.disabled}
            >
                {t("auction.auctionType.dealer")}
            </UiButton>
            <div className="mr_10">{t("words.or")}</div>
            <UiButton
                variant={"outlined"}
                color={props.value === AUCTION_TYPE.PRIVATE ? "primary" : "inherit"}
                onClick={() => props.onChange?.(AUCTION_TYPE.PRIVATE)}
                disabled={props.disabled}
            >
                {t("auction.auctionType.private")}
            </UiButton>
            <div className="ml_10">{t("auction.auctionType.seller")}</div>
        </div>
    );
}
