import React from "react";
import { observer } from "mobx-react";
import { NavLink } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { Logo } from "_common/icons/Logo";
import styles from "./_css/header.module.css";

export const HeaderLogo = observer(() => {
    return (
        <NavLink to={URLS.home()}>
            <Logo className={styles.headerLogo} width={100} />
        </NavLink>
    );
});
