import React, { useState } from "react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { useAuctionStore } from "auctions/AuctionContext";
import { Checkbox, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { PaymentMethods } from "payments/paymentMethods/PaymentMethods";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { IPaymentMethodMdl } from "payments/paymentMethods/_models/PaymentMethodMdl";
import { formatPrice } from "_common/_utils/currencyUtils";
import clsx from "clsx";
import styles from "./_css/offer.module.css";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Input } from "_common/ui/forms/Input";
import { useForm } from "react-hook-form";
import { overlayStore } from "main/overlay/_stores/overlayStore";
import { auctionsStore } from "auctions/_stores/auctionsStore";
import { toast } from "react-toastify";
import { getErrorMessage } from "_common/errors/errorUtils";

const OfferDialog = observer(() => {
    const auctionStore = useAuctionStore();
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [paymentMethod, setPaymentMethod] = useState<IPaymentMethodMdl>();
    const [paymentMethodError, setPaymentMethodError] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [termsError, setTermsError] = useState(false);
    if (!auctionStore.auction.minPrice) return null;

    const { register, handleSubmit, errors, watch } = useForm({
        mode: "onBlur",
    });

    return (
        <Dialog
            fullWidth
            maxWidth={"sm"}
            closeCross
            open={auctionStore.makeAnOfferDialogOpened}
            onClose={() => auctionStore.closeMakeAnOfferDialog()}
        >
            <DialogTitle>{t("makeAnOffer.title")}</DialogTitle>
            <DialogContent className={"mt_20"}>
                <div className={"mb_40"}>
                    <PaymentMethods
                        onSelect={(paymentMethod) => {
                            setPaymentMethod(paymentMethod);
                            setPaymentMethodError(false);
                        }}
                        selectedPaymentMethodId={paymentMethod?.id}
                    />
                    {paymentMethodError && <ErrorBlock error={t("bids.paymentMethodError")} />}
                </div>
                <div>
                    <InputBlock label={t("makeAnOffer.amount")}>
                        <Input
                            type={"number"}
                            name={"price"}
                            ref={register({
                                required: t<string>("errors.forms.required"),
                            })}
                            error={errors.price}
                        />
                    </InputBlock>
                    <p>
                        {t("makeAnOffer.buyNowPrice")} : <b>{formatPrice(auctionStore.auction.minPrice)}</b>
                    </p>
                    <p className={"text_discreet mt_10"}>{t("makeAnOffer.inputInfo")}</p>
                    <div className={styles.termsCheckbox}>
                        <Checkbox
                            id={"terms"}
                            value={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                        />
                        <label htmlFor={"terms"}>{t("makeAnOffer.termsAgree")}</label>
                    </div>
                    {termsError && <ErrorBlock error={t("makeAnOffer.termsError")} />}
                    <div className={clsx(styles.terms, "mt_20 mb_20")}>
                        <p className={"text_discreet"}>{t("makeAnOffer.termsOfUse")}</p>
                        <p className={"mt_10"}>{t("makeAnOffer.terms")}</p>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <UiButton
                    disabled={!termsAccepted || !watch("price") || watch("price") === 0}
                    color={"primary"}
                    variant={"contained"}
                    onClick={handleSubmit(({ price }) => {
                        if (!paymentMethod) {
                            setPaymentMethodError(true);
                            return;
                        }
                        if (!termsAccepted) {
                            setTermsError(true);
                            return;
                        }
                        if (auctionStore.auction._id && !loading) {
                            setLoading(true);
                            overlayStore.open({ children: t("makeAnOffer.loading"), withSpinner: true });
                            auctionsStore
                                .offer(auctionStore.auction._id, paymentMethod.id, parseFloat(price) * 100)
                                .then(() => {
                                    auctionStore.closeMakeAnOfferDialog();
                                    toast.success(t("makeAnOffer.success"));
                                })
                                .catch((err) => toast.error(getErrorMessage(err)))
                                .finally(() => {
                                    setLoading(false);
                                    overlayStore.close();
                                });
                        }
                    })}
                >
                    {t("makeAnOffer.action")}
                </UiButton>
            </DialogActions>
        </Dialog>
    );
});

export default OfferDialog;
