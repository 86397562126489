import io from "socket.io-client";
import sharedConfig from "_configs/sharedConfig";
import eventsStore from "main/events/eventsStore";
import { reformatComment } from "comments/_utils/commentsUtils";
import { reformatAuction, reformatBid, reformatBids } from "auctions/_utils/auctionUtils";
import { reaction } from "mobx";
import { tokenStore } from "users/_stores/tokenStore";
import dayjs from "dayjs";

export class WebsocketStore {
    private readonly socket: SocketIOClient.Socket;
    private auctionIdWatched: string | undefined;

    constructor() {
        this.socket = io(sharedConfig.appUrl, {
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            randomizationFactor: 0.5,
            transports: ["websocket"],
        });

        this.socket.on("connect", () => {
            reaction(
                () => tokenStore.token,
                (token) => {
                    if (token) this.socket.emit("users/signIn", { token });
                    else this.socket.emit("users/signOut", { token });
                },
            );
            if (tokenStore.token) this.socket.emit("users/signIn", { token: tokenStore.token });
            if (this.auctionIdWatched) this.sendAuctionWatch(this.auctionIdWatched);
        });

        this.socket.on("auctions/bid/succeeded", (event: any) => {
            eventsStore.send({
                ...event,
                payload: {
                    ...event.payload,
                    endDate: dayjs(event.payload.endDate),
                    bid: reformatBid(event.payload.bid),
                },
            });
        });

        this.socket.on("auctions/offer/succeeded", (event: any) => {
            eventsStore.send({
                ...event,
                payload: {
                    ...event.payload,
                    offer: reformatBid(event.payload.offer),
                },
            });
        });

        this.socket.on("auctions/created", (event: any) => {
            eventsStore.send({ ...event, payload: { ...event.payload, item: reformatAuction(event.payload.item) } });
        });

        this.socket.on("auctions/closed", (event: any) => {
            eventsStore.send({ ...event, payload: { ...event.payload, bids: reformatBids(event.payload.bids) } });
        });

        this.socket.on("auctions/buyNow/succeeded", (event: any) => {
            eventsStore.send({ ...event, payload: { ...event.payload, offers: reformatBids(event.payload.offers) } });
        });

        this.socket.on("comments/created", (event: any) => {
            eventsStore.send({ ...event, payload: { ...event.payload, item: reformatComment(event.payload.item) } });
        });

        this.socket.on("comments/updated", (event: any) => {
            eventsStore.send({ ...event, payload: { ...event.payload, item: reformatComment(event.payload.item) } });
        });

        this.socket.on("comments/deleted", (event: any) => {
            eventsStore.send({ ...event, payload: { ...event.payload, item: reformatComment(event.payload.item) } });
        });

        eventsStore.on("auctions/watch", (event) => {
            this.auctionIdWatched = event.payload.auctionId;
            if (this.socket.connected && this.auctionIdWatched) this.sendAuctionWatch(this.auctionIdWatched);
        });

        eventsStore.on("auctions/unwatch", (event) => {
            if (this.auctionIdWatched === event.payload.auctionId) {
                this.auctionIdWatched = undefined;
                if (this.socket.connected) this.sendAuctionUnwatch(event.payload.auctionId);
            }
        });
    }

    private sendAuctionWatch(auctionId: string) {
        this.socket.emit("auctions/watch", { auctionId });
    }

    private sendAuctionUnwatch(auctionId: string) {
        this.socket.emit("auctions/unwatch", { auctionId });
    }
}
