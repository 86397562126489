import React from "react";
import styles from "auctions/card/_css/auctionCard.module.css";
import { IconButton } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import copy from "copy-to-clipboard";

type Props = {
    text: string;
    onCopied?: () => void;
};

export function CopyLinkBtn(props: Props) {
    return (
        <IconButton
            onClick={() => {
                copy(props.text);
                props.onCopied?.();
            }}
            className={styles.copyLinkBtn}
        >
            <FileCopy />
        </IconButton>
    );
}
