import React from "react";
import styles from "./_css/spinner.module.css";

export function Spinner() {
    return (
        <div className={styles.spinner}>
            <div className={styles.doubleBounce1} />
            <div className={styles.doubleBounce2} />
        </div>
    );
}
