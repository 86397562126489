import { BASE_COMPONENTS_CONFIGS } from "components/base/baseComponents";
import { PAGES_COMPONENTS_CONFIGS } from "components/pages/pagesComponent";
import { LAYOUT_COMPONENTS_CONFIGS } from "components/layout/layoutComponents";
import { SECTIONS_COMPONENTS_CONFIGS } from "components/sections/sectionsComponent";
import { MISC_COMPONENTS_CONFIGS } from "components/misc/miscComponent";
import { TComponentsConfigs } from "components/ComponentRenderer";
import { NEWS_COMPONENTS_CONFIGS } from "components/news/newsComponent";

export const COMPONENTS_CONFIGS: TComponentsConfigs = {
    ...BASE_COMPONENTS_CONFIGS,
    ...PAGES_COMPONENTS_CONFIGS,
    ...LAYOUT_COMPONENTS_CONFIGS,
    ...SECTIONS_COMPONENTS_CONFIGS,
    ...MISC_COMPONENTS_CONFIGS,
    ...NEWS_COMPONENTS_CONFIGS,
};
