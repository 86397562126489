import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAuctionStore } from "auctions/AuctionContext";
import { DealerOrPrivateInput } from "auctions/form/adInformation/DealerOrPrivateInput";
import { ReserveInput } from "auctions/form/adInformation/ReserveInput";
import { LocationInput } from "auctions/form/adInformation/LocationInput";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Input } from "_common/ui/forms/Input";
import styles from "./_css/adForm.module.css";
import clsx from "clsx";

type Props = {
    admin?: boolean;
};

export function AdForm(props: Props) {
    const { control, errors } = useFormContext();
    const { t } = useTranslation();
    const auctionStore = useAuctionStore();
    const disabled = !props.admin && !auctionStore.inEdition;

    return (
        <div>
            <h3 className="mb_20">{t("auction.adInformation")}</h3>
            <Grid spacing={3} container>
                {props.admin && (
                    <Grid item xs={12}>
                        <InputBlock label="Name" className="mb_20">
                            <Controller
                                as={Input}
                                name={`name`}
                                rules={{
                                    required: t<string>("errors.forms.required"),
                                }}
                                control={control}
                            />
                        </InputBlock>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name={"auctionType"}
                        disabled={disabled}
                        as={DealerOrPrivateInput}
                        rules={{
                            required: t<string>("errors.forms.required"),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name={"minPrice"}
                        disabled={disabled}
                        as={ReserveInput}
                        rules={{
                            required: t<string>("errors.forms.required"),
                        }}
                        error={errors.minPrice}
                    />
                </Grid>
                <Grid item xs={12} className={clsx("flex_row_center", styles.steps)}>
                    <div className="mr_20">{t("auction.form.step1")}</div>
                    <div>{t("auction.form.step2")}</div>
                </Grid>
                <Grid item xs={12}>
                    <LocationInput admin={!!props.admin} />
                </Grid>
            </Grid>
        </div>
    );
}
