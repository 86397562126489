import React from "react";
import { IAuctionEditionMdl, IBaseAuctionMdl } from "auctions/_models/AuctionMdl";
import { CopyLinkBtn } from "_common/ui/share/CopyLinkBtn";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import sharedConfig from "_configs/sharedConfig";
import { URLS } from "_configs/URLS";

type Props = {
    auction: IBaseAuctionMdl | IAuctionEditionMdl;
};

export function AuctionCopyLinkBtn(props: Props) {
    const { t } = useTranslation();
    if (!props.auction.alias) return null;
    return (
        <CopyLinkBtn
            text={sharedConfig.appUrl + URLS.auction.see(props.auction.alias)}
            onCopied={() => toast.success(t("auction.linkCopied"))}
        />
    );
}
