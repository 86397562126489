import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import { useAuctionCommentsStore } from "comments/AuctionCommentsContext";
import { useAuctionStore } from "auctions/AuctionContext";
import { ICommentBaseMdl, ICommentMdl } from "comments/_models/CommentMdl";
import { IBidMdl } from "auctions/_models/BidMdl";
import { AuctionCommentOrBid } from "comments/AuctionCommentOrBid";
import { isBid, isCommentBase } from "auctions/_utils/auctionUtils";
import { LoadMoreBtn } from "_common/ui/utils/LoadMoreBtn";
import dayjs, { Dayjs } from "dayjs";
import AuctionCommentWithoutTitle from "comments/AuctionCommentWithoutTitle";

type Props = {
    actionsRenderer?: (comment: ICommentMdl) => ReactNode;
    hideBids?: boolean;
};

function mixCommentsAndBids(
    comments: (ICommentMdl | undefined)[],
    auctionEndDate: Dayjs,
    bids?: IBidMdl[],
): (ICommentMdl | IBidMdl | ICommentBaseMdl)[] {
    const isEndDateComment = dayjs().isAfter(auctionEndDate);
    const result: (ICommentMdl | IBidMdl | ICommentBaseMdl)[] = [
        ...(bids ?? []),
        ...(comments.filter((comment) => !!comment) as ICommentMdl[]),
    ];

    if (isEndDateComment) result.push({ textKey: "comments.ended", createdAt: auctionEndDate });
    return result.sort(
        (i1, i2) => (isBid(i2) ? i2.date : i2.createdAt).valueOf() - (isBid(i1) ? i1.date : i1.createdAt).valueOf(),
    );
}

export const AuctionCommentsAndBidsList = observer((props: Props) => {
    const auctionStore = useAuctionStore();
    const auctionCommentsStore = useAuctionCommentsStore();
    const commentsAndBids = mixCommentsAndBids(auctionCommentsStore.listStore.items, auctionStore.auction.endDate, [
        ...(auctionStore.auction.bids ?? []),
        ...(auctionStore.auction.offers ?? []),
    ]);
    return (
        <div>
            {commentsAndBids.map((commentOrBid, index) => {
                if (isBid(commentOrBid) && props.hideBids) return null;
                const key =
                    isBid(commentOrBid) || isCommentBase(commentOrBid) ? index : (commentOrBid as ICommentMdl)._id;
                if (isCommentBase(commentOrBid)) return <AuctionCommentWithoutTitle key={key} comment={commentOrBid} />;
                return (
                    <div key={key} className="mb_20">
                        <AuctionCommentOrBid
                            sellerId={auctionStore.auction.user._id}
                            commentOrBid={commentOrBid}
                            actionsRenderer={props.actionsRenderer}
                        />
                    </div>
                );
            })}
            <LoadMoreBtn listStore={auctionCommentsStore.listStore} />
        </div>
    );
});
