import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import { FullSizePagesSlider } from "components/pages/slider/FullSizePagesSlider";

type Props = {
    style: CSSProperties;
    $component: TComponent;
};

export const FullSizePagesSliderComponent = (props: Props) => {
    return (
        <div style={props.style}>
            <FullSizePagesSlider />
        </div>
    );
};
