import React from "react";
import { MenuItem } from "@material-ui/core";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import sharedConfig from "_configs/sharedConfig";
import { useTranslation } from "react-i18next";

type Props = {
    value: string;
    onChange: (value: string) => void;
    disabled: boolean;
};

export function ProvinceInput(props: Props) {
    const { t } = useTranslation();
    return (
        <UiSelect
            disabled={props.disabled}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value as string)}
        >
            {sharedConfig.provinces.map((province) => (
                <MenuItem key={province} value={province}>
                    {t(`provinces.${province}`)}
                </MenuItem>
            ))}
        </UiSelect>
    );
}
