import React, { useContext } from "react";
import { AuctionCommentsStore } from "./_stores/AuctionCommentsStore";

const AuctionCommentsCtxt = React.createContext<AuctionCommentsStore>({} as AuctionCommentsStore);

type Props = {
    auctionCommentsStore: AuctionCommentsStore;
};

export function useAuctionCommentsStore() {
    return useContext(AuctionCommentsCtxt);
}

export function AuctionCommentsContext(props: React.PropsWithChildren<Props>) {
    return (
        <AuctionCommentsCtxt.Provider value={props.auctionCommentsStore}>{props.children}</AuctionCommentsCtxt.Provider>
    );
}
