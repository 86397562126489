import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import { ChildrenComponentRenderer } from "components/ChildrenComponentRenderer";

type Props = {
    style?: CSSProperties;
    children: TComponent[];
    $component: TComponent;
};

export function DivComponent({ children, $component, ...props }: Props) {
    return (
        <div {...props}>
            <ChildrenComponentRenderer propName="children" parent={$component} children={children} />
        </div>
    );
}
