import React from "react";
import { useTranslation } from "react-i18next";
import { AuctionOptionForm } from "auctions/form/options/AuctionOptionForm";
import { Grid } from "@material-ui/core";
import { CAR_OPTIONS_CONFIG } from "_configs/carConfig";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Controller, useFormContext } from "react-hook-form";
import { TextArea } from "_common/ui/forms/TextArea";
import { useAuctionStore } from "auctions/AuctionContext";

type Props = {
    admin?: boolean;
};

export function AuctionOptionsForm(props: Props) {
    const { t } = useTranslation();
    const form = useFormContext();
    const auctionStore = useAuctionStore();
    const disabled = !props.admin && !auctionStore.inEdition;

    return (
        <div>
            <h3 className="mb_20">{t("auction.carOptions")}</h3>
            <Grid spacing={3} container>
                {CAR_OPTIONS_CONFIG.map((carOption) => {
                    return <AuctionOptionForm admin={props.admin} key={carOption.key} carOption={carOption} />;
                })}
            </Grid>
            <Grid spacing={3} container>
                <Grid item xs={12}>
                    <InputBlock label={t("auction.extraOptions")} extralabel={t("auction.extraOptionsInfo")}>
                        <Controller
                            rows={5}
                            name={"extraOptions"}
                            disabled={disabled}
                            placeholder={t("auction.extraOptionsPlaceHolder")}
                            control={form.control}
                            as={TextArea}
                            error={form.errors.extraOptions}
                        />
                    </InputBlock>
                </Grid>
            </Grid>
        </div>
    );
}
