import { useEffect, useState } from "react";
import _ from "lodash";

export function useWindowSize(throttle?: number) {
    const [windowSize, setWindowSize] = useState({ width: -1, height: -1 });
    if (__BROWSER__) {
        useEffect(() => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            const listener = _.throttle(
                () =>
                    setWindowSize({
                        width: window.innerWidth,
                        height: window.innerHeight,
                    }),
                throttle ?? 100,
            );
            window.addEventListener("resize", listener);
            return () => window.removeEventListener("resize", listener);
        }, []);
    }
    return windowSize;
}
