import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import { ChildrenComponentRenderer } from "components/ChildrenComponentRenderer";
import clsx from "clsx";
import styles from "./_css/sectionComponent.module.css";
import { Container } from "@material-ui/core";

type Props = {
    theme: "light" | "dark" | "secondary";
    style?: CSSProperties;
    children: TComponent[];
    $component: TComponent;
};

export function SectionComponent({ children, $component, ...props }: Props) {
    return (
        <div
            {...props}
            className={clsx(styles.container, {
                [styles.light]: props.theme === "light",
                [styles.dark]: props.theme === "dark",
                [styles.secondary]: props.theme === "secondary",
            })}
        >
            <Container>
                <ChildrenComponentRenderer propName="children" parent={$component}>
                    {children}
                </ChildrenComponentRenderer>
            </Container>
        </div>
    );
}
