import React from "react";
import { settingsStore } from "settings/_stores/settingsStore";
import { FooterMenu } from "main/footer/FooterMenu";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { TLang } from "_configs/sharedConfig";
import { MENU_BUSINESS_ITEMS } from "main/menu/MenuBusiness";
import { useTranslation } from "react-i18next";

type Props = {
    className?: string;
};

export function FooterNavigation(props: Props) {
    const menu = settingsStore.getOne()?.menu;
    const lang = i18nextInstance.language;
    const translation = useTranslation();
    const localizedMenu = (menu?.localized?.[lang as TLang]?.items ?? []).reverse();
    const businessMenu = MENU_BUSINESS_ITEMS(translation).reverse();
    const footerMenu = localizedMenu.concat(businessMenu).reverse();
    return (
        <>
            <FooterMenu
                className={props.className}
                title="Navigation"
                links={footerMenu
                    .filter((item) => !!item.url)
                    .map((item) => ({
                        title: item.title,
                        url: item.url ? (item.url.startsWith("/" + lang) ? item.url : "/" + lang + item.url) : "",
                    }))}
            />
        </>
    );
}
