import React, { useContext } from "react";
import { PageRendererStore } from "./_stores/PageRendererStore";

const PageRendererCtxt = React.createContext<PageRendererStore>({} as PageRendererStore);

type Props = {
    pageRendererStore: PageRendererStore;
};

export function usePageRendererStore() {
    return useContext(PageRendererCtxt);
}

export function PageRendererContext(props: React.PropsWithChildren<Props>) {
    return <PageRendererCtxt.Provider value={props.pageRendererStore}>{props.children}</PageRendererCtxt.Provider>;
}
