import React from "react";
import { useAuctionStore } from "auctions/AuctionContext";
import { observer } from "mobx-react";
import { GALLERY_CATEGORIES } from "auctions/_models/AuctionMdl";
import { FilePlaceholder } from "_common/ui/forms/images/FilePlaceholder";
import { AuctionGalleryPhotosEditor } from "auctions/gallery/editor/AuctionGalleryPhotosEditor";
import { AuctionGalleryPhotoEditor } from "auctions/gallery/editor/AuctionGalleryPhotoEditor";
import { AuctionGalleryEditorAddZone } from "auctions/gallery/editor/AuctionGalleryEditorAddZone";
import { useTranslation } from "react-i18next";
import AuctionGalleryPhotosOptions from "auctions/gallery/editor/AuctionGalleryPhotosOptions";

export const AuctionGalleryEditor = observer(() => {
    const auctionStore = useAuctionStore();
    const { t } = useTranslation();
    return (
        <>
            {Object.values(GALLERY_CATEGORIES).map((galleryCategory) => {
                return (
                    <div key={galleryCategory} className={"mb_25"}>
                        <div className={"flex_row justifyContent_spaceBetween"}>
                            <p className={"mb_10"}>{t(`auction.${galleryCategory}`)}</p>
                            <AuctionGalleryPhotosOptions galleryCategory={galleryCategory} />
                        </div>

                        <AuctionGalleryEditorAddZone galleryCategory={galleryCategory}>
                            <div className={"flex_row justifyContent_spaceBetween"}>
                                {auctionStore.uploadingState[galleryCategory].length === 0 ? (
                                    <FilePlaceholder onlyImage />
                                ) : (
                                    <div className="flex_row flexWrap_wrap">
                                        {auctionStore.uploadingState[galleryCategory].map(({ url }, index) => (
                                            <AuctionGalleryPhotoEditor
                                                key={index}
                                                photo={{ url }}
                                                galleryCategory={galleryCategory}
                                                inUpload
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                            <hr />
                            <AuctionGalleryPhotosEditor galleryCategory={galleryCategory} />
                        </AuctionGalleryEditorAddZone>
                    </div>
                );
            })}
        </>
    );
});
