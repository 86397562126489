import React from "react";
import clsx from "clsx";
import styles from "auctions/view/_css/auctionInfo.module.css";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { useAuctionStore } from "auctions/AuctionContext";
import { CAR_OPTIONS_CONFIG } from "_configs/carConfig";
import { AuctionOptionView } from "auctions/view/AuctionOptionView";

export function AuctionOptionInfo() {
    const { t } = useTranslation();
    const auctionStore = useAuctionStore();
    return (
        <div className={clsx("p_20", styles.container)}>
            <h3>{t("auction.view.information")}</h3>
            <Grid className={clsx("mt_25")} container spacing={3}>
                {CAR_OPTIONS_CONFIG.map((carOption) => (
                    <Grid key={carOption.key} item md={3} sm={6} xs={12}>
                        <AuctionOptionView auction={auctionStore.auction} carOption={carOption} />
                    </Grid>
                ))}
            </Grid>
            <Grid className={clsx("mt_25")} container spacing={3}>
                {auctionStore.auction.extraOptions && (
                    <Grid item xs={12}>
                        <h4>{t("auction.extraOptions")}</h4>
                        <div>{auctionStore.auction.extraOptions}</div>
                    </Grid>
                )}
                {auctionStore.auction.issues && (
                    <Grid item xs={12}>
                        <h4>{t("auction.issues")}</h4>
                        <div>{auctionStore.auction.issues}</div>
                    </Grid>
                )}
                {auctionStore.auction.modifications && (
                    <Grid item xs={12}>
                        <h4>{t("auction.modifications")}</h4>
                        <div>{auctionStore.auction.modifications}</div>
                    </Grid>
                )}
                {auctionStore.auction.services && (
                    <Grid item xs={12}>
                        <h4>{t("auction.services")}</h4>
                        <div>{auctionStore.auction.services}</div>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}
