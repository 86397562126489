import React from "react";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./_css/dialog.module.css";
import { DialogProps } from "@material-ui/core/Dialog/Dialog";
import { Dialog as MuiDialog } from "@material-ui/core";

type Props = Omit<DialogProps, "onClose"> & {
    onClose: () => void;
    closeCross?: boolean;
    bottomCrossMobile?: boolean;
    closeSize?: "inherit" | "default" | "small" | "large";
    withoutPlaceholder?: boolean;
};

export function Dialog({ closeCross, closeSize, withoutPlaceholder, bottomCrossMobile, ...props }: Props) {
    return (
        <MuiDialog {...props}>
            {closeCross && (
                <div
                    onClick={() => props.onClose?.()}
                    className={clsx(styles.cross, "flex_row p_10 justifyContent_flexEnd cursor_pointer", {
                        ["p_25"]: closeSize === "large",
                        [styles.bottomMobileCross]: bottomCrossMobile,
                    })}
                >
                    <CloseIcon fontSize={closeSize ? closeSize : "default"} />
                </div>
            )}
            {closeCross && !withoutPlaceholder && (
                <div
                    className={clsx(styles.crossPlaceHolderSmall, {
                        [styles.crossPlaceHolderLarge]: closeSize === "large",
                    })}
                />
            )}
            <div>{props.children}</div>
        </MuiDialog>
    );
}
