import React from "react";
import { Container, NoSsr } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { AuctionsListObserver } from "./AuctionsListObserver";
import { getAuctionsClosedListingStore } from "auctions/_stores/auctionsClosedListingStore";
import styles from "./_css/auctionsClosedListingPage.module.css";
import { Link } from "react-router-dom";
import clsx from "clsx";

export function AuctionsClosedListingPage() {
    const { t } = useTranslation();
    return (
        <Container className="mt_20 pb_10">
            <h1 className={clsx("mb_25 flex_row_center flexWrap_wrap", styles.titleContainer)}>
                <div className={"flex-1"}>
                    <div className={styles.title}>{t("auction.closedListing.title")}</div>
                    <Link onClick={() => ($crisp as any).push(["do", "chat:open"])} to={"#"}>
                        <div className={styles.subtitle}>{t("auction.closedListing.subtitle")}</div>
                    </Link>
                </div>
            </h1>
            <NoSsr>
                <LoadableFromLoading
                    loading={getAuctionsClosedListingStore().getLoadingState(1)}
                    renderer={(status, error) => {
                        return (
                            <div>
                                <ErrorBlock error={error} onRetry={() => getAuctionsClosedListingStore().load(1)} />
                                {status === "SUCCEEDED" ? (
                                    <AuctionsListObserver
                                        listStore={getAuctionsClosedListingStore()}
                                        isClosed={true}
                                        textKey={""}
                                    />
                                ) : null}
                            </div>
                        );
                    }}
                />
            </NoSsr>
        </Container>
    );
}
