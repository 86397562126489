import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import styles from "./_css/slider.module.css";
import { useGesture } from "react-use-gesture";
import { RoundSliderArrows } from "_common/slider/arrows/RoundSliderArrows";

type Props = {
    index: number;
    slideWidth: number;
    nbSlides: number;
    onChange?: (index: number) => void;
    stopPropagation?: boolean;
    noIndex?: boolean;
    arrowType?: "round" | "normal" | "none";
};

export function Slider(props: PropsWithChildren<Props>) {
    const initialTranslation = { x: 0 };
    const [{ x }, setSpring] = useSpring(() => initialTranslation);
    const lastTranslation = useRef<number>(initialTranslation.x);
    const currentTranslation = useRef<number>(initialTranslation.x);
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

    let maxXTranslation = props.nbSlides ? -props.slideWidth * (props.nbSlides - 1) : 0;
    if (props.noIndex && props.nbSlides && containerRef) {
        maxXTranslation = containerRef.getBoundingClientRect().width - props.nbSlides * props.slideWidth;
    }
    maxXTranslation = Math.min(0, maxXTranslation);

    const [disabledPrevious, setDisabledPrevious] = useState(initialTranslation.x >= 0);
    const [disabledNext, setDisabledNext] = useState(!containerRef || initialTranslation.x <= maxXTranslation);

    useEffect(() => {
        setDisabledNext(initialTranslation.x <= maxXTranslation);
    }, [maxXTranslation]);

    const set = (x: number) => {
        currentTranslation.current = x;
        setSpring({ x });
        if (props.noIndex) {
            setDisabledPrevious(x >= 0);
            setDisabledNext(x <= maxXTranslation);
        }
    };

    useEffect(() => {
        !props.noIndex && set(-props.index * props.slideWidth);
    }, [props.noIndex, props.index, props.slideWidth]);

    const bind = useGesture({
        onDrag: (params) => {
            if (props.stopPropagation) params.event?.stopPropagation();
            if (params.last) {
                if (window.document) delete window.document.body.style.userSelect;
                if (!props.noIndex) {
                    const offset = currentTranslation.current + props.slideWidth * props.index;
                    if (Math.abs(offset) > Math.max(150, props.slideWidth * 0.3)) {
                        props.onChange?.(props.index + (offset < 0 ? 1 : -1));
                    } else {
                        set(-props.index * props.slideWidth);
                    }
                }
            }
            if (!params.down) return;
            if (params.first) {
                lastTranslation.current = currentTranslation.current;
                if (window.document) window.document.body.style.userSelect = "none";
            }

            const translation = lastTranslation.current + params.movement[0];
            const newTranslation = Math.min(0, Math.max(translation, maxXTranslation));
            set(newTranslation);
        },
    });

    function handlePrevious() {
        if (props.noIndex) {
            set(Math.min(0, Math.max(currentTranslation.current + props.slideWidth, maxXTranslation)));
        } else {
            props.onChange?.(props.index === 0 ? props.nbSlides - 1 : props.index - 1);
        }
    }

    function handleNext() {
        if (props.noIndex) {
            set(Math.min(0, Math.max(currentTranslation.current - props.slideWidth, maxXTranslation)));
        } else {
            props.onChange?.(props.index < props.nbSlides - 1 ? props.index + 1 : 0);
        }
    }

    return (
        <div
            ref={(ref) => setContainerRef(ref)}
            className={styles.container}
            style={!props.noIndex ? { width: props.slideWidth } : undefined}
        >
            <animated.div
                className="flex_row_center"
                {...bind()}
                style={{
                    transform: x.interpolate(((x: number) => `translate3d(${x}px, 0, 0)`) as any),
                }}
            >
                {props.children}
            </animated.div>
            {props.arrowType === "round" ? (
                <RoundSliderArrows
                    onPrevious={handlePrevious}
                    onNext={handleNext}
                    nextDisabled={disabledNext}
                    previousDisabled={disabledPrevious}
                />
            ) : null}
        </div>
    );
}
