import React from "react";
import { UiPaper } from "_common/ui/mui/surfaces/UiPaper";
import { NavLink } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { useAuctionStore } from "auctions/AuctionContext";
import { observer } from "mobx-react";
import { AuctionGalleryEditor } from "auctions/gallery/editor/AuctionGalleryEditor";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import galleryGuide from "shared/assets/fcb_gallery_guide.pdf";

export const AuctionNewSuccess = observer(() => {
    const auctionStore = useAuctionStore();
    const { t } = useTranslation();
    return (
        <div>
            <h2>{t("auction.successfullyPosted")}</h2>
            {auctionStore.auction._id && (
                <UiPaper className="mt_20 p_25" color={"white"} elevation={0}>
                    <div>{t("auction.postPhotos")}</div>
                    <div>
                        <a href={galleryGuide} target={"_blank"} rel="noopener noreferrer">
                            {t("auction.form.galleryGuide")}
                        </a>
                    </div>
                    <div className="mt_25">
                        <AuctionGalleryEditor />
                    </div>
                    <div className="mt_25">{t("auction.successfullyPostedText")}</div>
                    <NavLink to={URLS.auction.edit(auctionStore.auction._id)}>
                        <UiButton className="mt_25" variant={"contained"} color={"primary"}>
                            {t("auction.completeAd")}
                        </UiButton>
                    </NavLink>
                </UiPaper>
            )}
        </div>
    );
});
