import { MenuItem, Select } from "@material-ui/core";
import { GALLERY_CATEGORIES } from "auctions/_models/AuctionMdl";
import React from "react";
import { useAuctionStore } from "auctions/AuctionContext";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import styles from "../_css/auctionGalleryPhotosOptions.module.css";
import clsx from "clsx";

type Props = {
    galleryCategory: GALLERY_CATEGORIES;
};

const AuctionGalleryPhotosOptions = observer((props: Props) => {
    const auctionStore = useAuctionStore();
    const { t } = useTranslation();
    const selectOptions = Object.values(GALLERY_CATEGORIES).filter(
        (galleryCategory) => galleryCategory !== props.galleryCategory,
    );
    const selectedPhotos = auctionStore.selectedPhotos[props.galleryCategory];
    const allPhotosAreSelected = selectedPhotos.size === auctionStore.auction[props.galleryCategory]?.length;
    return (
        <div className={"flex_row"}>
            <span className={"mr_20"}>Selected({auctionStore.selectedPhotos[props.galleryCategory]?.size ?? 0})</span>
            {auctionStore.auction[props.galleryCategory]?.length > 0 && (
                <span
                    className={clsx(styles.clickableSpan, "mr_20")}
                    onClick={() => {
                        if (allPhotosAreSelected) auctionStore.unselectAll(props.galleryCategory);
                        else auctionStore.selectAll(props.galleryCategory);
                    }}
                >
                    {allPhotosAreSelected ? "Unselect all" : "Select all"}
                </span>
            )}
            {selectedPhotos?.size > 0 && (
                <div className={"flex_row"}>
                    <div className={"flex_center_center"}>
                        <Select
                            displayEmpty
                            labelId={"move-to-select"}
                            value={"default"}
                            onChange={(e) => {
                                e.stopPropagation();
                                auctionStore.moveSelectedPhotos(
                                    props.galleryCategory,
                                    e.target.value as GALLERY_CATEGORIES,
                                );
                            }}
                        >
                            <MenuItem value={"default"}>Move to</MenuItem>
                            {selectOptions.map((galleryCategory) => {
                                return (
                                    <MenuItem key={galleryCategory} value={galleryCategory}>
                                        {t(`auction.${galleryCategory}`)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                </div>
            )}
        </div>
    );
});

export default AuctionGalleryPhotosOptions;
