import React, { useState } from "react";
import { TRootMenu } from "settings/_models/MenuMdl";
import clsx from "clsx";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import styles from "./_css/rootMobileMenu.module.css";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { KeyboardArrowUp } from "@material-ui/icons";
import { i18nextInstance } from "_common/i18n/IntlProvider";

type Props = {
    menu: TRootMenu;
    onClose: () => void;
    withLang?: boolean;
};

function renderNavContent(props: Props) {
    return (
        <>
            <div className={styles.text}>{props.menu.title}</div>
        </>
    );
}

function SubGroupMenu(props: Props) {
    const [isOpenSubgroup, setIsOpenSubgroup] = useState(false);

    return (
        <>
            <div
                onClick={() => setIsOpenSubgroup(!isOpenSubgroup)}
                className={clsx(styles.menu, styles.text, "flex_center_center")}
            >
                {props.menu.title}
                {isOpenSubgroup ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </div>
            {isOpenSubgroup && (
                <div className={styles.subgroup}>
                    {props.menu.subGroups?.map((subgroup, index) => {
                        return (
                            <div key={index}>
                                <div className={clsx(styles.subGroupTitle, styles.text, "flex_center_center")}>
                                    {subgroup.title}
                                </div>
                                <div>
                                    {subgroup.items?.map((item, index) => {
                                        return (
                                            <ExternalOrNavLink
                                                key={index}
                                                className={clsx(styles.subItem, styles.text, "flex_center_center")}
                                                url={item.url}
                                                onClick={props.onClose}
                                            >
                                                {item.title}
                                            </ExternalOrNavLink>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
}

export function RootMobileMenu(props: Props) {
    return (
        <div
            className={clsx(styles.container, {
                [styles.container_separator]: props.menu.separator,
            })}
        >
            {props.menu.subGroups ? (
                <SubGroupMenu {...props} />
            ) : (
                <ExternalOrNavLink
                    className={clsx(styles.menu, "flex_center_center")}
                    activeClassName={styles.menu_active}
                    url={(props.withLang ? "" : "/" + i18nextInstance.language) + props.menu.url}
                    onClick={props.onClose}
                >
                    {renderNavContent(props)}
                </ExternalOrNavLink>
            )}
        </div>
    );
}
