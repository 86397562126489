import React, { useState } from "react";
import { observer } from "mobx-react";
import { useAuctionStore } from "auctions/AuctionContext";
import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Input } from "_common/ui/forms/Input";
import { PaymentMethods } from "payments/paymentMethods/PaymentMethods";
import { IPaymentMethodMdl } from "payments/paymentMethods/_models/PaymentMethodMdl";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { getHighestBidOrOffer, getMinimumBid } from "auctions/_utils/auctionUtils";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { auctionsStore } from "auctions/_stores/auctionsStore";
import { toast } from "react-toastify";
import { getErrorMessage } from "_common/errors/errorUtils";
import { overlayStore } from "main/overlay/_stores/overlayStore";
import { userStore } from "users/_stores/userStore";
import { IUserMdl } from "users/_models/UserMdl";

export const BidDialog = observer(() => {
    const { t } = useTranslation();
    const auctionStore = useAuctionStore();
    const [loading, setLoading] = React.useState(false);
    const [paymentMethod, setPaymentMethod] = useState<IPaymentMethodMdl>();
    const [paymentMethodError, setPaymentMethodError] = useState(false);
    const minPrice = getMinimumBid(getHighestBidOrOffer(auctionStore.auction.bids)?.price) / 100;
    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur",
    });
    return (
        <Dialog
            fullWidth
            maxWidth={"sm"}
            closeCross
            open={auctionStore.bidDialogOpened}
            onClose={() => auctionStore.closeBidDialog()}
        >
            <DialogTitle>{t("bids.place")}</DialogTitle>
            <DialogContent className="pb_20">
                <div className="mv_10">{t("bids.topText")}</div>
                <InputBlock label={t("bids.price")}>
                    <Input
                        name={"price"}
                        type={"number"}
                        ref={register({
                            required: t<string>("errors.forms.required"),
                            min: {
                                value: minPrice,
                                message: t("bids.minError", { minPrice }),
                            },
                        })}
                        error={errors.price}
                    />
                </InputBlock>
                {!userStore.user?.phone && (
                    <InputBlock label={t("words.phone")}>
                        <Input
                            name={"phone"}
                            placeholder={"Your phone"}
                            ref={register({
                                required: t<string>("errors.forms.required"),
                            })}
                            error={errors.phone}
                        />
                    </InputBlock>
                )}
                <div className="mt_20">
                    <PaymentMethods
                        onSelect={(paymentMethod) => {
                            setPaymentMethod(paymentMethod);
                            setPaymentMethodError(false);
                        }}
                        selectedPaymentMethodId={paymentMethod?.id}
                    />
                    {paymentMethodError && <ErrorBlock error={t("bids.paymentMethodError")} />}
                </div>
            </DialogContent>
            <hr />
            <DialogActions>
                <UiButton
                    color={"primary"}
                    variant={"contained"}
                    onClick={handleSubmit((data) => {
                        if (!paymentMethod) {
                            setPaymentMethodError(true);
                            return;
                        }
                        if (auctionStore.auction._id && !loading) {
                            setLoading(true);
                            overlayStore.open({ children: t("bids.loading"), withSpinner: true });
                            auctionsStore
                                .bid(auctionStore.auction._id, paymentMethod.id, parseFloat(data.price) * 100)
                                .then(() => {
                                    if (data.phone) {
                                        return userStore.save({ phone: data.phone } as IUserMdl);
                                    }
                                })
                                .then(() => {
                                    window.dataLayer.push({
                                        event: "bid_placed",
                                    });
                                    auctionStore.closeBidDialog();
                                    toast.success(t("bids.success"));
                                })
                                .catch((err) => toast.error(getErrorMessage(err)))
                                .finally(() => {
                                    setLoading(false);
                                    overlayStore.close();
                                });
                        }
                    })}
                >
                    {t("words.submit")}
                </UiButton>
            </DialogActions>
        </Dialog>
    );
});
