import React from "react";

type Props = {
    title: string;
    titleHeader?: "h2" | "h3" | "h4";
    className?: string;
};

export function CardTitle(props: Props) {
    const tag = props.titleHeader ?? "h3";
    const HeaderTag = `${tag}` as keyof JSX.IntrinsicElements;
    return <HeaderTag className={props.className}>{props.title}</HeaderTag>;
}
