import React from "react";
import Grid from "@material-ui/core/Grid";
import { AuctionOptionInfo } from "./AuctionOptionInfo";
import { AuctionFeed } from "auctions/view/feed/AuctionFeed";
import { AuctionMainInfo } from "auctions/view/AuctionMainInfo";
import { AuctionGalleryView } from "auctions/gallery/view/AuctionGalleryView";
import { NoSsr } from "@material-ui/core";
import AuctionChart from "auctions/view/AuctionChart";
import AuctionVideos from "auctions/view/AuctionVideos";

export function AuctionInfo() {
    return (
        <Grid container className="mt_20" spacing={3}>
            <Grid item md={6} xs={12}>
                <AuctionGalleryView />
            </Grid>
            <Grid item md={6} xs={12}>
                <AuctionMainInfo />
            </Grid>
            <Grid item xs={12}>
                <AuctionOptionInfo />
            </Grid>
            <Grid item xs={12}>
                <AuctionChart />
            </Grid>
            <Grid item xs={12}>
                <AuctionVideos />
            </Grid>
            <NoSsr>
                <Grid item xs={12}>
                    <AuctionFeed />
                </Grid>
            </NoSsr>
        </Grid>
    );
}
