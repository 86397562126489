import React from "react";
import { TLeafMenu } from "settings/_models/MenuMdl";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import styles from "./_css/leafMenu.module.css";
import clsx from "clsx";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

type Props = {
    menu: TLeafMenu;
};

export function LeafMenu(props: Props) {
    return (
        <ExternalOrNavLink
            className={clsx(styles.container, "flex_row_center", {
                [styles.container_highlighted]: props.menu.highlighted,
            })}
            url={props.menu.url}
        >
            {props.menu.highlighted && <NavigateNextIcon className={styles.icon} />}
            <div className={clsx(styles.item, "flexShrink_0")}>{props.menu.title}</div>
        </ExternalOrNavLink>
    );
}
