import React from "react";
import { IPaymentMethodMdl } from "payments/paymentMethods/_models/PaymentMethodMdl";
import clsx from "clsx";
import styles from "./_css/paymentMethod.module.css";
import { Radio } from "@material-ui/core";
import { paymentMethodsStore } from "payments/paymentMethods/_stores/paymentMethodsStore";
import { useTranslation } from "react-i18next";
import { ActionBtn } from "_common/ui/utils/ActionBtn";
import { LinkBtn } from "_common/ui/links/LinkBtn";

type Props = {
    paymentMethod: IPaymentMethodMdl;
    selected?: boolean;
    onSelect?: () => void;
    canDelete?: boolean;
};

export function PaymentMethod(props: Props) {
    const { t } = useTranslation();
    const selectable = !!props.onSelect;
    return (
        <div
            className={clsx("flex_column", styles.container, {
                [styles.selected]: props.selected,
                [styles.selectable]: selectable,
            })}
            onClick={() => !props.selected && props.onSelect?.()}
        >
            <div className="flex_row_center">
                {props.onSelect ? (
                    <div className={styles.radio}>
                        <Radio
                            color="primary"
                            checked={props.selected}
                            onChange={(event) => event.target.checked && props.onSelect?.()}
                        />
                    </div>
                ) : null}
                <div className={styles.brand}>{props.paymentMethod.brand}</div>
                <div className="mr_5">****-{props.paymentMethod.last4}</div>
                <div className="flex-1" />
                <div>
                    {props.paymentMethod.expMonth}/{props.paymentMethod.expYear}
                </div>
            </div>
            <div className="flex_row justifyContent_flexEnd">
                {props.canDelete && (
                    <ActionBtn
                        onExecute={() => paymentMethodsStore.delete(props.paymentMethod.id)}
                        getSuccessMessage={() => t("paymentMethods.delete.success")}
                        noLoadingLabel
                    >
                        {(loading, loadingLabel) => (
                            <LinkBtn size="small" theme="delete">
                                {loading ? loadingLabel : t("paymentMethods.delete.cta")}
                            </LinkBtn>
                        )}
                    </ActionBtn>
                )}
            </div>
        </div>
    );
}
