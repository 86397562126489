import React, { ReactNode } from "react";
import styles from "./_css/actionBtn.module.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getErrorMessage } from "_common/errors/errorUtils";
import clsx from "clsx";

type Props<T> = {
    onExecute: (event: React.MouseEvent) => Promise<T> | undefined;
    loadingLabel?: string;
    noLoadingLabel?: boolean;
    getSuccessMessage?: (result: T) => string;
    children: ReactNode | ((loading: boolean, loadingLabel?: string) => ReactNode);
};

export function ActionBtn<T>(props: Props<T>) {
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();

    const content =
        typeof props.children === "function"
            ? props.children(loading, props.loadingLabel ?? t("loaders.message"))
            : props.children;
    return (
        <div
            className={clsx(styles.container, { [styles.loading]: loading })}
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (loading) return;
                setLoading(true);
                const promise = props.onExecute(event);
                if (!promise) {
                    setLoading(false);
                    return;
                }
                promise
                    .then(
                        (result) => {
                            if (!result) return;
                            if (props.getSuccessMessage) {
                                toast.success(props.getSuccessMessage(result), {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                });
                            }
                        },
                        (err) => {
                            toast.error(getErrorMessage(err), {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        },
                    )
                    .finally(() => setLoading(false));
            }}
        >
            {loading && !props.noLoadingLabel ? props.loadingLabel ?? t("loaders.message") : content}
        </div>
    );
}
