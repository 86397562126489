import React from "react";
import { TOverlayOption } from "main/overlay/_stores/overlayStore";
import { Spinner } from "_common/loaders/Spinner";
import styles from "./_css/overlay.module.css";
import clsx from "clsx";

type Props = {
    option?: TOverlayOption;
};

export function Overlay(props: Props) {
    if (!props.option) return null;

    return (
        <div
            className={clsx("flex_center_center flex_column", styles.container)}
            onClick={props.option.closeable ? props.option.onClose : undefined}
        >
            {props.option.withSpinner ? (
                <div className="mb_20">
                    <Spinner />
                </div>
            ) : null}
            <div>{props.option.children}</div>
        </div>
    );
}
