import _ from "lodash";
import { TObjWithId } from "_common/types/GenericTypes";

export function getInitialStateValue<T>(key: string) {
    return __BROWSER__
        ? (window.__PRELOADED_STATE__?.[key] as T | undefined)
        : ((global as any).__PRELOADED_STATE__?.[key] as T | undefined);
}

export function getResourceInitialStateValue(key: string) {
    return __BROWSER__ ? window.__PRELOADED_STATE__?.r?.[key] : (global as any).__PRELOADED_STATE__?.r?.[key];
}

export function putItemsInInitialState<T>(resourceName: string, items: T[]) {
    if (!(global as any).__PRELOADED_STATE__.r) {
        (global as any).__PRELOADED_STATE__.r = {};
    }
    const rState = (global as any).__PRELOADED_STATE__.r;
    if (!rState[resourceName]) rState[resourceName] = { items: [] };
    rState[resourceName].items.push(...items);
}

export function putPromiseResultInInitialState(resourceName: string, promise: Promise<any>): void {
    if (__BROWSER__) return;
    const processId = (global as any).startProcess();
    promise
        .then((result) => {
            (global as any).__PRELOADED_STATE__ = {
                ...(global as any).__PRELOADED_STATE__,
                [resourceName]: result,
            };
        })
        .finally(() => (global as any).onProcessFinished(processId));
}

export function putPromiseResourceResultInInitialState<T>(resourceName: string, promise: Promise<T | T[]>): void {
    if (__BROWSER__) return;
    const processId = (global as any).startProcess();
    promise
        .then((result) => {
            if (!result) return;
            (global as any).__PRELOADED_STATE__ = {
                ...(global as any).__PRELOADED_STATE__,
            };
            const resultArray = _.isArray(result) ? result : [result];
            putItemsInInitialState(resourceName, resultArray);
        })
        .finally(() => (global as any).onProcessFinished(processId));
}

export function putPromiseListResultInInitialState<T extends TObjWithId>(
    resourceName: string,
    listId: string,
    offset: number,
    promise: Promise<{ count: number; items: T[] }>,
): void {
    if (__BROWSER__) return;
    const processId = (global as any).startProcess();
    promise
        .then((result) => {
            (global as any).__PRELOADED_STATE__ = {
                ...(global as any).__PRELOADED_STATE__,
            };
            const rState = (global as any).__PRELOADED_STATE__.r;
            if (!rState[resourceName]) rState[resourceName] = { items: [], list: {} };
            rState[resourceName].items.push(...result.items);
            if (!rState[resourceName].list[listId]) rState[resourceName].list[listId] = { pages: {} };
            if (!rState[resourceName].list[listId].pages[offset]) rState[resourceName].list[listId].pages[offset] = [];
            rState[resourceName].list[listId].count = result.count;
            rState[resourceName].list[listId].pages[offset].push(...result.items.map((item) => item._id));
        })
        .finally(() => (global as any).onProcessFinished(processId));
}
