import React, { useState } from "react";
import { useAuctionStore } from "auctions/AuctionContext";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import { Grid } from "@material-ui/core";

import styles from "../_css/galleryCar.module.css";
import "swiper/swiper-bundle.min.css";
import { GALLERY_CATEGORIES } from "auctions/_models/AuctionMdl";

SwiperCore.use([Navigation, Thumbs]);

type Props = {
    galleryCategory: GALLERY_CATEGORIES;
};

export function GalleryCar({ galleryCategory }: Props) {
    const auctionStore = useAuctionStore();
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>();
    const photos =
        galleryCategory === GALLERY_CATEGORIES.ALL ? auctionStore.allPhotos : auctionStore.auction[galleryCategory];
    return (
        <div className={styles.container}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Swiper slidesPerView={1} thumbs={{ swiper: thumbsSwiper }} navigation loop>
                        {photos.map((image, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className={styles.image} style={{ backgroundImage: `url(${image.url})` }} />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </Grid>
                <Grid item xs={12} className={styles.navigation}>
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={20}
                        watchSlidesVisibility
                        watchSlidesProgress
                        onSwiper={(s) => setThumbsSwiper(s)}
                    >
                        {photos.map((image, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div
                                        className={styles.thumbImage}
                                        style={{ backgroundImage: `url(${image.url})` }}
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </Grid>
            </Grid>
        </div>
    );
}
