import React from "react";
import { Container, NoSsr } from "@material-ui/core";
import { useParams } from "react-router";
import { auctionsStore } from "auctions/_stores/auctionsStore";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { AuctionView } from "./AuctionView";
import { AuctionContext } from "auctions/AuctionContext";
import { AuctionStore } from "auctions/_stores/AuctionStore";
import { GalleryPage } from "auctions/gallery/GalleryPage";
import { isPromise } from "_common/_utils/coreUtils";
import { IAuctionMdl } from "auctions/_models/AuctionMdl";

function renderContent(auction: IAuctionMdl) {
    return (
        <AuctionContext auctionStore={new AuctionStore(auction)}>
            <AuctionView />
            <NoSsr>
                <GalleryPage />
            </NoSsr>
        </AuctionContext>
    );
}

export function AuctionViewPage() {
    const params = useParams<{ alias: string }>();
    const auctionOrPromise = auctionsStore.getByAlias(params.alias);
    const auctionPromise = isPromise(auctionOrPromise) ? auctionOrPromise : undefined;
    const { loading } = useLoadingFromPromise(auctionPromise);
    return (
        <Container className="mt_20">
            {auctionPromise ? (
                !loading ? null : (
                    <LoadableFromLoading
                        loading={loading}
                        renderer={() => {
                            const auction = loading?.value;
                            if (!auction) return null;
                            return renderContent(auction);
                        }}
                    />
                )
            ) : (
                renderContent(auctionOrPromise as IAuctionMdl)
            )}
        </Container>
    );
}
