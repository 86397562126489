import React, { useMemo } from "react";
import { GALLERY_CATEGORIES, TAuctionPhoto } from "auctions/_models/AuctionMdl";
import { useAuctionStore } from "auctions/AuctionContext";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { toast } from "react-toastify";
import { getErrorMessage } from "_common/errors/errorUtils";
import { LoaderWrapper } from "_common/loaders/LoaderWrapper";
import { FilePreview } from "_common/ui/forms/images/FilePreview";
import { combineLoadingStates } from "_common/loaders/loadingStateUtils";
import { observer } from "mobx-react-lite";

type Props = {
    photo: TAuctionPhoto;
    galleryCategory: GALLERY_CATEGORIES;
    inUpload?: boolean;
};

export const AuctionGalleryPhotoEditor = observer((props: Props) => {
    const auctionStore = useAuctionStore();
    const uploadingState = auctionStore.getPhotoUploadingState(props.photo.url, props.galleryCategory);
    const { loading, setPromise } = useLoadingFromPromise();
    const loadingState = useMemo(() => combineLoadingStates([uploadingState, loading]), [uploadingState, loading]);
    const index = auctionStore.auction[props.galleryCategory]?.findIndex((photo) => photo.url === props.photo.url);
    return (
        <LoaderWrapper loadingState={loadingState}>
            <FilePreview
                url={props.photo.url}
                isImage
                selected={auctionStore.selectedPhotos[props.galleryCategory].has(props.photo)}
                onClick={() => {
                    auctionStore.toggleSelectedPhoto(props.galleryCategory, props.photo);
                }}
                onDelete={
                    loading?.isLoading
                        ? undefined
                        : () => {
                              if (props.inUpload) {
                                  auctionStore.deletePhotoUploadingState(props.photo.url, props.galleryCategory);
                              } else {
                                  const promise = auctionStore.deletePhoto(index, props.galleryCategory).promise;
                                  promise?.catch((err) => toast.error(getErrorMessage(err)));
                                  if (promise) setPromise(promise);
                              }
                          }
                }
            />
        </LoaderWrapper>
    );
});
