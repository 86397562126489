import { loadStripe, Stripe } from "@stripe/stripe-js";
import { appConfig } from "_configs/appConfig";

class PaymentsStore {
    stripePromise: Promise<Stripe | null> | undefined;

    loadStripe(force?: boolean) {
        if (!force && this.stripePromise) {
            return this.stripePromise;
        }
        this.stripePromise = loadStripe(appConfig.stripe.publicKey);
        return this.stripePromise;
    }
}

const paymentsStore = new PaymentsStore();
export { paymentsStore };
