import React from "react";
import { Container } from "@material-ui/core";
import { useParams } from "react-router";
import { auctionsStore } from "auctions/_stores/auctionsStore";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { AuctionEdit } from "auctions/edit/AuctionEdit";
import { AuctionContext } from "auctions/AuctionContext";
import { AuctionStore } from "auctions/_stores/AuctionStore";

export function AuctionEditPage() {
    const params = useParams<{ auctionId: string }>();
    const auctionId = params.auctionId;
    const { loading } = useLoadingFromPromise(auctionsStore.getAsync(auctionId));
    if (!loading) return null;

    return (
        <Container className="mt_20">
            <LoadableFromLoading
                loading={loading}
                renderer={() => {
                    const auction = loading?.value;
                    if (!auction) return null;
                    return (
                        <AuctionContext auctionStore={new AuctionStore(auction)}>
                            <AuctionEdit />
                        </AuctionContext>
                    );
                }}
            />
        </Container>
    );
}
