import { action, observable } from "mobx";
import { ReactNode } from "react";

export type TOverlayOption = {
    children: ReactNode;
    closeable?: boolean;
    onClose?: () => void;
    withSpinner?: boolean;
};

class OverlayStore {
    @observable option: TOverlayOption | undefined;

    @action open(option: TOverlayOption) {
        this.option = option;
    }

    @action close() {
        this.option = undefined;
    }
}

const overlayStore = new OverlayStore();
export { overlayStore };
