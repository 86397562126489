const isDev = process.env.NODE_ENV === "development";
const isStaging = process.env.IS_STAGING === "true";
const isProd = process.env.NODE_ENV === "production" && !isStaging;

const languages = {
    en: {
        lang: "en",
        baseUrl: `${process.env.APP_URL}/en`,
        basePath: `/en`,
        title: "English",
    },
    fr: {
        lang: "fr",
        baseUrl: `${process.env.APP_URL}/fr`,
        basePath: `/fr`,
        title: "Français",
    },
};

export type TLang = keyof typeof languages;

const sharedConfig = {
    env: process.env.NODE_ENV,
    isDev,
    isStaging,
    isProd,

    emailName: "Fast Car Bids",
    email: isDev ? "fcb@incodewetrust.co" : "contact@fastcarbids.com",

    defaultLang: "en" as TLang, // langue par défaut, utilisé si des textes ne sont pas présent dans une autre langue
    languages,

    apiUrl: process.env.API_URL as string,
    appUrl: process.env.APP_URL as string,

    sentry: {
        dsn: undefined, // to_change
    },

    uploadsBaseUrl: "/static/uploads",

    provinces: ["AB", "BC", "MB", "NB", "NL", "NS", "ON", "PE", "QC", "SK", "NT", "NU", "YT"],
    states: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
    ],
    countries: ["canada", "usa"],

    fees: {
        postAuctionWithReserve: 4999,
        postAuctionNoReserve: 2499,
        cancelWithReserve: 11500,
        cancelNoReserve: 11500,
    },

    facebook: {
        pixel: "1020834888463273",
    },

    endDateAuctionsThreshold: 2,

    google: {
        analytics: "G-KDJ5SYQ05L",
    },
};

export default sharedConfig;
