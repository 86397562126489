import React, { useState } from "react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Input } from "_common/ui/forms/Input";
import { FieldError } from "react-hook-form/dist/types/form";

type Props = {
    value?: number;
    onChange?: (value: number | null) => void;
    disabled?: boolean;
    error?: FieldError;
};

export function ReserveInput(props: Props) {
    const { t } = useTranslation();
    const [isReserve, setIsReserve] = useState(!!props.value);

    return (
        <Grid container spacing={1} className="mt_10">
            <Grid item xs={12}>
                <UiButton
                    onClick={() => {
                        setIsReserve(false);
                        props.onChange?.(0);
                    }}
                    className="mr_10"
                    variant={"outlined"}
                    color={!isReserve ? "primary" : "inherit"}
                    disabled={props.disabled}
                >
                    {t("auction.noReserve")}
                </UiButton>
                <UiButton
                    onClick={() => setIsReserve(true)}
                    variant={"outlined"}
                    color={isReserve ? "primary" : "inherit"}
                    disabled={props.disabled}
                >
                    {t("auction.reserve")}
                </UiButton>
            </Grid>
            {isReserve && (
                <Grid item md={6} sm={9} xs={12}>
                    <InputBlock
                        required={isReserve}
                        label={t("auction.reserveAmount")}
                        extralabel={t("auction.reserveAmountInfo")}
                    >
                        <Input
                            error={
                                props.error ||
                                (isReserve && !props.value
                                    ? { type: "required", message: t("errors.forms.required") }
                                    : undefined)
                            }
                            type="number"
                            value={props.value ? props.value / 100 : undefined}
                            onChange={(e) => {
                                if (e.target.value.trim() === "") props.onChange?.(0);
                                else {
                                    const value = parseInt(e.target.value);
                                    if (!isNaN(value)) {
                                        props.onChange?.(value * 100);
                                    }
                                }
                            }}
                            disabled={props.disabled}
                        />
                    </InputBlock>
                </Grid>
            )}
            {/*<Grid item xs={12}>*/}
            {/*    {t("auction.reserveText")} <Link to={URLS.about()}>{t("auction.reserveTextLink")}</Link>*/}
            {/*</Grid>*/}
        </Grid>
    );
}
