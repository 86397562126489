import React, { useState } from "react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { useAuctionStore } from "auctions/AuctionContext";
import { observer } from "mobx-react-lite";
import { Checkbox, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { PaymentMethods } from "payments/paymentMethods/PaymentMethods";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { IPaymentMethodMdl } from "payments/paymentMethods/_models/PaymentMethodMdl";
import { formatPrice } from "_common/_utils/currencyUtils";
import styles from "./_css/buyNow.module.css";
import clsx from "clsx";
import { overlayStore } from "main/overlay/_stores/overlayStore";
import { auctionsStore } from "auctions/_stores/auctionsStore";
import { toast } from "react-toastify";
import { getErrorMessage } from "_common/errors/errorUtils";

const BuyNowDialog = observer(() => {
    const { t } = useTranslation();
    const auctionStore = useAuctionStore();
    const [paymentMethod, setPaymentMethod] = useState<IPaymentMethodMdl>();
    const [paymentMethodError, setPaymentMethodError] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [loading, setLoading] = useState(false);

    if (!auctionStore.auction.minPrice) return null;
    return (
        <Dialog
            fullWidth
            maxWidth={"sm"}
            closeCross
            open={auctionStore.buyNowDialogOpened}
            onClose={() => auctionStore.closeBuyNowDialog()}
        >
            <DialogTitle>{t("buyNow.title")}</DialogTitle>
            <DialogContent className="pb_20">
                <div className={"mb_40"}>
                    <PaymentMethods
                        onSelect={(paymentMethod) => {
                            setPaymentMethod(paymentMethod);
                            setPaymentMethodError(false);
                        }}
                        selectedPaymentMethodId={paymentMethod?.id}
                    />
                    {paymentMethodError && <ErrorBlock error={t("bids.paymentMethodError")} />}
                </div>
                <div>
                    <p>
                        {t("buyNow.amount")} : <b>{formatPrice(auctionStore.auction.minPrice)}</b>
                    </p>
                    <div className={styles.termsCheckbox}>
                        <Checkbox
                            id={"terms"}
                            value={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                        />
                        <label htmlFor={"terms"}>{t("buyNow.termsAgree")}</label>
                    </div>
                    {termsError && <ErrorBlock error={t("makeAnOffer.termsError")} />}

                    <div className={clsx(styles.terms, "mt_20 mb_20")}>
                        <p className={"text_discreet"}>{t("buyNow.termsOfUse")}</p>
                        <p className={"mt_10"}>{t("buyNow.terms")}</p>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <UiButton
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => {
                        if (!paymentMethod) {
                            setPaymentMethodError(true);
                            return;
                        }
                        if (!termsAccepted) {
                            setTermsError(true);
                            return;
                        }
                        if (auctionStore.auction._id && !loading) {
                            setLoading(true);
                            overlayStore.open({ children: t("buyNow.loading"), withSpinner: true });
                            auctionsStore
                                .buyNow(auctionStore.auction._id, paymentMethod.id)
                                .then(() => {
                                    auctionStore.closeBuyNowDialog();
                                    toast.success(t("buyNow.success"));
                                })
                                .catch((err) => toast.error(getErrorMessage(err)))
                                .finally(() => {
                                    setLoading(false);
                                    overlayStore.close();
                                });
                        }
                    }}
                >
                    {t("buyNow.action")}
                </UiButton>
            </DialogActions>
        </Dialog>
    );
});

export default BuyNowDialog;
