import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { PropTypes } from "@material-ui/core";
import { Link } from "react-router-dom";
import { IconComponent } from "components/base/icon/IconComponent";
import { usePageRendererStore } from "pages/PageContext";

type Props = {
    style: CSSProperties;
    url: string;
    label: string;
    color?: PropTypes.Color;
    variant?: "text" | "outlined" | "contained";
    size?: "small" | "medium" | "large";
    startIcon?: string;
    endIcon?: string;
    $component?: TComponent;
};

function renderButton(props: Props) {
    return (
        <UiButton
            color={props.color}
            variant={props.variant}
            size={props.size}
            style={props.style}
            startIcon={props.startIcon ? <IconComponent icon={props.startIcon} /> : undefined}
            endIcon={props.endIcon ? <IconComponent icon={props.endIcon} /> : undefined}
        >
            {props.label}
        </UiButton>
    );
}

export function CtaComponent(props: Props) {
    const pageRendererStore = usePageRendererStore();
    const isLocalUrl = props.url.startsWith("/");
    if (isLocalUrl) {
        return <Link to={pageRendererStore.isBuilder ? "#" : props.url}>{renderButton(props)}</Link>;
    }

    return (
        <a href={pageRendererStore.isBuilder ? "#" : props.url} rel="noreferrer noopener">
            {renderButton(props)}
        </a>
    );
}
