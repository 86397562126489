import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./_css/cookieBanner.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const STORAGE_KEY = "cookie";

function getCookieAccepted() {
    let cookieAccepted = false;
    try {
        cookieAccepted = localStorage.getItem(STORAGE_KEY) === "true";
    } catch (err) {
        console.error(err);
    }
    return cookieAccepted;
}

function setCookieAccepted() {
    try {
        localStorage.setItem(STORAGE_KEY, "true");
    } catch (err) {
        console.error(err);
    }
}

export function CookieBanner() {
    const [opened, setOpened] = useState(!getCookieAccepted());
    const { t } = useTranslation();
    return (
        <CSSTransition in={opened} timeout={200} unmountOnExit classNames={{ exitActive: styles.exitActive }}>
            <div className={styles.container}>
                <div className={clsx(styles.innerContainer, "flex_row_center")}>
                    <div className={styles.left}>{t("cookieBanner.text")}</div>
                    <div className={styles.right}>
                        <div
                            className="btn btn_smaller btn_normal"
                            onClick={() => {
                                setOpened(false);
                                setCookieAccepted();
                            }}
                        >
                            {t("words.accept")}
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
}
