import React, { ReactNode, useMemo } from "react";
import { useWindowSize } from "shared/_common/ui/utils/slider";
import { Slider } from "_common/slider/Slider";

type Props = {
    children: (width: number) => ReactNode;
    nbSlides: number;
    arrowType?: "round" | "normal" | "none";
};

export function FluidSlider(props: Props) {
    const windowSize = useWindowSize();

    const itemWidth = useMemo(() => {
        if (windowSize.width <= 768) {
            return windowSize.width;
        } else if (windowSize.width <= 900) {
            return windowSize.width / 2;
        } else if (windowSize.width <= 1400) {
            return windowSize.width / 3;
        } else if (windowSize.width <= 2000) {
            return windowSize.width / 4;
        } else {
            return windowSize.width / 5;
        }
    }, [windowSize]);

    return (
        <Slider
            index={0}
            slideWidth={itemWidth}
            noIndex
            stopPropagation
            nbSlides={props.nbSlides}
            arrowType={props.arrowType}
        >
            {props.children(itemWidth)}
        </Slider>
    );
}
