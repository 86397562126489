import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { AUCTION_STATUS, IAuctionEditionMdl, IAuctionMdl, IUserAuctionListingMdl } from "auctions/_models/AuctionMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import { IBidMdl } from "auctions/_models/BidMdl";
import { reformatAuction, watchAuctionsEvent } from "auctions/_utils/auctionUtils";
import { action } from "mobx";

class AuctionsStore extends BaseResourceStore<IAuctionMdl> {
    constructor() {
        super("auctions");
        if (__BROWSER__) this.initWatcher();
    }

    @action
    swapAuctions(auctionId: string, newPosition: number) {
        fetchUtils
            .post<IAuctionMdl>(`${this.apiPath}/${auctionId}/swap`, { newPosition })
            .then(() => {
                const listStore = this.getListStore("admin", undefined, undefined, { position: -1 });
                listStore.setSort({ position: -1 });
            });
    }

    approve(auctionId: string, version: number) {
        return fetchUtils
            .post<IAuctionMdl>(`${this.apiPath}/${auctionId}/approve`, { version })
            .then(({ data }) => this.handleItemUpdated(data));
    }

    publish(auctionId: string, version: number) {
        return fetchUtils
            .post<IAuctionMdl>(`${this.apiPath}/${auctionId}/publish`, { version })
            .then(({ data }) => this.handleItemUpdated(data));
    }

    cancel(auctionId: string) {
        return fetchUtils
            .post<IAuctionMdl>(`${this.apiPath}/${auctionId}/cancel`)
            .then(({ data }) => this.handleItemUpdated(data));
    }

    bid(auctionId: string, paymentMethodId: string, price: number) {
        return fetchUtils.post<IBidMdl>(`${this.apiPath}/${auctionId}/bid`, { paymentMethodId, price });
    }

    offer(auctionId: string, paymentMethodId: string, price: number) {
        return fetchUtils.post<IBidMdl>(`${this.apiPath}/${auctionId}/offer`, { paymentMethodId, price });
    }

    acceptOffer(auctionId: string) {
        return fetchUtils.post<IUserAuctionListingMdl>(`${this.apiPath}/${auctionId}/offer/accept`, { auctionId });
    }

    declineOffer(auctionId: string) {
        return fetchUtils.post<IUserAuctionListingMdl>(`${this.apiPath}/${auctionId}/offer/decline`, { auctionId });
    }

    buyNow(auctionId: string, paymentMethodId: string) {
        return fetchUtils.post<IBidMdl>(`${this.apiPath}/${auctionId}/buyNow`, { paymentMethodId });
    }

    reformatItem(auction: IAuctionMdl) {
        return reformatAuction(auction);
    }

    getByAlias(alias?: string) {
        const auction = this.findOneSync("alias", alias);
        if (auction) return auction;
        return fetchUtils
            .get<IAuctionMdl>(`${this.apiPath}/${alias}/alias`)
            .then(({ data }) => this.handleItemUpdated(data));
    }

    checkout(auction: IAuctionEditionMdl, amount: number) {
        return fetchUtils
            .post<{ clientCode: string }>(`${this.apiPath}/checkout`, { auction, amount })
            .then(({ data }) => data);
    }

    updatePositions(auctionId: string, previousStatus: AUCTION_STATUS) {
        return fetchUtils.post<IAuctionMdl>(`${this.apiPath}/${auctionId}/updatePositions`, { previousStatus });
    }

    private initWatcher() {
        watchAuctionsEvent((auctionId) => this.getSync(auctionId));
    }
}

const auctionsStore = new AuctionsStore();
export { auctionsStore };
