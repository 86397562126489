import {
    AUCTION_STATUS,
    GALLERY_CATEGORIES,
    IAuctionEditionMdl,
    IAuctionMdl,
    TAuctionPhoto,
} from "auctions/_models/AuctionMdl";
import { auctionsStore } from "auctions/_stores/auctionsStore";
import { action, computed, observable, reaction } from "mobx";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { UNKNOWN_ERROR } from "_common/errors/errorUtils";
import { TFilesData } from "_common/_utils/fetchUtils";
import { createFilesData } from "_common/_utils/fileUtils";
import _ from "lodash";
import eventsStore from "main/events/eventsStore";

const PHOTOS_UPLOAD_BATCH_SIZE = 3;

export class AuctionStore {
    @observable auction: Partial<IAuctionEditionMdl>;
    readonly auctionSavingState = new LoadingStateMdl<IAuctionMdl | undefined>();

    @observable bidDialogOpened = false;
    @observable buyNowDialogOpened = false;
    @observable makeAnOfferDialogOpened = false;
    @observable galleryPageOpened = false;

    @observable currentPhotosLoadingState: LoadingStateMdl<IAuctionMdl | undefined> | undefined = undefined;
    @observable uploadingState: {
        [key in GALLERY_CATEGORIES]: { url: string; loadingState: LoadingStateMdl<IAuctionMdl | undefined> }[];
    } = {
        [GALLERY_CATEGORIES.ALL]: [],
        [GALLERY_CATEGORIES.INTERIOR]: [],
        [GALLERY_CATEGORIES.EXTERIOR]: [],
        [GALLERY_CATEGORIES.UNDERCARRIAGE]: [],
        [GALLERY_CATEGORIES.ENGINE]: [],
        [GALLERY_CATEGORIES.EXTRA]: [],
    };

    @observable selectedPhotos: { [key in GALLERY_CATEGORIES]: Set<TAuctionPhoto> } = {
        [GALLERY_CATEGORIES.ALL]: new Set(),
        [GALLERY_CATEGORIES.INTERIOR]: new Set(),
        [GALLERY_CATEGORIES.EXTERIOR]: new Set(),
        [GALLERY_CATEGORIES.UNDERCARRIAGE]: new Set(),
        [GALLERY_CATEGORIES.ENGINE]: new Set(),
        [GALLERY_CATEGORIES.EXTRA]: new Set(),
    };

    constructor(auction: Partial<IAuctionEditionMdl>) {
        this.auction = auction;
        if (!this.auction._id) {
            const disposer = eventsStore.on("auctions/created", (event) => {
                this.auction = event.payload.item;
                disposer();
            });
        }
    }

    @computed get inEdition() {
        return !this.isApproved;
    }

    @computed get isApproved() {
        return this.auction.status === AUCTION_STATUS.APPROVED;
    }

    @computed get isPublished() {
        return this.auction.status === AUCTION_STATUS.IN_PROGRESS;
    }

    @computed get allPhotos() {
        const allPhotos: TAuctionPhoto[] = [];
        Object.values(GALLERY_CATEGORIES).forEach((galleryCategory) => {
            if (this.auction[galleryCategory]) allPhotos.push(...this.auction[galleryCategory]);
        });
        return allPhotos;
    }

    @computed get coverPhotos() {
        const coverPhotos: TAuctionPhoto[] = [];
        if (this.auction.photosExterior) coverPhotos.push(...this.auction.photosExterior);
        if (this.auction.photosInterior) coverPhotos.push(...this.auction.photosInterior);
        if (this.auction.photos) coverPhotos.push(...this.auction.photos);
        if (coverPhotos.length >= 4) return coverPhotos.slice(0, 4);
        return this.allPhotos.slice(0, 4);
    }

    @computed get galleriesWithContent() {
        return Object.values(GALLERY_CATEGORIES).filter(
            (galleryCategory) => this.auction[galleryCategory]?.length || galleryCategory === GALLERY_CATEGORIES.ALL,
        );
    }

    @action openBidDialog() {
        this.bidDialogOpened = true;
    }

    @action closeBidDialog() {
        this.bidDialogOpened = false;
    }

    @action openBuyNowDialog() {
        this.buyNowDialogOpened = true;
    }

    @action closeBuyNowDialog() {
        this.buyNowDialogOpened = false;
    }

    @action openMakeAnOfferDialog() {
        this.makeAnOfferDialogOpened = true;
    }

    @action closeMakeAnOfferDialog() {
        this.makeAnOfferDialogOpened = false;
    }

    @action openGalleryPage() {
        this.galleryPageOpened = true;
    }

    @action closeGalleryPage() {
        this.galleryPageOpened = false;
    }

    save(auction: IAuctionEditionMdl, files?: TFilesData) {
        return this.patch(auction, files);
    }

    publish() {
        if (this.auction._id && this.auction.__v !== undefined) {
            return auctionsStore.publish(this.auction._id, this.auction.__v);
        }
    }

    addPhotos(urls: string[], galleryCategory: GALLERY_CATEGORIES) {
        const urlsBatchs = _.chunk(urls, PHOTOS_UPLOAD_BATCH_SIZE);
        return urlsBatchs.map((urlsBatch) => this.uploadPhotosBatchs(urlsBatch, galleryCategory));
    }

    movePhoto(startIndex: number, endIndex: number, galleryCategory: GALLERY_CATEGORIES) {
        if (!this.currentPhotosLoadingState) {
            this.selectedPhotos[galleryCategory].clear();
            const updatedPhotos = [...(this.auction[galleryCategory] ?? [])];
            updatedPhotos.splice(endIndex, 0, ...updatedPhotos.splice(startIndex, 1));
            this.currentPhotosLoadingState = this.patch({ [galleryCategory]: updatedPhotos });
            this.currentPhotosLoadingState.promise?.finally(() => (this.currentPhotosLoadingState = undefined));
            return this.currentPhotosLoadingState;
        }
    }

    deletePhoto(photoIndex: number, galleryCategory: GALLERY_CATEGORIES) {
        const loadingState = new LoadingStateMdl<IAuctionMdl | undefined>();
        const deletePromise = new Promise<IAuctionMdl | undefined>((resolve) => {
            this.onNextPhotosLoadingState(() => {
                const updatedPhotos = [...(this.auction[galleryCategory] ?? [])];
                updatedPhotos.splice(photoIndex, 1);
                const patchLoadingState = this.patch({ [galleryCategory]: updatedPhotos });
                loadingState.sync(patchLoadingState);
                resolve(patchLoadingState.promise);
            });
        });
        loadingState.startLoading(deletePromise);
        return loadingState;
    }

    getPhotoUploadingState(url: string, galleryCategory: GALLERY_CATEGORIES) {
        return this.uploadingState[galleryCategory].find((uploadingState) => uploadingState.url === url)?.loadingState;
    }

    @action deletePhotoUploadingState(url: string, category: GALLERY_CATEGORIES) {
        return this.uploadingState[category].splice(
            this.uploadingState[category].findIndex((uploadingState) => uploadingState.url === url),
            1,
        );
    }

    @action toggleSelectedPhoto(category: GALLERY_CATEGORIES, photo: TAuctionPhoto) {
        if (this.selectedPhotos[category].has(photo)) this.selectedPhotos[category].delete(photo);
        else this.selectedPhotos[category].add(photo);
    }

    @action moveSelectedPhotos(fromCategoryName: GALLERY_CATEGORIES, toCategoryName: GALLERY_CATEGORIES) {
        const updatedPhotos = {
            [fromCategoryName]: this.auction[fromCategoryName]!.filter(
                (photo) => !this.selectedPhotos[fromCategoryName].has(photo),
            ),
            [toCategoryName]: [...(this.auction[toCategoryName] ?? []), ...this.selectedPhotos[fromCategoryName]],
        };
        this.currentPhotosLoadingState = this.patch(updatedPhotos);
        this.currentPhotosLoadingState.promise?.finally(() => (this.currentPhotosLoadingState = undefined));
        this.selectedPhotos[fromCategoryName].clear();
    }

    @action selectAll(category: GALLERY_CATEGORIES) {
        this.auction[category]!.forEach((photo) => {
            this.selectedPhotos[category].add(photo);
        });
    }

    @action unselectAll(category: GALLERY_CATEGORIES) {
        this.selectedPhotos[category].clear();
    }

    @action
    private uploadPhotosBatchs(urls: string[], galleryCategory: GALLERY_CATEGORIES) {
        const uploadingState = new LoadingStateMdl<IAuctionMdl | undefined>("LOADING");
        for (let i = 0; i < urls.length; i++) {
            const url = urls[i];
            this.uploadingState[galleryCategory].push({ url, loadingState: uploadingState });
        }
        this.onNextPhotosLoadingState(() => this.executePhotosUpload(uploadingState, urls, galleryCategory));
        return uploadingState;
    }

    @action
    private async executePhotosUpload(
        uploadingState: LoadingStateMdl<IAuctionMdl | undefined>,
        urls: string[],
        galleryCategory: GALLERY_CATEGORIES,
    ) {
        try {
            this.currentPhotosLoadingState = uploadingState;
            const updatedPhotos = [...(this.auction[galleryCategory] ?? []), ...urls.map((url) => ({ url }))];
            const filesData = await createFilesData(
                updatedPhotos.map(({ url }) => url),
                `${galleryCategory}.*.url`,
                1200,
            );
            const patchLoadingState = this.patch({ [galleryCategory]: updatedPhotos }, filesData);
            uploadingState.sync(patchLoadingState);
            patchLoadingState.promise
                ?.then(
                    action(() => {
                        for (const url of urls) {
                            this.deletePhotoUploadingState(url, galleryCategory);
                        }
                    }),
                )
                .finally(() => (this.currentPhotosLoadingState = undefined));
        } catch (err) {
            uploadingState.setError(err);
            this.currentPhotosLoadingState = undefined;
        }
    }

    private onNextPhotosLoadingState(cb: () => any) {
        if (!this.currentPhotosLoadingState) cb();
        else {
            const disposer = reaction(
                () => !this.currentPhotosLoadingState,
                () => {
                    if (!this.currentPhotosLoadingState) {
                        disposer();
                        cb();
                    }
                },
            );
        }
    }

    private patch(patch: Partial<IAuctionMdl>, files?: TFilesData) {
        if (!this.auctionSavingState.isLoading) {
            const isCreate = !this.auction._id;
            const request = isCreate
                ? auctionsStore.create(patch, files)
                : auctionsStore.patch({ ...patch, _id: this.auction._id }, files);
            const promise = request.then(
                action((savedAuction) => {
                    if (savedAuction) {
                        this.auction = savedAuction;
                        this.auctionSavingState.setSuccess(savedAuction);
                        eventsStore.send({
                            type: isCreate ? "auctions/created" : "auctions/updated",
                            payload: { item: savedAuction },
                        });
                    } else {
                        this.auctionSavingState.setError(UNKNOWN_ERROR);
                    }
                    return savedAuction;
                }),
                (err) => {
                    this.auctionSavingState.setError(err);
                    return undefined;
                },
            );
            this.auctionSavingState.startLoading(promise);
        }
        return this.auctionSavingState;
    }
}
