import React, { CSSProperties, useRef } from "react";
import clsx from "clsx";
import { useHistory } from "react-router";
import styles from "./_css/newsCardComponent.module.css";
import { TComponent } from "pages/_models/PageMdl";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

export type NewsCardComponentProps = {
    date?: Dayjs | string;
    text?: string;
    image?: string;
    url?: string;
    style?: CSSProperties;
    $component?: TComponent;
};

export function NewsCardComponent(props: NewsCardComponentProps) {
    const mouseDownStart = useRef(0);
    const history = useHistory();
    const { t } = useTranslation();
    const date = typeof props.date === "string" ? dayjs(props.date) : props.date;
    return (
        <div
            style={props.style}
            className={styles.container}
            onMouseDown={() => (mouseDownStart.current = Date.now())}
            onMouseUp={() => {
                if (Date.now() - mouseDownStart.current > 250) return;
                if (props.url?.startsWith("/")) {
                    history.push(props.url);
                } else if (props.url) {
                    window.location.href = props.url;
                }
            }}
        >
            <div className={clsx(styles.image, "pt_20")} style={{ backgroundImage: `url(${props.image})` }}>
                {date && <span className={clsx(styles.date, "mh_20")}>{date.format("DD/MM/YYYY")}</span>}
            </div>
            <div className={clsx(styles.text, "p_25")}>{props.text}</div>
            <div className={clsx(styles.readMore, "flex_center_center")}>
                <div className={styles.textGradient} />
                {t("news.seeMore")}
            </div>
        </div>
    );
}
