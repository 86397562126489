import React from "react";
import { TCarConfigDef } from "_configs/carConfig";
import { IAuctionEditionMdl, IBaseAuctionMdl } from "auctions/_models/AuctionMdl";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/auctionOptionView.module.css";

type Props = {
    auction: IBaseAuctionMdl | IAuctionEditionMdl;
    carOption: TCarConfigDef;
};

export function AuctionOptionView(props: Props) {
    const { t } = useTranslation();

    function renderOption(props: Props) {
        switch (props.carOption.type) {
            case "color":
                return (
                    <div className="flex_row">
                        <div
                            className={clsx("p_10 mv_10", styles.color)}
                            style={{ backgroundColor: props.auction.options?.[props.carOption.key] as string }}
                        />
                    </div>
                );
            case "enum":
                return <div>{t("car.options." + props.auction.options?.[props.carOption.key])}</div>;
            default:
                return <div>{props.auction.options?.[props.carOption.key]}</div>;
        }
    }

    return (
        <>
            <h4>{t("car.options." + props.carOption.key)}</h4>
            {renderOption(props)}
        </>
    );
}
