import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";

type Props = {
    style?: CSSProperties;
    color?: string;
    $component?: TComponent;
};

export function SeparatorComponent(props: Props) {
    return <hr style={{ ...props.style, backgroundColor: props.color }} />;
}
