import React from "react";
import { useTranslation } from "react-i18next";
import { useAuctionStore } from "auctions/AuctionContext";
import clsx from "clsx";
import styles from "auctions/view/_css/auctionInfo.module.css";
import { Grid } from "@material-ui/core";
import { VideoComponent } from "components/base/video/VideoComponent";

const AuctionVideos = () => {
    const { t } = useTranslation();
    const auctionStore = useAuctionStore();
    const ytRegex = new RegExp(/https:\/\/youtu\.be\/[a-zA-Z0-9]*/);
    const videos = [];
    if (auctionStore.auction.video1) videos.push(auctionStore.auction.video1);
    if (auctionStore.auction.video2) videos.push(auctionStore.auction.video2);
    if (auctionStore.auction.video3) videos.push(auctionStore.auction.video3);
    if (auctionStore.auction.video4) videos.push(auctionStore.auction.video4);
    if (auctionStore.auction.video5) videos.push(auctionStore.auction.video5);
    if (auctionStore.auction.video6) videos.push(auctionStore.auction.video6);
    if (auctionStore.auction.video7) videos.push(auctionStore.auction.video7);
    if (auctionStore.auction.video8) videos.push(auctionStore.auction.video8);

    if (videos.length && videos.filter((v) => ytRegex.test(v)).length) {
        return (
            <div className={clsx("p_20", styles.container)}>
                <h3 className={"mb_20"}>{t("auction.view.videos")}</h3>
                <Grid container spacing={3}>
                    {videos
                        .filter((v) => ytRegex.test(v))
                        .map((video) => (
                            <Grid key={video} item xs={12} md={6}>
                                <VideoComponent url={video} />
                            </Grid>
                        ))}
                </Grid>
            </div>
        );
    } else return null;
};

export default AuctionVideos;
