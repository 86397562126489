import React from "react";
import { IAuctionListingMdl } from "auctions/_models/AuctionMdl";
import { observer } from "mobx-react-lite";
import { ListStore } from "_common/list/ListStore";
import { AuctionThumbnail } from "auctions/card/AuctionThumbnail";
import { URLS } from "_configs/URLS";
import { useHistory } from "react-router-dom";

type Props = {
    listStore: ListStore<IAuctionListingMdl>;
    isClosed?: boolean;
};

export const AuctionsSideList = observer((props: Props) => {
    const history = useHistory();
    if (props.listStore.items.length === 0) return null;
    return (
        <div>
            {props.listStore.items.map((auction) => {
                if (!auction) return null;

                return (
                    <div
                        key={auction._id}
                        className={"cursor_pointer"}
                        onClick={() => history.push(URLS.auction.see(auction.alias))}
                    >
                        <AuctionThumbnail auction={auction} imageOnly smallImage />
                    </div>
                );
            })}
        </div>
    );
});
