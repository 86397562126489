import React, { ReactNode } from "react";
import { ICommentMdl } from "comments/_models/CommentMdl";
import { AuctionCommentOrBid } from "comments/AuctionCommentOrBid";
import { useTranslation } from "react-i18next";
import { LinkBtn } from "_common/ui/links/LinkBtn";
import { Collapse } from "@material-ui/core";
import { AuctionCommentForm } from "comments/AuctionCommentForm";
import { observer } from "mobx-react";

type Props = {
    comment: ICommentMdl;
    actionsRenderer?: (comment: ICommentMdl) => ReactNode;
    sellerId?: string;
};

export const AuctionComment = observer((props: Props) => {
    const [formOpened, setFormOpened] = React.useState(false);
    const replies = props.comment.replies;
    const { t } = useTranslation();
    return (
        <div>
            <div>{props.comment.text}</div>
            {props.actionsRenderer?.(props.comment)}
            {!props.comment.repliedTo ? (
                <div>
                    <LinkBtn onClick={() => setFormOpened(!formOpened)} theme="discreet">
                        {t("comments.reply")}
                    </LinkBtn>
                    <Collapse in={formOpened}>
                        <div className="mt_10 mb_5">
                            <AuctionCommentForm
                                parentComment={props.comment._id}
                                onSuccess={() => setFormOpened(false)}
                            />
                        </div>
                    </Collapse>
                    {replies && replies.length > 0 ? (
                        <div className="border_left pl_20 mt_10 pt_5">
                            {replies?.map((reply) => (
                                <div key={reply._id} className="mb_5">
                                    <AuctionCommentOrBid
                                        sellerId={props.sellerId}
                                        commentOrBid={reply}
                                        actionsRenderer={props.actionsRenderer}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
});
