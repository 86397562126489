import React from "react";
import { WatchListLoader } from "auctions/watchList/WatchListLoader";
import { useTranslation } from "react-i18next";

export function ProfileWatchlist() {
    const { t } = useTranslation();
    return (
        <div className="mb_40">
            <h2 className="mb_20">{t("profile.watchlist.title")}</h2>
            <WatchListLoader />
        </div>
    );
}
