import { TComponent, TRootComponent } from "pages/_models/PageMdl";
import { ReactNode } from "react";

export type TWrapperComponentProps = {
    component: TComponent | TRootComponent;
    parent?: TComponent;
    isRoot?: boolean;
    actions?: ReactNode;
    onAdd?: () => void;
    children: (props: any, EditorComponent?: (props: any) => JSX.Element) => ReactNode;
};

type TWrapperComponent = (props: TWrapperComponentProps) => JSX.Element;

export type TChildrenComponentProps = {
    propName: string;
    parent: TComponent;
    children: TComponent[];
};

export class PageRendererStore {
    readonly WrapperComponent: TWrapperComponent | undefined;
    readonly ChildrenComponent: ((props: TChildrenComponentProps) => JSX.Element) | undefined;
    readonly isBuilder: boolean;

    constructor(
        WrapperComponent?: (props: any) => JSX.Element,
        ChildrenComponent?: (props: TChildrenComponentProps) => JSX.Element,
        isBuilder = false,
    ) {
        this.WrapperComponent = WrapperComponent;
        this.ChildrenComponent = ChildrenComponent;
        this.isBuilder = isBuilder;
    }
}
