import { action, observable, when } from "mobx";
import { userStore } from "users/_stores/userStore";
import sharedConfig from "_configs/sharedConfig";
import { fetchUtils } from "_common/_utils/fetchUtils";
import { MUST_BE_SIGNED_IN_ERROR } from "_common/errors/errorUtils";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { ListStore } from "_common/list/ListStore";
import { IAuctionListingMdl } from "auctions/_models/AuctionMdl";
import { IListProvider } from "_common/list/IListProvider";
import eventsStore from "main/events/eventsStore";
import { reformatListingAuction, watchAuctionsEvent } from "auctions/_utils/auctionUtils";
import { auctionsStore } from "auctions/_stores/auctionsStore";

class AuctionsWatchedProvider implements IListProvider<IAuctionListingMdl> {
    list(offset?: number, limit?: number): Promise<{ count: number; items: IAuctionListingMdl[] }> {
        const url = `${sharedConfig.apiUrl}/users/${userStore.user?._id}/watchList/listing?offset=${offset}&limit=${limit}`;
        return fetchUtils
            .get<{ count: number; items: IAuctionListingMdl[] }>(url)
            .then(({ data: { count, items } }) => ({
                count,
                items: items.map(reformatListingAuction),
            }));
    }

    putItemInCache(): void {}
}

function createListStore() {
    return new ListStore<IAuctionListingMdl>(
        "auctions/watchList",
        new AuctionsWatchedProvider(),
        undefined,
        undefined,
        true,
    );
}

class AuctionsWatchListStore {
    @observable loadingState = new LoadingStateMdl<string[]>();
    readonly watched = observable<string>([]);
    listStore: ListStore<IAuctionListingMdl>;

    constructor() {
        this.listStore = createListStore();
        when(
            () => userStore.isLogged,
            () => {
                this.load();
            },
        );
        eventsStore.on(
            "START_RENDER",
            action(() => {
                this.loadingState = new LoadingStateMdl<string[]>();
                this.watched.replace([]);
                this.listStore = createListStore();
            }),
        );
        if (__BROWSER__) this.initWatcher();
    }

    @action load() {
        if (!this.loadingState.isLoading && !this.loadingState.isSucceeded) {
            const promise = fetchUtils.get<string[]>(`${sharedConfig.apiUrl}/users/${userStore.user?._id}/watchList`);
            this.loadingState.startLoading(promise.then(({ data }) => data));
            promise.then(
                action(({ data }) => {
                    this.watched.replace(data);
                    this.loadingState.setSuccess(data);
                }),
                (err) => this.loadingState.setError(err),
            );
        }
        return this.loadingState;
    }

    @action async watch(auctionId: string) {
        const userId = userStore.user?._id;
        if (!userId) throw MUST_BE_SIGNED_IN_ERROR;

        await fetchUtils.post(`${sharedConfig.apiUrl}/users/${userStore.user?._id}/watchList/${auctionId}`);
        this.watched.push(auctionId);
        if (this.listStore.getLoadingState(1).isSucceeded) {
            auctionsStore.getAsync(auctionId).then((auction) => {
                if (auction) this.listStore.onCreate(auction);
            });
        }
    }

    @action async unwatch(auctionId: string) {
        const userId = userStore.user?._id;
        if (!userId) throw MUST_BE_SIGNED_IN_ERROR;

        await fetchUtils.delete(`${sharedConfig.apiUrl}/users/${userStore.user?._id}/watchList/${auctionId}`);
        this.watched.splice(this.watched.indexOf(auctionId), 1);
    }

    isWatching(auctionId: string) {
        return this.watched.includes(auctionId);
    }

    private initWatcher() {
        watchAuctionsEvent((auctionId) => this.listStore.getSync(auctionId));
    }
}

const auctionsWatchListStore = new AuctionsWatchListStore();
export { auctionsWatchListStore };
