import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TextArea } from "_common/ui/forms/TextArea";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { useAuctionStore } from "auctions/AuctionContext";
import "../../../_css/lib/dropZone.css";

type Props = {
    admin?: boolean;
};

export function AuctionAdvancedCarForm(props: Props) {
    const form = useFormContext();
    const { t } = useTranslation();
    const auctionStore = useAuctionStore();
    const disabled = !props.admin && !auctionStore.inEdition;

    return (
        <div>
            <h3 className="mb_20">{t("auction.advancedCarInformation")}</h3>
            <Grid spacing={3} container>
                <Grid item xs={12}>
                    <InputBlock required label={t("auction.issues")} extralabel={t("auction.issuesInfo")}>
                        <Controller
                            rules={{ required: t<string>("errors.forms.required") }}
                            disabled={disabled}
                            rows={5}
                            name={"issues"}
                            control={form.control}
                            placeholder={t("auction.issuesPlaceHolder")}
                            as={TextArea}
                            error={form.errors.issues}
                        />
                    </InputBlock>
                </Grid>
                <Grid item xs={12}>
                    <InputBlock required label={t("auction.modifications")} extralabel={t("auction.modificationsInfo")}>
                        <Controller
                            rules={{ required: t<string>("errors.forms.required") }}
                            disabled={disabled}
                            rows={5}
                            name={"modifications"}
                            control={form.control}
                            placeholder={t("auction.modificationsPlaceHolder")}
                            as={TextArea}
                            error={form.errors.modifications}
                        />
                    </InputBlock>
                </Grid>
                <Grid item xs={12}>
                    <InputBlock required label={t("auction.services")} extralabel={t("auction.servicesInfo")}>
                        <Controller
                            rules={{ required: t<string>("errors.forms.required") }}
                            disabled={disabled}
                            rows={5}
                            name={"services"}
                            control={form.control}
                            placeholder={t("auction.servicesPlaceHolder")}
                            as={TextArea}
                            error={form.errors.services}
                        />
                    </InputBlock>
                </Grid>
            </Grid>
        </div>
    );
}
