import { useEffect } from "react";
import { useLocation } from "react-router";

export const scrollTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollTop);
        window.scrollTo(0, c - c / 2);
    }
};

export function ScrollTop() {
    const location = useLocation();
    useEffect(scrollTop, [location.pathname]);
    return null;
}
