import React from "react";
import i18next from "i18next";
import i18nextXHRBackend from "i18next-xhr-backend";
import { I18nextProvider } from "react-i18next";
import utc from "dayjs/plugin/utc";
import en from "./locales/en/translation.json";
import fr from "./locales/fr/translation.json";
import sharedConfig from "_configs/sharedConfig";
import dayjs from "dayjs";
import { detectLanguage } from "_common/i18n/intlLanguageDetector";

if (__BROWSER__) {
    i18next.use(i18nextXHRBackend);
}

dayjs.extend(utc);

i18next.init({
    backend: {
        // for all available options read the backend's repository readme file
        loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    react: {
        // Must be false until Suspense is supported on the server side
        useSuspense: false,
        wait: true,
    },
    lng: __BROWSER__ ? document.documentElement.lang : undefined,
    debug: !sharedConfig.isProd && __BROWSER__,
    fallbackLng: "en",
    fallbackNS: ["translation"],
    // This option is necessary to tell i18next to try loading missing resources via
    // i18next-xhr-backend, otherwise no calls will be made if resources are defined.
    partialBundledLanguages: true,
    resources: {
        en: { translation: en },
        fr: { translation: fr },
    },
    parseMissingKeyHandler: (missing: any) => {
        if (!sharedConfig.isProd && __BROWSER__) {
            console.warn("MISSING TRANSLATION:", missing);
        }
        return missing;
    },
    interpolation: { escapeValue: false },
});

i18next.languages = ["en", "fr"];

if (__BROWSER__) {
    const lang = detectLanguage(window.location.pathname).lang;
    if (lang !== i18next.language) i18next.changeLanguage(lang);
}

const I18N: React.FC<any> = React.memo(({ children }) => {
    return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>;
});
const i18nextInstance = i18next;

export { I18N as default, i18nextInstance };
