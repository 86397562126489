import { ListStore } from "_common/list/ListStore";
import { IListProvider } from "_common/list/IListProvider";
import { IAuctionListingMdl } from "auctions/_models/AuctionMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import eventsStore from "main/events/eventsStore";
import { reformatListingAuction, watchAuctionsEvent } from "auctions/_utils/auctionUtils";

class AuctionsListingProvider implements IListProvider<IAuctionListingMdl> {
    list(offset?: number, limit?: number): Promise<{ count: number; items: IAuctionListingMdl[] }> {
        const url = `${sharedConfig.apiUrl}/auctions/listing?offset=${offset}&limit=${limit}&type=BUY_NOW`;
        return fetchUtils
            .get<{ count: number; items: IAuctionListingMdl[] }>(url)
            .then(({ data: { count, items } }) => ({
                count,
                items: items.map(reformatListingAuction),
            }));
    }

    putItemInCache(): void {}
}

let auctionsBuyNowListingStore: ListStore<IAuctionListingMdl> | undefined;

export function getAuctionsBuyNowListingStore(listId = "auctions/listing", pageSize?: number) {
    if (!auctionsBuyNowListingStore || auctionsBuyNowListingStore.listId !== listId) {
        auctionsBuyNowListingStore = new ListStore(listId, new AuctionsListingProvider(), undefined, pageSize);
        if (__BROWSER__) {
            watchAuctionsEvent((auctionId) => auctionsBuyNowListingStore?.getSync(auctionId));
        }
    }
    return auctionsBuyNowListingStore;
}

eventsStore.on("START_RENDER", () => {
    auctionsBuyNowListingStore = undefined;
});
