import React, { ReactNode } from "react";
import { ICommentMdl } from "comments/_models/CommentMdl";
import { IBidMdl, IBidPaymentStatusMdl } from "auctions/_models/BidMdl";
import { UserSummary } from "users/view/UserSummary";
import { isBid } from "auctions/_utils/auctionUtils";
import { AuctionBid } from "auctions/view/AuctionBid";
import { AuctionComment } from "comments/AuctionComment";
import { useTranslation } from "react-i18next";
import { userStore } from "users/_stores/userStore";
import { useAuctionStore } from "auctions/AuctionContext";
import { AuctionOfferStatusMessage } from "comments/AuctionOfferStatusMessage";

type Props = {
    commentOrBid: ICommentMdl | IBidMdl | IBidPaymentStatusMdl;
    actionsRenderer?: (comment: ICommentMdl) => ReactNode;
    sellerId?: string;
};

export function AuctionCommentOrBid(props: Props) {
    const { t } = useTranslation();
    const auctionStore = useAuctionStore();
    const date = isBid(props.commentOrBid) ? props.commentOrBid.date : props.commentOrBid.createdAt;
    const isOffer = isBid(props.commentOrBid) && props.commentOrBid.date > auctionStore.auction.endDate;
    const isDeclined = isOffer && (props.commentOrBid as IBidPaymentStatusMdl)?.payment?.status === "CANCELED";
    const isAccepted = isOffer && (props.commentOrBid as IBidPaymentStatusMdl)?.payment?.status === "CAPTURED";
    return (
        <div>
            <div className="flex_row_center flexWrap_wrap">
                <div className="mr_15">
                    <UserSummary
                        isSeller={props.commentOrBid.user._id === props.sellerId}
                        userSummary={props.commentOrBid.user}
                    />
                </div>
                <div className="text_discreet mr_15">{date.format(t("dates.formats.dayAndHour"))}</div>
                {userStore.isAdmin && <div className="text_discreet">{props.commentOrBid.ip}</div>}
            </div>
            <div className="mt_5">
                {isBid(props.commentOrBid) ? (
                    <div>
                        {isOffer && <label className={"mr_5"}>{t("comments.offer")}</label>}
                        <AuctionBid bid={props.commentOrBid} theme="contained" />
                    </div>
                ) : (
                    <AuctionComment
                        sellerId={props.sellerId}
                        comment={props.commentOrBid}
                        actionsRenderer={props.actionsRenderer}
                    />
                )}
            </div>
            {isDeclined && <AuctionOfferStatusMessage type={"declined"} />}
            {isAccepted && <AuctionOfferStatusMessage type={"accepted"} />}
        </div>
    );
}
