import React, { useEffect, useRef } from "react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { authStore } from "users/auth/_stores/authStore";
import { UiPaper } from "_common/ui/mui/surfaces/UiPaper";
import { UserInfo } from "users/profile/info/userInfo/UserInfo";
import { PaymentMethods } from "payments/paymentMethods/PaymentMethods";
import { UserAuctions } from "auctions/user/UserAuctions";
import { useTranslation } from "react-i18next";
import UserOffersReceived from "auctions/user/UserOffersReceived";

export function ProfileInfo() {
    const { t } = useTranslation();
    const OFFERS_REF = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(document.location.search);
        if (searchParams.has("tab")) {
            OFFERS_REF.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [OFFERS_REF.current]);

    return (
        <div>
            <h2 className="flex_row_center flexWrap_wrap">
                <div className="mr_15 mh_10 flex-1">{t("profile.title")}</div>
                <UiButton variant={"outlined"} onClick={() => authStore.signOut()}>
                    {t("profile.logout")}
                </UiButton>
            </h2>
            <div className="mt_25">
                <UiPaper className="p_25" color={"white"} elevation={0}>
                    <UserInfo />
                </UiPaper>
            </div>
            <div className="mt_25">
                <UiPaper className="p_25" color={"white"} elevation={0}>
                    <h3 className="mb_20">{t("profile.paymentMethods")}</h3>
                    <PaymentMethods canDelete />
                </UiPaper>
            </div>
            <div className="mt_25">
                <UiPaper className="p_25" color={"white"} elevation={0}>
                    <h3 className="mb_20">{t("profile.auctions")}</h3>
                    <UserAuctions />
                </UiPaper>
            </div>
            <div id={"offers"} ref={OFFERS_REF} className={"mt_25"}>
                <UiPaper className={"p_25"} color={"white"} elevation={0}>
                    <h3 className="mb_20">{t("profile.offersReceived")}</h3>
                    <UserOffersReceived />
                </UiPaper>
            </div>
        </div>
    );
}
