import React, { ReactNode } from "react";
import { useTrackPage } from "useTrackPage";

type Props = {
    children: ReactNode;
};

export function PageTracker(props: Props) {
    useTrackPage();
    return <div>{props.children}</div>;
}
