import React from "react";
import { Select } from "@material-ui/core";
import styles from "./_css/uiSelect.module.css";
import clsx from "clsx";

export const UiSelect = ({
    disabledAltStyle,
    ...props
}: React.ComponentProps<typeof Select> & { disabledAltStyle?: boolean }) => {
    return (
        <Select
            {...props}
            className={clsx(styles.container, props.className)}
            classes={{
                select: styles.select,
                icon: styles.icon,
                disabled: disabledAltStyle ? styles.disabled : undefined,
            }}
        />
    );
};
