import React from "react";
import clsx from "clsx";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import styles from "./_css/roundSliderArrows.module.css";

type Props = {
    onPrevious: () => void;
    previousDisabled?: boolean;
    onNext: () => void;
    nextDisabled?: boolean;
};

export function RoundSliderArrows(props: Props) {
    return (
        <>
            {!props.previousDisabled && (
                <div className={clsx(styles.arrow, styles.arrowLeft, "flex_center_center")} onClick={props.onPrevious}>
                    <ArrowBackIcon />
                </div>
            )}
            {!props.nextDisabled && (
                <div className={clsx(styles.arrow, styles.arrowRight, "flex_center_center")} onClick={props.onNext}>
                    <ArrowForwardIcon />
                </div>
            )}
        </>
    );
}
