import { ListStore } from "_common/list/ListStore";
import { IListProvider } from "_common/list/IListProvider";
import { IAuctionListingMdl } from "auctions/_models/AuctionMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import eventsStore from "main/events/eventsStore";
import { reformatListingAuction, watchAuctionsEvent } from "auctions/_utils/auctionUtils";

export enum AUCTION_LISTING_SORT {
    ENDING_SOON = "ENDING_SOON",
    NEWLY_LISTED = "NEWLY_LISTED",
}

class AuctionsListingProvider implements IListProvider<IAuctionListingMdl> {
    readonly sort: AUCTION_LISTING_SORT;

    constructor(sort: AUCTION_LISTING_SORT) {
        this.sort = sort;
    }

    list(offset?: number, limit?: number): Promise<{ count: number; items: IAuctionListingMdl[] }> {
        const url = `${sharedConfig.apiUrl}/auctions/listing?offset=${offset}&limit=${limit}&sort=${this.sort}`;
        return fetchUtils
            .get<{ count: number; items: IAuctionListingMdl[] }>(url)
            .then(({ data: { count, items } }) => ({
                count,
                items: items.map(reformatListingAuction),
            }));
    }

    putItemInCache(): void {}
}

let auctionsListingsStores: { [sort: string]: ListStore<IAuctionListingMdl> | undefined } = {};

export function getAuctionsListingStore(sort: AUCTION_LISTING_SORT) {
    let auctionsListingStore = auctionsListingsStores[sort];
    if (!auctionsListingStore) {
        auctionsListingStore = new ListStore("auctions/listing/" + sort, new AuctionsListingProvider(sort));
        auctionsListingsStores[sort] = auctionsListingStore;
        if (__BROWSER__) {
            watchAuctionsEvent((auctionId) => auctionsListingsStores[sort]?.getSync(auctionId));
        }
    }
    return auctionsListingStore;
}

eventsStore.on("START_RENDER", () => {
    auctionsListingsStores = {};
});
