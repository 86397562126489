export const menuConfig = {
    localized: {
        en: {
            items: [
                {
                    title: "How it All Works",
                    url: "/how-it-all-works",
                    separator: false,
                },
            ],
        },
        fr: {
            items: [
                {
                    title: "Comment ça Marche",
                    url: "/comment-ca-marche",
                },
            ],
        },
    },
};
