import React from "react";
import { getUserAuctionsStore } from "auctions/user/_stores/userAuctionsStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import UserOffersList from "auctions/user/UserOffersList";

const UserOffersReceived = () => {
    return (
        <LoadableFromLoading
            loading={getUserAuctionsStore().getLoadingState(1)}
            renderer={(status, error) => (
                <>
                    <ErrorBlock error={error} onRetry={() => getUserAuctionsStore().load(1)} />
                    {status === "SUCCEEDED" && <UserOffersList />}
                </>
            )}
        />
    );
};

export default UserOffersReceived;
