import React, { useState } from "react";
import { CirclePickerProps, GithubPicker } from "react-color";
import styles from "./_css/colorInput.module.css";
import clsx from "clsx";
import { CAR_COLOR } from "_configs/carConfig";

type Props = CirclePickerProps & {
    onChange?: (value: string) => void;
    type: string;
    value?: string;
    disabled?: boolean;
};

export function ColorInput(props: Props) {
    const [color, setColor] = useState(props.value ?? "#000000");

    return (
        <>
            <div className="flex_row">
                <div className={clsx("p_10 mv_10", styles.color)} style={{ backgroundColor: color }} />
            </div>
            {!props.disabled && (
                <div className={clsx(styles.container, "p_10")}>
                    <GithubPicker
                        width={"300"}
                        color={props?.value}
                        colors={Object.keys(CAR_COLOR)}
                        onChangeComplete={(color) => {
                            props.onChange?.(color.hex);
                            setColor(color.hex);
                        }}
                        {...props}
                    />
                </div>
            )}
        </>
    );
}
