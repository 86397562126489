import React, { PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "./_css/linkBtn.module.css";

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
    PropsWithChildren<{
        size?: "default" | "small";
        theme?: "default" | "delete" | "discreet";
    }>;

export function LinkBtn(props: Props) {
    const { size, theme, ...divProps } = props;
    return (
        <div
            {...divProps}
            className={clsx(styles.container, {
                [styles.delete]: theme === "delete",
                text_discreet: theme === "discreet",
                [styles.small]: size === "small",
            })}
        />
    );
}
