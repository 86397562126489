import React from "react";
import { ComponentRenderer } from "components/ComponentRenderer";
import { usePageRendererStore } from "pages/PageContext";
import { TChildrenComponentProps } from "pages/_stores/PageRendererStore";

export function ChildrenComponentRenderer(props: TChildrenComponentProps) {
    const { ChildrenComponent } = usePageRendererStore();
    if (ChildrenComponent) {
        return <ChildrenComponent {...props} />;
    }

    return (
        <>
            {props.children.map((child, index) => (
                <ComponentRenderer key={index} parent={props.parent} component={child} />
            ))}
        </>
    );
}
