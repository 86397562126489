import React from "react";
import styles from "./_css/menu.module.css";
import { RootMenu } from "main/menu/RootMenu";
import clsx from "clsx";
import { MobileMenuBtn } from "main/menu/mobile/MobileMenuBtn";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { MenuBusiness } from "main/menu/MenuBusiness";
import { MenuUser } from "main/menu/MenuUser";
import { HeaderLogo } from "main/menu/HeaderLogo";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";
import { settingsStore } from "settings/_stores/settingsStore";
import { TLang } from "_configs/sharedConfig";
import { TRootMenu } from "settings/_models/MenuMdl";
import { SearchBar } from "main/menu/search/SearchBar";

export function Menu() {
    const menu = settingsStore.getOneMenu()?.menu;
    const lang = i18nextInstance.language;
    const localizedMenu: TRootMenu[] = menu?.localized?.[lang as TLang]?.items ?? [];

    const { t } = useTranslation();

    return (
        <>
            <div className={styles.mobileContainer}>
                <MobileMenuBtn />
                <HeaderLogo />
            </div>
            <nav className={clsx(styles.container, "flex_row_center flex-1")}>
                <HeaderLogo />
                <RootMenu
                    withLang={true}
                    menu={{
                        title: t("header.sellCar"),
                        highlighted: true,
                        url: URLS.auction.new(),
                    }}
                />
                <MenuBusiness />
                {localizedMenu.map((rootMenu, index) => (
                    <RootMenu key={index} menu={rootMenu} />
                ))}
                <div className="flex-1">
                    <SearchBar />
                </div>
                <MenuUser />
            </nav>
        </>
    );
}
