import React from "react";
import styles from "auctions/card/_css/auctionCard.module.css";
import { IconButton } from "@material-ui/core";
import { MailOutline } from "@material-ui/icons";

type Props = {
    emails?: string[];
    subject?: string;
    body?: string;
};

export function EmailBtn(props: Props) {
    let mailto = "mailto:";
    if (props.emails) mailto += props.emails.join(",");
    if (props.subject || props.body) mailto += "?";
    if (props.subject) mailto += "subject=" + encodeURIComponent(props.subject) + "&";
    if (props.body) mailto += "body=" + encodeURIComponent(props.body);
    return (
        <a href={mailto} onClick={(event) => event.stopPropagation()}>
            <IconButton className={styles.mailBtn}>
                <MailOutline />
            </IconButton>
        </a>
    );
}
