import React from "react";
import { useTranslation } from "react-i18next";
import { userStore } from "users/_stores/userStore";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "_common/ui/forms/Input";

const ContactInformationForm = () => {
    const { t } = useTranslation();
    const { control, errors } = useFormContext();
    const user = userStore.user;
    return (
        <div>
            <h3 className="mb_20">{t("auction.contactInformation")}</h3>
            <div className={"flex_row"} style={{ gap: "16px" }}>
                <InputBlock label={t("words.firstName")}>
                    <p>{user?.firstName}</p>
                </InputBlock>
                <InputBlock label={t("words.lastName")}>
                    <p>{user?.lastName}</p>
                </InputBlock>
                <InputBlock label={t("words.phone")}>
                    <Controller
                        control={control}
                        defaultValue={user?.phone}
                        name={"phone"}
                        as={Input}
                        rules={{
                            maxLength: 30,
                        }}
                        error={errors.phone}
                    />
                </InputBlock>
            </div>
        </div>
    );
};

export default ContactInformationForm;
