import React, { ReactNode } from "react";
import Countdown, { CountdownProps, CountdownRenderProps } from "react-countdown";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/uiCountdown.module.css";
import { useNoSsr } from "_common/_utils/useNoSsr";

const ALMOST_DONE_THRESHOLD = 5;

type Props = Omit<CountdownProps, "date"> & {
    date: Dayjs;
    theme?: "dark" | "simple";
    completeLabel?: ReactNode;
};

function renderNumber(number: number) {
    return number < 10 ? "0" + number : number;
}

function CountdownRenderer(props: CountdownRenderProps) {
    const { t } = useTranslation();
    return (
        <div>
            {t("dates.days", { count: props.days }) + "   "}
            {props.hours ? props.hours + ":" : ""} {renderNumber(props.minutes)}:{renderNumber(props.seconds)}
        </div>
    );
}

export function UiCountdown(props: Props) {
    const firstRender = useNoSsr();
    const hoursLeft = props.date.diff(dayjs(), "hour");
    const isDone = props.date.isBefore(dayjs());
    const isAlmostDone = hoursLeft < ALMOST_DONE_THRESHOLD && !isDone;
    const [completed, setCompleted] = React.useState(isDone);
    if (firstRender || (completed && !props.completeLabel)) return null;

    return (
        <div
            className={clsx(styles.container, {
                [styles.dark]: props.theme === "dark",
                [styles.almostDone]: isAlmostDone,
                [styles.done]: isDone,
            })}
        >
            {completed ? (
                props.completeLabel
            ) : (
                <Countdown
                    renderer={(props) => <CountdownRenderer {...props} />}
                    date={props.date.valueOf()}
                    onComplete={() => setCompleted(true)}
                />
            )}
        </div>
    );
}
