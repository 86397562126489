import React from "react";
import { useTranslation } from "react-i18next";
import { Container, NoSsr } from "@material-ui/core";
import clsx from "clsx";
import styles from "auctions/listing/_css/auctionsClosedListingPage.module.css";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { AuctionsListObserver } from "auctions/listing/AuctionsListObserver";
import { getAuctionsBuyNowListingStore } from "auctions/_stores/auctionsBuyNowListingStore";

const AuctionsBuyNowListingPage = () => {
    const { t } = useTranslation();

    return (
        <Container className="mt_20 pb_10">
            <h1 className={clsx("mb_25 flex_row_center flexWrap_wrap", styles.titleContainer)}>
                <div className={"flex-1"}>
                    <div className={styles.title}>{t("auction.offersListing.title")}</div>
                </div>
            </h1>
            <NoSsr>
                <LoadableFromLoading
                    loading={getAuctionsBuyNowListingStore().getLoadingState(1)}
                    renderer={(status, error) => {
                        return (
                            <div>
                                <ErrorBlock error={error} onRetry={() => getAuctionsBuyNowListingStore().load(1)} />
                                {status === "SUCCEEDED" ? (
                                    <AuctionsListObserver
                                        textKey={"auction.offersListing.empty"}
                                        listStore={getAuctionsBuyNowListingStore()}
                                        isClosed={true}
                                    />
                                ) : null}
                            </div>
                        );
                    }}
                />
            </NoSsr>
        </Container>
    );
};

export default AuctionsBuyNowListingPage;
