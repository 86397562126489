import React from "react";
import styles from "auctions/card/_css/auctionCard.module.css";
import FacebookIcon from "@material-ui/icons/Facebook";
import { IconButton } from "@material-ui/core";

type Props = {
    url: string;
};

export function FacebookBtn(props: Props) {
    return (
        <IconButton
            className={styles.facebookBtn}
            onClick={(event) => {
                event.stopPropagation();
                window.open(
                    `http://facebook.com/sharer/sharer.php?u=${encodeURIComponent(props.url)}`,
                    "sharer",
                    `toolbar=0,status=0,width=${Math.min(520, window.outerWidth)},height=350`,
                );
            }}
        >
            <FacebookIcon />
        </IconButton>
    );
}
