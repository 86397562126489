import { Dayjs } from "dayjs";
import { TUserSummaryMdl } from "users/_models/UserMdl";

export enum PAYMENT_STATUS {
    IN_HOLD = "IN_HOLD",
    CANCELED = "CANCELED",
    CAPTURED = "CAPTURED",
}

export enum PAYMENT_PROVIDER {
    STRIPE = "STRIPE",
}

export enum PAYMENT_TYPE {
    POSTING_FEE = "POSTING_FEE",
    REQUEST_CANCEL = "REQUEST_CANCEL",
    BID = "BID",
}

export interface ICrudPaymentMdl {
    _id: string;

    amount: number;
    type: PAYMENT_TYPE;
    status: PAYMENT_STATUS;
    provider: PAYMENT_PROVIDER;
    providerPaymentId: string;

    auction: string;
    user: TUserSummaryMdl;
    data?: {
        bidAmount?: number;
    };

    captured?: Dayjs;
    canceled?: Dayjs;

    createdAt: Dayjs;
    updatedAt: Dayjs;
}
