import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";

type Props = {
    disabled?: boolean;
    onlyImage?: boolean;
    placeholder?: string;
};

export function FilePlaceholder(props: PropsWithChildren<Props>) {
    const { t } = useTranslation();
    return (
        <div className="p_20">
            {props.children}
            {props.disabled ? (
                <div>{t(props.onlyImage ? "files.image.empty" : "files.empty")}</div>
            ) : (
                <div className="flex_row_center">
                    {props.onlyImage && <InsertPhotoIcon className="mr_5" />}
                    {props.placeholder ?? t(props.onlyImage ? "files.image.upload" : "files.upload")}
                </div>
            )}
        </div>
    );
}
