import React from "react";
import { observer } from "mobx-react";
import Snackbar from "@material-ui/core/Snackbar";
import { snackbarsStore } from "main/snackbars/_store/snackbarsStore";

export const Snackbars = observer(() => {
    return (
        <>
            {snackbarsStore.snackbars.map(({ id, snackbarProps }) => (
                <Snackbar
                    key={id}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    open
                    onClose={() => snackbarsStore.hide(id)}
                    {...snackbarProps}
                />
            ))}
        </>
    );
});
