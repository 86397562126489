import { IBidMdl, IBidPaymentStatusMdl } from "auctions/_models/BidMdl";
import { ICommentBaseMdl, ICommentMdl } from "comments/_models/CommentMdl";
import dayjs from "dayjs";
import {
    AUCTION_CLOSED_STATUSES,
    AUCTION_SORTABLE_STATUSES,
    AUCTION_STATUS,
    IAuctionEditionMdl,
    IAuctionListingMdl,
    IAuctionMdl,
    IBaseAuctionMdl,
    IUserAuctionListingMdl,
} from "auctions/_models/AuctionMdl";
import eventsStore from "main/events/eventsStore";
import sharedConfig from "_configs/sharedConfig";
import { action } from "mobx";
import _ from "lodash";

export function lastBid(bids?: IBidMdl[]) {
    return bids?.sort((b1: IBidMdl, b2: IBidMdl) => (b1.date > b2.date ? -1 : 1))?.[0];
}

export function getHighestBidOrOffer(bids?: IBidMdl[] | IBidPaymentStatusMdl[]) {
    if (!bids) return undefined;
    let maxBid = bids[0];
    for (let i = 0; i < bids.length; i++) {
        if (!maxBid || bids[i].price > maxBid.price) {
            maxBid = bids[i];
        }
    }
    return maxBid;
}

export function isBid(
    commentOrBid: ICommentMdl | IBidMdl | ICommentBaseMdl | IBidPaymentStatusMdl,
): commentOrBid is IBidMdl | IBidPaymentStatusMdl {
    return (commentOrBid as IBidMdl).price !== undefined;
}

export function isCommentBase(comment: ICommentBaseMdl | IBidMdl | ICommentMdl): comment is ICommentBaseMdl {
    return !("user" in comment);
}

export function reformatBid(bid: IBidMdl | IBidPaymentStatusMdl) {
    return { ...bid, date: dayjs(bid.date) };
}

export function getMinimumBid(lastBid?: number) {
    if (!lastBid) return 25000;
    if (lastBid < 1999900) return lastBid + 15000;
    else if (lastBid < 4999900) return lastBid + 35000;
    else if (lastBid < 9999900) return lastBid + 60000;
    return lastBid + 100000;
}

export function reformatBids(bids?: (IBidMdl | IBidPaymentStatusMdl)[]) {
    if (bids) {
        return bids.map((bid) => reformatBid(bid));
    }
    return bids;
}

export function reformatBaseAuction<T extends IBaseAuctionMdl | IUserAuctionListingMdl>(auction: T) {
    let formattedAuction = { ...auction };
    if (formattedAuction.endDate) formattedAuction.endDate = dayjs(formattedAuction.endDate);
    if (formattedAuction.publishedDate) formattedAuction.publishedDate = dayjs(formattedAuction.publishedDate);
    if (formattedAuction.bids) {
        formattedAuction = { ...formattedAuction, bids: reformatBids(formattedAuction.bids) };
    }
    if (formattedAuction.offers) {
        formattedAuction = { ...formattedAuction, offers: reformatBids(formattedAuction.offers) };
    }
    return formattedAuction;
}

export function reformatAuction(auction: IAuctionMdl) {
    const formattedAuction = reformatBaseAuction(auction);
    if (auction.endDate) formattedAuction.endDate = dayjs(formattedAuction.endDate);
    if (auction.publishedDate) formattedAuction.publishedDate = dayjs(formattedAuction.publishedDate);
    if (auction.createdAt) formattedAuction.createdAt = dayjs(formattedAuction.createdAt);
    if (auction.updatedAt) formattedAuction.updatedAt = dayjs(formattedAuction.updatedAt);
    return formattedAuction;
}

export function reformatListingAuction(auction: IAuctionListingMdl) {
    return reformatBaseAuction(auction);
}

export function reformatUserListingAuction(auction: IUserAuctionListingMdl) {
    return reformatBaseAuction(auction);
}

export function watchAuctionsEvent(auctionGetter: (auctionId: string) => IBaseAuctionMdl | undefined) {
    eventsStore.on(
        "auctions/bid/succeeded",
        action((event) => {
            const auction = auctionGetter(event.payload.auctionId);
            if (auction) {
                auction.endDate = event.payload.endDate;
                auction.bids = auction.bids ? [...auction.bids] : [];
                auction.bids.push(event.payload.bid);
            }
        }),
    );

    eventsStore.on(
        "auctions/offer/succeeded",
        action((event) => {
            const auction = auctionGetter(event.payload.auctionId);
            if (auction) {
                auction.offers = auction.offers ? [...auction.offers] : [];
                auction.offers.push(event.payload.offer);
            }
        }),
    );

    eventsStore.on(
        "auctions/closed",
        action((event) => {
            const auction = auctionGetter(event.payload.auctionId);
            if (auction) {
                auction.status = AUCTION_STATUS.CLOSED;
                auction.sold = event.payload.sold;
                auction.bids = event.payload.bids;
            }
        }),
    );

    eventsStore.on(
        "auctions/buyNow/succeeded",
        action((event) => {
            const auction = auctionGetter(event.payload.auctionId);
            if (auction) {
                auction.status = AUCTION_STATUS.PAID;
                auction.sold = true;
                auction.offers = event.payload.offers;
            }
        }),
    );
}

export function getAuctionSubmittingFee(minPrice?: number) {
    if (minPrice) return sharedConfig.fees.postAuctionWithReserve;
    return sharedConfig.fees.postAuctionNoReserve;
}

export function getCoverPhoto(auction: IAuctionListingMdl) {
    if (auction.photosExterior?.[0]) return auction.photosExterior[0];
    if (auction.photosInterior?.[0]) return auction.photosInterior[0];
    if (auction.photos?.[0]) return auction.photos[0];
    if (auction.photosEngine?.[0]) return auction.photosEngine[0];
    if (auction.photosExtra?.[0]) return auction.photosExtra[0];
    if (auction.photosUndercarriage?.[0]) return auction.photosUndercarriage[0];
}

export function isClosedStatus(status: AUCTION_STATUS) {
    return AUCTION_CLOSED_STATUSES.includes(status);
}

export function isSortableStatus(status: AUCTION_STATUS) {
    return AUCTION_SORTABLE_STATUSES.includes(status);
}

export function statusesFromSamePage(status1: AUCTION_STATUS, status2: AUCTION_STATUS) {
    return status1 === status2 || (isClosedStatus(status1) && isClosedStatus(status2));
}

export function getLastBidOrOffer(auction: IBaseAuctionMdl | IAuctionEditionMdl) {
    const bid = _.last(auction.bids);
    const offer = _.last(auction.offers);

    if (!bid && !offer) return;

    if (!bid) return offer;
    if (!offer) return bid;

    return bid.date > offer.date ? bid : offer;
}
