import React from "react";
import { useTranslation } from "react-i18next";
import { AuctionCountdown } from "auctions/_common/AuctionCountdown";
import { useAuctionStore } from "auctions/AuctionContext";
import { IAuctionMdl } from "auctions/_models/AuctionMdl";

export function AuctionTimeLeft() {
    const { t } = useTranslation();
    const auctionStore = useAuctionStore();

    return (
        <div>
            <h3>{t("auction.view.timeLeft")}</h3>
            <AuctionCountdown auction={auctionStore.auction as IAuctionMdl} />
        </div>
    );
}
