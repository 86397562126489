import React from "react";
import { RootMobileMenu } from "main/menu/mobile/RootMobileMenu";
import { useMenuUserItems } from "main/menu/_hooks/useMenuUserItems";

type Props = {
    onClose: () => void;
};

export function MobileMenuUser(props: Props) {
    const menuUserItems = useMenuUserItems();
    return (
        <>
            {menuUserItems.map((item, index) => (
                <RootMobileMenu key={index} withLang={true} menu={item} onClose={props.onClose} />
            ))}
        </>
    );
}
