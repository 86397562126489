import React, { CSSProperties } from "react";
import { IPageShortMdl, TComponent } from "pages/_models/PageMdl";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { pagesStore } from "pages/_stores/pagesStore";
import { NewsCardComponent } from "components/news/newsCard/NewsCardComponent";
import { FluidSlider } from "_common/slider/FluidSlider";

type Props = {
    style?: CSSProperties;
    numberOfCardsToDisplay?: number;
    $component?: TComponent;
};

function LatestNewsSliderLoaded(props: Props & { latestArticles: IPageShortMdl[] }) {
    if (props.latestArticles.length === 0) return null;
    return (
        <div style={{ margin: "0 -20px" }}>
            <FluidSlider nbSlides={props.latestArticles.length} arrowType="round">
                {(itemWidth) =>
                    props.latestArticles.map((article) => (
                        <div key={article._id} style={{ minWidth: itemWidth, padding: "0 20px" }}>
                            <NewsCardComponent
                                style={props.style}
                                date={article.publishedAt}
                                text={article.description}
                                image={article.image}
                                url={article.url}
                                $component={props.$component}
                            />
                        </div>
                    ))
                }
            </FluidSlider>
        </div>
    );
}

export function LatestNewsSliderComponent(props: Props) {
    return (
        <LoadableFromLoading
            loading={pagesStore.getLatestArticles(Math.min(props.numberOfCardsToDisplay ?? 5, 10))}
            renderer={(_status, _error, latestArticles) => {
                if (!latestArticles) return null;
                return <LatestNewsSliderLoaded {...props} latestArticles={latestArticles} />;
            }}
        />
    );
}
