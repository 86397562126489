import { i18nextInstance } from "_common/i18n/IntlProvider";

export const URLS = {
    home: (lang?: string) => `/${lang || i18nextInstance.language}`,

    auth: {
        base: (lang?: string) => `/${lang || i18nextInstance.language}/auth`,
        signIn: (lang?: string) => `/${lang || i18nextInstance.language}/auth/signIn`,
        signUp: (lang?: string) => `/${lang || i18nextInstance.language}/auth/signUp`,
        askResetPassword: (lang?: string) => `/${lang || i18nextInstance.language}/auth/askResetPassword`,
        resetPassword: (lang?: string) => `/${lang || i18nextInstance.language}/auth/resetPassword`,
        verified: (lang?: string) => `/${lang || i18nextInstance.language}/auth/verify`,
    },

    user: {
        profile: (tab?: string, lang?: string) => `/${lang || i18nextInstance.language}/profile/${tab ?? ""}`,
        watchlist: (lang?: string) => `/${lang || i18nextInstance.language}/profile/watchlist`,
    },

    auction: {
        current: (lang?: string) => `/${lang || i18nextInstance.language}`,
        ended: (lang?: string) => `/${lang || i18nextInstance.language}/ended-auctions`,
        buyNowOrMakeAnOffer: (lang?: string) => `/${lang || i18nextInstance.language}/buy-now-auctions`,
        new: (lang?: string) => `/${lang || i18nextInstance.language}/sell-a-car`,
        see: (alias: string, lang?: string) => `/${lang || i18nextInstance.language}/auction/${alias}`,
        edit: (auctionId: string, lang?: string) => `/${lang || i18nextInstance.language}/auction/${auctionId}/edit`,
        search: (searchParams: string, lang?: string) => `/${lang || i18nextInstance.language}/search/${searchParams}`,
    },

    about: (lang?: string) =>
        `/${lang || i18nextInstance.language}/${lang === "fr" ? "comment-ca-marche" : "how-it-all-works"}`,

    admin: (_lang?: string) => "/fcb-admin",
};
