import React from "react";
import { AUCTION_STATUS, IAuctionListingMdl, IAuctionMdl, IUserAuctionListingMdl } from "auctions/_models/AuctionMdl";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/auctionStatus.module.css";
import { getHighestBidOrOffer } from "auctions/_utils/auctionUtils";
import dayjs from "dayjs";

type Props = {
    className?: string;
    auction: IAuctionMdl | IAuctionListingMdl | IUserAuctionListingMdl;
    cancelRequested?: boolean;
};

export function AuctionStatus(props: Props) {
    const { t } = useTranslation();
    const isLive = props.auction.publishedDate?.isBefore(dayjs());
    let didNotReachReserve = undefined;
    if (props.auction.minPrice) {
        const maxBidPrice = getHighestBidOrOffer(props.auction.bids)?.price;
        didNotReachReserve = !(maxBidPrice && maxBidPrice > props.auction.minPrice);
    }

    let status = props.auction.status;
    if (props.auction.soldManually) {
        status = AUCTION_STATUS.PAID;
    } else if (!isLive && props.auction.status === AUCTION_STATUS.IN_PROGRESS) {
        status = AUCTION_STATUS.PUBLISHED_NOT_LIVE;
    } else if (didNotReachReserve && props.auction.status === AUCTION_STATUS.CLOSED) {
        status = AUCTION_STATUS.CLOSED_NO_RESERVE;
    }

    return (
        <div>
            <div
                className={clsx(props.className, styles.container, {
                    [styles.draft]: props.auction.status === AUCTION_STATUS.DRAFT,
                    [styles.approved]: props.auction.status === AUCTION_STATUS.APPROVED,
                    [styles.inProgress]: props.auction.status === AUCTION_STATUS.IN_PROGRESS,
                    [styles.closed]: props.auction.status === AUCTION_STATUS.CLOSED,
                    [styles.paid]: props.auction.status === AUCTION_STATUS.PAID || props.auction.soldManually,
                    [styles.canceled]: props.auction.status === AUCTION_STATUS.CANCELED,
                    [styles.buyNow]: props.auction.status === AUCTION_STATUS.BUY_NOW,
                })}
            >
                {t(`auction.status.${status}`)}
            </div>
            {props.cancelRequested && props.auction.status !== AUCTION_STATUS.CANCELED && (
                <div className={clsx(styles.cancelRequested, "mt_3")}>{t("auction.cancelRequested")}</div>
            )}
        </div>
    );
}
