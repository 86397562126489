import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { Input } from "shared/_common/ui/forms/Input";
import { InputBlock } from "shared/_common/ui/forms/InputBlock";
import { useLoadingFromPromise } from "shared/_common/loaders/useLoadingFromPromise";
import { observer } from "mobx-react";
import { authStore } from "users/auth/_stores/authStore";
import { ErrorBlock } from "shared/_common/errors/ErrorBlock";
import { CardContent, CardHeader, Checkbox, IconButton } from "@material-ui/core";
import { UiButton } from "shared/_common/ui/mui/buttons/UiButton";
import { URLS } from "_configs/URLS";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { toast } from "react-toastify";
import { UserInfoNotifications } from "users/profile/info/userInfo/UserInfoNotifications";
import i18next from "i18next";

type TFormData = {
    email: string;
    phone: string;
    password: string;
    confirmPassword: string;
    nickname: string;
    firstName: string;
    lastName: string;
    heardAboutUs: string;
    dailyNotification: boolean;
    weeklyNotification: boolean;
};

export const SignUpBlock = observer(() => {
    const { t } = useTranslation();
    const form = useForm<TFormData>({
        mode: "onBlur",
    });
    const { loading, setPromise } = useLoadingFromPromise();
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const onSubmit = (values: TFormData) => {
        const promise = authStore.signUp(
            values.email,
            values.phone,
            values.password,
            values.nickname,
            values.firstName,
            values.lastName,
            // values.heardAboutUs,
            values.dailyNotification,
            values.weeklyNotification,
        );
        setPromise(promise);
        promise.then(() => {
            toast.success(t("auth.signUp.successfullyRegistered"), { position: toast.POSITION.TOP_CENTER });
            history.push(URLS.auth.signIn());
            window.dataLayer.push({ event: "registration" });
        });
    };
    const isLoading = loading?.status === "LOADING";
    const hasAnyErrors = Object.keys(form.errors).length > 0 || !termsAccepted;

    return (
        <div style={{ width: 450 }}>
            <CardHeader className="mt_10" style={{ textAlign: "center" }} title={t("auth.signUp.title")} />
            <CardContent>
                <ErrorBlock error={loading?.error} />

                <FormProvider {...form}>
                    <InputBlock label={t("words.email")}>
                        <Input
                            name="email"
                            ref={form.register({
                                required: t<string>("errors.forms.required"),
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: t("errors.forms.emailInvalid"),
                                },
                            })}
                            error={form.errors.email}
                            disabled={isLoading}
                        />
                    </InputBlock>
                    <InputBlock label={t("words.phone")}>
                        <Input
                            name="phone"
                            ref={form.register({
                                required: t<string>("errors.forms.required"),
                            })}
                            error={form.errors.email}
                            disabled={isLoading}
                        />
                    </InputBlock>
                    <InputBlock label={t("words.nickname")} extralabel={t("auth.signUp.noLastNameAsNick")}>
                        <Input
                            name="nickname"
                            ref={form.register({
                                required: t<string>("errors.forms.required"),
                            })}
                            error={form.errors.nickname}
                            disabled={isLoading}
                        />
                    </InputBlock>
                    <InputBlock label={t("words.firstName")}>
                        <Input
                            name="firstName"
                            ref={form.register({
                                required: t<string>("errors.forms.required"),
                            })}
                            error={form.errors.firstName}
                            disabled={isLoading}
                        />
                    </InputBlock>
                    <InputBlock label={t("words.lastName")}>
                        <Input
                            name="lastName"
                            ref={form.register({
                                required: t<string>("errors.forms.required"),
                            })}
                            error={form.errors.lastName}
                            disabled={isLoading}
                        />
                    </InputBlock>
                    <InputBlock label={t("words.password")}>
                        <Input
                            name="password"
                            type={showPassword ? "text" : "password"}
                            ref={form.register({
                                required: t<string>("errors.forms.required"),
                                minLength: {
                                    value: 6,
                                    message: t("auth.signUp.passwordsLength"),
                                },
                            })}
                            error={form.errors.password}
                            disabled={isLoading}
                            endIcon={
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    size="small"
                                >
                                    {showPassword ? (
                                        <Visibility fontSize="small" />
                                    ) : (
                                        <VisibilityOff fontSize="small" />
                                    )}
                                </IconButton>
                            }
                        />
                    </InputBlock>
                    <InputBlock label={t("auth.signUp.passwordConfirmation")}>
                        <Input
                            name="confirmPassword"
                            type={showPassword ? "text" : "password"}
                            ref={form.register({
                                required: t<string>("errors.forms.required"),
                                minLength: {
                                    value: 8,
                                    message: t<string>("auth.signUp.passwordsLength"),
                                },
                                validate: (value) =>
                                    value !== form.watch("password")
                                        ? t<string>("auth.signUp.passwordsMismatch")
                                        : undefined,
                            })}
                            error={form.errors.confirmPassword}
                            disabled={isLoading}
                            endIcon={
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    size="small"
                                >
                                    {showPassword ? (
                                        <Visibility fontSize="small" />
                                    ) : (
                                        <VisibilityOff fontSize="small" />
                                    )}
                                </IconButton>
                            }
                        />
                    </InputBlock>
                    <UserInfoNotifications time={"dailyNotification"} isEditing={true} onStopEditing={() => null} />
                    <div className={"flex_row_center"}>
                        <Checkbox
                            id={"terms"}
                            value={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                        />
                        <label htmlFor={"terms"}>
                            <NavLink
                                target={"_blank"}
                                to={i18next.language === "fr" ? "/fr/conditions-d-utilisation" : "/en/terms-of-use"}
                            >
                                {t("auth.signUp.termsAgree")}
                            </NavLink>
                        </label>
                    </div>
                    <div className="flex_center_center mt_20">
                        <UiButton
                            onClick={form.handleSubmit(onSubmit)}
                            disabled={hasAnyErrors || isLoading}
                            variant="contained"
                            color="primary"
                        >
                            {t(isLoading ? "loaders.message" : "auth.signUp.title")}
                        </UiButton>
                    </div>
                </FormProvider>
            </CardContent>
            <hr />
            <CardContent className="flex_center_center flex_column">
                <div>{t("auth.signIn.haveAlreadyAnAccount")}</div>
                <Link to={URLS.auth.signIn()} className="link">
                    {t("auth.signIn.title")}
                </Link>
            </CardContent>
        </div>
    );
});
