import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
    typography: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 14,
    },
    palette: {
        primary: {
            main: "#0052CB",
        },
        secondary: {
            main: "#FF000A",
        },
        error: {
            main: "#FA6F4F",
        },
        background: {
            default: "#fff",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1540,
        },
    },
});
