import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { PAYMENT_STATUS } from "admin/payments/_models/CrudPaymentMdl";

import styles from "./_css/userOfferStatus.module.css";

type Props = {
    paymentStatus: PAYMENT_STATUS;
    className?: string;
};

export function UserOfferStatus(props: Props) {
    const { t } = useTranslation();
    if (props.paymentStatus === PAYMENT_STATUS.IN_HOLD) return null;
    return (
        <>
            <div>
                <div
                    className={clsx(props.className, styles.container, {
                        [styles.accepted]: props.paymentStatus === PAYMENT_STATUS.CAPTURED,
                        [styles.declined]: props.paymentStatus === PAYMENT_STATUS.CANCELED,
                    })}
                >
                    {t(`offers.status.${props.paymentStatus}`)}
                </div>
            </div>
        </>
    );
}
