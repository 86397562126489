import { action, computed, observable, reaction } from "mobx";
import { TErrMdl } from "shared/_common/errors/_models/ErrMdl";

export type TLoadingStatus = "IDLE" | "LOADING" | "SUCCEEDED";

export class LoadingStateMdl<TValue = void> {
    @observable status: TLoadingStatus;
    @observable error?: TErrMdl;
    value?: TValue;
    promise?: Promise<TValue>;

    startLoading = action((promise?: Promise<TValue>) => {
        this.status = "LOADING";
        this.promise = promise;
        this.value = undefined;
        this.error = undefined;
    });

    setError = action((error: TErrMdl) => {
        this.status = "IDLE";
        this.error = error;
    });

    setSuccess = action((value: TValue) => {
        this.value = value;
        this.status = "SUCCEEDED";
    });

    setStatus = action((status: TLoadingStatus) => {
        this.status = status;
    });

    constructor(initialState: TLoadingStatus = "IDLE", promise?: Promise<TValue>) {
        this.status = initialState;
        this.promise = promise;
    }

    @computed get isIdle() {
        return this.status === "IDLE";
    }

    @computed get isLoading() {
        return this.status === "LOADING";
    }

    @computed get isSucceeded() {
        return this.status === "SUCCEEDED";
    }

    ifSucceeded<T = void, U = void>(onSuccess: (value: TValue) => T, onNotSuccess?: () => U) {
        return this.isSucceeded ? onSuccess(this.value as TValue) : onNotSuccess ? onNotSuccess() : undefined;
    }

    sync(loadingState: LoadingStateMdl<TValue>) {
        reaction(
            () => loadingState.toObject(),
            action((loadingStateObject) => {
                this.status = loadingStateObject.status;
                this.error = loadingStateObject.error;
                this.value = loadingStateObject.value;
                this.promise = loadingStateObject.promise;
            }),
        );
    }

    toObject() {
        return {
            status: this.status,
            error: this.error,
            value: this.value,
            promise: this.promise,
        };
    }
}
