import React from "react";
import { useTranslation } from "react-i18next";
import { useAuctionStore } from "auctions/AuctionContext";
import { getHighestBidOrOffer } from "auctions/_utils/auctionUtils";
import { AuctionBid } from "auctions/view/AuctionBid";
import { observer } from "mobx-react";
import { AUCTION_STATUS } from "auctions/_models/AuctionMdl";

export const AuctionLastBid = observer(() => {
    const auctionStore = useAuctionStore();
    const auction = auctionStore.auction;
    const bidsAndOffers = [...auction.bids, ...auction.offers];
    const highestBid = getHighestBidOrOffer(bidsAndOffers);
    const isSold =
        !auction?.notAvailable && (auction.soldManually || auction.sold || auction.status === AUCTION_STATUS.PAID);

    const { t } = useTranslation();
    if (isSold) return null;
    return (
        <div>
            <div className="flex_row_center">
                <h3 className="mr_5">
                    {auction.status != AUCTION_STATUS.CLOSED ? t("auction.view.currentBid") : t("auction.view.past")}
                </h3>
                {bidsAndOffers.length > 0 && (
                    <div>
                        / {bidsAndOffers.length} {t("words.bids")}
                    </div>
                )}
            </div>
            {highestBid ? <AuctionBid bid={highestBid} /> : <div>{t("bids.none")}</div>}
        </div>
    );
});
